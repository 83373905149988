<template>
  <div id="site-menu">
    <!-- BEGIN: Mobile Menu -->
    <div class="mobile-menu site-menu" :class="{'mobile-menu--active': show }">
      <div class="scrollable">
        <button class="mobile-menu-toggler" type="button" @click="show = false" v-if="currentSite != null && currentSite !== ''"> <font-awesome-icon icon="fa-regular fa-circle-xmark" class="text-white fa-2x" /> </button>

        <div class="scrollable__content p-5">
          <h3 class="text-white">{{ currentSite == null || currentSite === '' ? '請先選擇' : '' }}所屬部門</h3>

          <div class="mt-3">
            <label for="site_id" class="form-label text-white">分站</label>
            <select id="site_id" v-model="site_id" class="form-select" aria-label="請選擇類別">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in userSites" :key="key" :value="item.site_id">{{ item.name }}</option>
            </select>

            <button class="btn btn-secondary w-full mt-4" type="button" @click="onChange">確定</button>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Mobile Menu -->
  </div>
</template>

<script>
export default {
  props: ['userSites', 'currentSite'],
  data() {
    return {
      show: false,
      site_id: '',
      errors: {}
    }
  },
  watch: {
    async show(val) {
      if (val && this.currentSite) {
        this.site_id = this.currentSite.site_id
      }
    }
  },
  mounted() {
  },
  methods: {
    async onChange() {
      if (this.site_id == null) {
        return
      }

      for (let i = 0; i < this.userSites.length; i++) {
        if (this.userSites[i].site_id === this.site_id) {
          this.show = false

          // Update CurrentSite in App.vue
          await this.$root.$children[0].updateCurrentSiteData(this.userSites[i].site_id)

          setTimeout(() => {
            this.$router.go(this.$router.currentRoute)
          }, 100)
          break
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
#site-menu {
  .mobile-menu .scrollable {
    left: unset;
    right: 0;
    margin-right: -100%;
  }

  .mobile-menu.mobile-menu--active .scrollable {
    margin-left: unset;
    margin-right: 0;
  }

  .mobile-menu .scrollable .mobile-menu-toggler {
    right: unset;
    margin-right: unset;
    left: 0;
    margin-left: 1rem;
  }

  .scrollable__content {
    h3 {
      margin-top: 30px;
      padding-bottom: 4px;
      border-bottom: 2px solid #FFF;
      font-size: 20px;
    }
  }
}
</style>
