<template>
  <div id="resource-booking-page">

    <div class="flex justify-end mt-8">
      <router-link to="/resource-bookings" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
    </div>

    <div>
      <div v-if="data != null" class="intro-y xl:w-3/5 p-5 box">
        <div class="mt-3">
          <label for="user" class="form-label">員工</label>
          <div class="w-full font-bold">{{ user ? user.full_name : '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="resource" class="form-label">資源</label>
          <div class="w-full font-bold">
            <router-link :to="`/resources/${data.resource_id}`" class="text-primary">{{ resource ?  resource.name : '-' }}</router-link>
          </div>
        </div>

        <div class="mt-3">
          <label for="description" class="form-label">簡介</label>
          <div class="w-full font-bold">{{ data.description ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="last_name" class="form-label">備註</label>
          <div class="w-full font-bold">{{ data.remark ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="is_whole_day" class="form-label">全日</label>
          <div class="w-full font-bold">{{ data.is_whole_day ? '是' : '否' }}</div>
        </div>

        <div class="mt-3">
          <label for="from_time" class="form-label">借用日期時間</label>
          <div class="w-full font-bold">{{ data.from_time ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="to_time" class="form-label">歸還日期時間</label>
          <div class="w-full font-bold">{{ data.to_time ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="people_num" class="form-label">人數</label>
          <div class="w-full font-bold">{{ data.people_num ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="prepare_tea" class="form-label">備茶</label>
          <div class="w-full font-bold">{{ data.prepare_tea ? '是' : '否' }}</div>
        </div>

        <div class="mt-3">
          <label for="borrower" class="form-label">借用人/單位</label>
          <div class="w-full font-bold">{{ data.borrower ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="prepare_cup" class="form-label">備杯</label>
          <div class="w-full font-bold">{{ data.prepare_cup ? '是' : '否' }}</div>
        </div>

        <div class="mt-3">
          <label for="host" class="form-label">會議主持人</label>
          <div class="w-full font-bold">{{ data.host ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="conditioner" class="form-label">冷氣</label>
          <div class="w-full font-bold">{{ data.conditioner ?? '-' }}</div>
        </div>

        <div class="mt-3">
          <label for="booking_status" class="form-label">狀態</label>
          <div class="w-full font-bold" v-if="data">{{ getObjectValue(allStatus, 'value', data.booking_status, 'name') }}</div>
        </div>

        <div class="mt-3" v-if="profile.user_id === data.user_id">
          <label for="booking_status" class="form-label">動作</label>
          <router-link v-if="true" :to="`/update-resource-booking/${data.booking_id}`"
                       class="flex items-center mr-3 text-pending">
            <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
            &nbsp;更改
          </router-link>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions', 'profile'],
  data() {
    return {
      mounted: false,
      data: null,
      resource: null,
      user: null,

      allStatus: [
        { name: '全部', value: 'ALL' },
        { name: '已批准', value: 'Accepted' },
        { name: '已拒絕', value: 'Rejected' },
        { name: '等待審批', value: 'Pending' }
      ]
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    async currentSite(val) {
      await this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadData()

        this.mounted = true
      }
    },
    async loadData() {
      this.data = await this.getSiteResourceBooking(this.currentSite.site_id, this.$route.params.bookingId)
      this.user = await this.getUser(this.data.user_id)
      this.resource = await this.getSiteResource(this.currentSite.site_id, this.data.resource_id)
    }
  }
}
</script>

<style lang="scss" scoped>
#resource-booking-page {
  .leading-relaxed {
    min-height: 400px;
  }
}
</style>
