<template>
  <div id="site-users-page">
    <div class="flex justify-between mt-10">
      <h2 class="intro-y text-lg font-medium">
        {{ site.name }}的用戶 (共{{ total }}名)
      </h2>

      <router-link to="/management/sites" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
    </div>

    <div v-if="checkPermission(permissions, 'system.site_enrollment.view')">
      <div class="tab-content border-l border-r border-b mb-5 mt-4">
        <div class="tab-pane leading-relaxed p-5 active">

          <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center">
            <button class="btn btn-primary shadow-md mr-2" @click="onAddUserModal" v-if="checkPermission(permissions, 'system.site_enrollment.create')">添加用戶</button>

            <button v-if="selectedUserIds.length < users.length" class="btn btn-secondary shadow-md ml-2" @click="selectAllItems">
              全選
            </button>

            <button v-if="selectedUserIds.length > 0" class="btn btn-secondary shadow-md ml-2" @click="clearSelectedItems">
              清除選擇
            </button>

            <div v-if="selectedUserIds.length > 0" class="flex justify-center items-center action ml-2">
              <button type="button" class="btn btn-primary shadow-md dropdown-toggle" @click="action = !action" @focusout="action = false">
                動作
              </button>

              <div class="relative">
                <div class="dropdown-menu w-40" :class="{'show': action}">
                  <ul class="dropdown-content">
                    <li>
                      <button type="button" class="dropdown-item" @click.stop="openDeleteModal()">
                        <font-awesome-icon icon="fa-solid fa-trash" class="fa-1x w-5 mr-2"/>
                        移除
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="hidden md:block mx-auto text-slate-500">正在顯示第 {{ users.length !== 0 ? from : 0 }} 至 {{ to ?? 0 }} 名用戶</div>
            <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
              <div class="w-56 relative text-slate-500">
                <input v-model="search" type="text" class="form-control w-56 pr-10" placeholder="搜尋">
                <font-awesome-icon icon="fa-solid fa-magnifying-glass" class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0" />
              </div>
            </div>
          </div>

          <div class="overflow-x-auto mt-2">
            <table class="table table-striped">
              <thead>
              <tr>
                <th class="whitespace-nowrap">選取</th>
                <th class="whitespace-nowrap"><span v-if="search !== ''">名稱</span><order-button v-else title="類型" column="name" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="whitespace-nowrap"><span v-if="search !== ''">角色</span><order-button v-else title="角色" column="role" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="whitespace-nowrap"><span v-if="search !== ''">顯示名稱</span><order-button v-else title="顯示名稱" column="display_name" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="whitespace-nowrap"><span v-if="search !== ''">職員編號</span><order-button v-else title="職員編號" column="staff_number" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="whitespace-nowrap"><span v-if="search !== ''">服務單位</span><order-button v-else title="服務單位" column="center_id" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="whitespace-nowrap"><span v-if="search !== ''">職位名稱</span><order-button v-else title="職位名稱" column="position_id" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="whitespace-nowrap">主任級或以上</th>
                <th class="whitespace-nowrap"><span v-if="search !== ''">電郵</span><order-button v-else title="電郵" column="email" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="text-center whitespace-nowrap">啟用</th>
                <th class="text-center whitespace-nowrap">動作</th>
              </tr>
              </thead>

              <tbody>
              <tr class="" v-for="(item, key) in users" :key="key">
                <td><input class="form-check-input border border-slate-500" type="checkbox" @click.stop="onSelect(item)" :value="item.user_id" v-model="selectedUserIds"></td>
                <td class="">
                  <router-link :to="`/users/${item.user_id}`">{{ item.first_name + " " + item.last_name }}</router-link>
                </td>
                <td class="">
                  {{ item.role ? item.role.name : '-' }}
                </td>
                <td class="">
                  {{ item.display_name ?? '-' }}
                </td>
                <td class="">
                  {{ item.staff_number ?? '-' }}
                </td>
                <td class="">
                  {{ item.centre ? getObjectValue(centers, 'centre_id', item.centre.centre_id, 'abbrev') : '-' }}
                </td>
                <td class="">
                  {{ item.position ? getObjectValue(positions, 'position_id', item.position.position_id, 'name') : '-' }}
                </td>
                <td class="">
                  {{ item.is_high_level ? '是' : '否' }}
                </td>
                <td class="">
                  {{ item.email ?? '-' }}
                </td>
                <td class="">
                  <div class="flex justify-center align-items-center mx-4">
                    <div class="mr-2">關</div>
                    <div class="form-switch">
                      <input v-model="item.is_enrolled" type="checkbox" class="form-check-input" @change="toggleUser(item)">
                    </div>
                    <div class="ml-2">開</div>
                  </div>
                </td>
                <td class="w-56">
                  <div class="flex justify-center">
                    <button v-if="checkPermission(permissions, 'system.site_enrollment.edit')" class="flex items-center text-danger" @click="openDeleteModal(item)">
                      <font-awesome-icon icon="fa-regular fa-trash-can" />
                      &nbsp;移除 </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- BEGIN: Pagination -->
      <pagination-row :pages="pages" :current-page.sync="currentPage" :item-per-page.sync="itemPerPage"></pagination-row>
      <!-- END: Pagination -->

      <!-- BEGIN: Add User Modal -->
      <div id="add-user-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <!-- BEGIN: Modal Header -->
            <div class="modal-header">
              <h2 class="font-medium text-base mr-auto">
                添加用戶
              </h2>
            </div>
            <!-- END: Modal Header -->
            <!-- BEGIN: Modal Body -->
            <div class="modal-body">
              <div>
                <label for="role-id" class="form-label"><span class="text-danger">*</span> 角色</label>
                <select v-model="roleId" id="role-id" class="form-select mt-2 sm:mr-2" aria-label="Default select example">
                  <option value="">--- 請選擇 ---</option>
                  <option v-for="(item, key) in roles" :key="key" :value="item.role_id">{{ item.name }}</option>
                </select>
              </div>

              <div class="mt-3 flex hidden">
                <button type="button" class="flex-1 btn mr-2" :class="{'btn-primary': type === 'user', 'btn-secondary': type !== 'user'}" @click="type = 'user'">按用戶</button>
                <button type="button" class="flex-1 btn" :class="{'btn-primary': type === 'group', 'btn-secondary': type !== 'group'}" @click="type = 'group'">按組別</button>
              </div>

              <div class="mt-3" v-if="type === 'user'">
                <label class="form-label"><span class="text-danger">*</span> 選擇用戶</label>
                <multi-select v-model="newUsers" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true" placeholder="以名搜尋" selectLabel="按下Enter以選擇" label="searchLabel" track-by="email" :preselect-first="true" :hide-selected="true" >
                  <template slot="noResult"><span class="multiselect__option">沒有結果，請輸入其他字</span></template>
                </multi-select>
                <button type="button" class="btn btn-secondary mt-2" @click="toggleSelectAll">{{ newUsers.length > 0 ? '清除選擇' : '全選'}}</button>
              </div>

              <div class="mt-3 hidden" v-if="type === 'group'">
                <label class="form-label"><span class="text-danger">*</span> 選擇組別</label>
                <multi-select v-model="newGroup" :options="userGroups" :multiple="false" :close-on-select="true" :clear-on-select="true" :preserve-search="true" placeholder="以名搜尋" selectLabel="按下Enter以選擇" label="name" track-by="group_id" :preselect-first="true" :hide-selected="true" >
                  <template slot="noResult"><span class="multiselect__option">沒有結果，請輸入其他字</span></template>
                </multi-select>
              </div>
            </div>
            <!-- END: Modal Body -->
            <!-- BEGIN: Modal Footer -->
            <div class="modal-footer">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-20 mr-1">取消</button>
              <button type="button" class="btn btn-primary w-20" @click="onAdd" :disabled="roleId === '' || (type === 'user' && newUsers.length === 0) || (type === 'group' && newGroup == null)">儲存</button>
            </div>
            <!-- END: Modal Footer -->
          </div>
        </div>
      </div>
      <!-- END: Add User Modal -->

      <!-- BEGIN: Delete Confirmation Modal -->
      <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
                <div class="text-3xl mt-5">你確定嗎？</div>
                <div class="text-slate-500 mt-2">
                  你是否要移除此用戶？
                  <br>
                  此用戶會喪失在此分站的權限
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>

  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions'],
  data() {
    return {
      mounted: false,
      site: {
        name: null
      },

      users: [],
      userGroups: [],

      itemPerPage: 10,
      currentPage: 1,
      pages: 0,
      from: 0,
      to: 0,
      total: 0,
      search: '',
      orderBy: '',
      order: '',

      removeUser: null,

      centers: [],
      positions: [],

      roles: [],
      roleId: '',
      type: 'user',
      options: [],
      newUsers: [],
      groupId: '',
      newGroup: null,

      selectedUserIds: [],
      action: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentPage(val) {
      this.loadSiteUsers(false)
    },
    itemPerPage(val) {
      this.loadSiteUsers()
    },
    search(val) {
      this.loadSiteUsers()
    },
    order() {
      this.loadSiteUsers()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      await this.loadSite()
      await this.loadCenters()
      await this.loadPositions()
      await this.loadSiteUsers()
      await this.loadUserGroups()
      await this.loadRoles()

      setTimeout(() => {
        this.mounted = true
      }, 500)
    },
    async loadSite() {
      this.site = await this.getSite(this.$route.params.siteId)
    },
    async loadCenters() {
      this.centers = await this.getCenters()
    },
    async loadPositions() {
      this.positions = await this.getPositions()
    },
    async loadSiteUsers(resetCurrentPage = true) {
      const response = await this.getSiteUsers(this.$route.params.siteId, this.search, this.itemPerPage, this.currentPage, this.orderBy, this.order)
      this.users = response.data
      this.pages = response.meta.page.last_page
      this.from = response.meta.page.from
      this.to = response.meta.page.to
      this.total = response.meta.page.total

      if (resetCurrentPage) {
        this.currentPage = 1
      }
    },
    async loadUserGroups() {
      this.userGroups = await this.getUserGroups()
    },
    async loadUsers() {
      const users = await this.getUsers()

      for (let i = 0; i < users.length; i++) {
        let centerText = ''
        if (users[i].centre_id != null) {
          centerText = '[' + this.getObjectValue(this.centers, 'centre_id', users[i].centre_id, 'abbrev') + ']'
        }

        users[i].searchLabel = `${users[i].staff_number} - ${users[i].full_name} (${users[i].email}) ${centerText}`
      }

      this.options = users.filter((x) => {
        for (let i = 0; i < this.users.length; i++) {
          if (JSON.stringify(this.users[i]) === JSON.stringify(x)) {
            return false
          }
        }

        return true
      })
    },
    async loadRoles() {
      this.roles = await this.getRoles(this.$route.params.siteId)
    },
    onAddUserModal() {
      this.loadUsers()
      const el = document.querySelector('#add-user-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onAdd() {
      const el = document.querySelector('#add-user-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const data = {
        role_id: this.roleId
      }

      let response = null

      if (this.type === 'user') {
        data.users = this.newUsers
        response = await this.addSiteUsers(this.$route.params.siteId, data)
      } else {
        data.group_id = this.newGroup.group_id
        response = await this.addSiteGroup(this.$route.params.siteId, data)
      }

      let option = {
        title: '成功',
        message: '已添加用戶到分站',
        type: 'success'
      }

      if (response.code === 0) {
        this.roleId = ''
        this.newUsers = []
        this.newGroup = null
        await this.loadSiteUsers()
      } else {
        option = {
          title: '未能添加用戶到分站',
          message: '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    openDeleteModal(item) {
      this.removeUser = item

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      if (this.removeUser != null) {
        const response = await this.removeSiteUsers(this.$route.params.siteId, { users: [this.removeUser] })

        let option = {
          title: '成功',
          message: '用戶已從分站中移除',
          type: 'success'
        }

        if (response.code === 0) {
          await this.loadSiteUsers()
        } else {
          option = {
            title: '未能從分站移除用戶',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      } else if (this.selectedUserIds.length > 0) {
        let option = {
          title: '成功',
          message: '用戶已從分站中移除',
          type: 'success'
        }

        for (let i = 0; i < this.selectedUserIds.length; i++) {
          const item = this.getSelectedItem(this.selectedUserIds[i])
          if (item) {
            const response = await this.removeSiteUsers(this.$route.params.siteId, { users: [item] })

            if (response.code === 0) {
              console.log('Success')
            } else {
              option = {
                title: '未能從分站移除用戶',
                message: response.data.message ?? '系統錯誤，請稍後再試',
                type: 'error'
              }
              break
            }
          }
        }

        this.$notify(option)
        this.loadSiteUsers()
      }
    },
    async toggleUser(item) {
      const response = await this.toggleSiteUser(this.$route.params.siteId, item.user_id)

      let option = {
        title: '成功',
        message: '已設定用戶分站狀態',
        type: 'success'
      }

      if (response.code === 0) {
        console.log()
      } else {
        option = {
          title: '未能設定用戶分站狀態',
          message: '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    onSelect(item) {
    },
    selectAllItems() {
      this.clearSelectedItems()
      for (let i = 0; i < this.users.length; i++) {
        this.selectedUserIds.push(this.users[i].user_id)
      }
    },
    clearSelectedItems() {
      this.selectedUserIds = []
    },
    getSelectedItem(userId) {
      const index = this.users.findIndex(e => e.user_id === userId)
      if (index > -1) {
        return this.users[index]
      }
      return null
    },
    toggleSelectAll() {
      if (this.newUsers.length > 0) {
        this.newUsers = []
      } else {
        this.newUsers = this.options
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
#site-users-page {
  .tab-content {
    background-color: #FFF;

    table {
      thead {
        th:first-child, th:nth-child(3) {
          width: 120px;
        }
      }

      td {
        position: relative;

        span {
          display: inline-block;
          width: 14px;

          &.unread::before {
            top: 23px;
            left: 20px;
          }
        }
      }
    }
  }

  .action {
    .show {
      visibility: visible;
      opacity: 1;
    }

    .dropdown-menu {
      margin-top: 10px;
      left: -100px;

      button {
        width: 100%;
      }
    }
  }
}
</style>
