<template>
  <div id="resource-booking-form">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">

          <div class="">
            <label for="resource_type" class="form-label"><span class="text-danger">*</span> 資源類別</label>
            <select id="resource_type" v-model="resourceType" class="form-select" aria-label="請選擇類別" @change="form.resource_id = ''">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in resourceTypes" :key="key" :value="item.value">{{ item.key }}</option>
            </select>
            <div v-if="'resource_type' in errors" class="text-danger mt-2" v-html="errors.resource_type"></div>
          </div>

          <div class="mt-3">
            <label for="site_id" class="form-label"><span class="text-danger">*</span> 資源</label>
            <select id="site_id" v-model="form.resource_id" class="form-select" aria-label="請選擇資源" :disabled="mode === 'update' || resourceType === ''">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in filterArray()" :key="key" :value="item.resource_id">{{ item.name }} ({{ getObjectValue(sites, 'site_id', item.site_id, 'name') }})</option>
            </select>
            <div v-if="'resource_id' in errors" class="text-danger mt-2" v-html="errors.resource_id"></div>
          </div>

          <div class="mt-3">
            <label for="description" class="form-label">簡介</label>
            <input v-model="form.description" id="description" type="text" class="form-control w-full" placeholder="請輸入簡介">
            <div v-if="'description' in errors" class="text-danger mt-2" v-html="errors.description"></div>
          </div>

          <div class="mt-3">
            <label for="remark" class="form-label">備註</label>
            <input v-model="form.remark" id="remark" type="text" class="form-control w-full" placeholder="請輸入備註">
            <div v-if="'remark' in errors" class="text-danger mt-2" v-html="errors.remark"></div>
          </div>

          <div class="mt-6">
            <label for="is_whole_day">全日</label>
            <div class="form-switch mt-2">
              <input id="is_whole_day" v-model="form.is_whole_day" type="checkbox" class="form-check-input" :disabled="mode === 'update'">
            </div>

            <div v-if="'is_whole_day' in errors" class="text-danger mt-2" v-html="errors.is_whole_day"></div>
          </div>

          <div class="mt-3" v-if="form.is_whole_day">
            <label for="from_time" class="form-label"><span class="text-danger">*</span> 借用日期</label>
            <div id="from_time">
              <date-picker v-model="from_time" type="date" placeholder="請選擇借用日期" input-class="form-control w-full" :disabled-date="notBeforeToday" format="YYYY-MM-DD" :disabled="mode === 'update'"></date-picker>
            </div>
            <div v-if="'from_time' in errors" class="text-danger mt-2" v-html="errors.from_time"></div>
          </div>

          <div class="mt-3" v-if="!form.is_whole_day">
            <label for="from_time" class="form-label"><span class="text-danger">*</span> 借用日期時間</label>
            <div id="from_time">
              <date-picker v-model="from_time" type="datetime" placeholder="請選擇借用日期時間" input-class="form-control w-full" :disabled-date="notBeforeDate" :disabled-time="notBeforeTime" :time-picker-options="{start: '00:00', step:'00:30' , end: '23:00', format: 'HH:mm'}" format="YYYY-MM-DD HH:mm" :disabled="mode === 'update'"></date-picker>
            </div>
            <div v-if="'from_time' in errors" class="text-danger mt-2" v-html="errors.from_time"></div>
          </div>

          <div class="mt-3" v-if="!form.is_whole_day">
            <label for="end_at" class="form-label"><span class="text-danger">*</span> 歸還日期時間</label>
            <div id="end_at">
              <date-picker v-model="to_time" type="datetime" placeholder="請選擇歸還日期時間" input-class="form-control w-full" :disabled-date="notBeforeDate2" :disabled-time="notBeforeTime2" :time-picker-options="{start: '00:00', step:'00:30' , end: '23:30', format: 'HH:mm'}" format="YYYY-MM-DD HH:mm" :disabled="mode === 'update'"></date-picker>
            </div>
            <div v-if="'to_time' in errors" class="text-danger mt-2" v-html="errors.to_time"></div>
          </div>

          <div class="mt-3">
            <label for="people_num" class="form-label">人數</label>
            <input v-model="form.people_num" id="people_num" type="text" class="form-control w-full" placeholder="請輸入人數">
            <div v-if="'people_num' in errors" class="text-danger mt-2" v-html="errors.people_num"></div>
          </div>

          <div class="mt-6">
            <label for="prepare_tea">備茶</label>
            <div class="form-switch mt-2">
              <input id="prepare_tea" v-model="form.prepare_tea" type="checkbox" class="form-check-input">
            </div>

            <div v-if="'prepare_tea' in errors" class="text-danger mt-2" v-html="errors.prepare_tea"></div>
          </div>

          <div class="mt-3">
            <label for="borrower" class="form-label">借用人/單位</label>
            <input v-model="form.borrower" id="borrower" type="text" class="form-control w-full" placeholder="請輸入借用人/單位">
            <div v-if="'borrower' in errors" class="text-danger mt-2" v-html="errors.borrower"></div>
          </div>

          <div class="mt-6">
            <label for="prepare_cup">備杯</label>
            <div class="form-switch mt-2">
              <input id="prepare_cup" v-model="form.prepare_cup" type="checkbox" class="form-check-input">
            </div>

            <div v-if="'prepare_cup' in errors" class="text-danger mt-2" v-html="errors.prepare_cup"></div>
          </div>

          <div class="mt-3">
            <label for="host" class="form-label">會議主持人</label>
            <input v-model="form.host" id="borrower" type="text" class="form-control w-full" placeholder="請輸入會議主持人">
            <div v-if="'host' in errors" class="text-danger mt-2" v-html="errors.host"></div>
          </div>

          <div class="mt-3">
            <label for="conditioner" class="form-label">冷氣</label>
            <input v-model="form.conditioner" id="conditioner" type="text" class="form-control w-full" placeholder="請輸入冷氣">
            <div v-if="'conditioner' in errors" class="text-danger mt-2" v-html="errors.conditioner"></div>
          </div>

          <div class="mt-6">
            <label for="repeat_enable">重覆</label>
            <div class="form-switch mt-2">
              <input id="repeat_enable" v-model="form.repeat_enable" type="checkbox" class="form-check-input">
            </div>

            <div v-if="'repeat_enable' in errors" class="text-danger mt-2" v-html="errors.repeat_enable"></div>
          </div>

          <div class="mt-3" v-if="form.repeat_enable">
            <label for="repeat_start" class="form-label"><span class="text-danger">*</span> 重覆開始日期</label>
            <div id="repeat_start">
              <date-picker v-model="repeat_start" type="date" placeholder="請選擇重覆開始日期" input-class="form-control w-full" :disabled-date="notBeforeDate3" format="YYYY-MM-DD"></date-picker>
            </div>
            <div v-if="'repeat_start' in errors" class="text-danger mt-2" v-html="errors.repeat_start"></div>
          </div>

          <div class="mt-3" v-if="form.repeat_enable">
            <label for="repeat_end" class="form-label"><span class="text-danger">*</span> 重覆結束日期</label>
            <div id="repeat_end">
              <date-picker v-model="repeat_end" type="date" placeholder="請選擇重覆結束日期" input-class="form-control w-full" :disabled-date="notBeforeDate4" format="YYYY-MM-DD"></date-picker>
            </div>
            <div v-if="'repeat_end' in errors" class="text-danger mt-2" v-html="errors.repeat_end"></div>
          </div>

          <div class="mt-3" v-if="form.repeat_enable">
            <div class="flex flex-wrap align-items-center">
              <div class="flex align-items-center mb-2 mr-4" v-for="(item, key) in weekDays" :key="key">
                <label :for="`repeat_week_day-${key}`">{{ item }}</label>

                <div class="form-switch ml-2">
                  <input :id="`repeat_week_day-${key}`" v-model="form.repeat_week_day" type="checkbox" class="form-check-input" :value="key">
                </div>
              </div>
            </div>

            <div v-if="'allowedSites' in errors" class="text-danger mt-2" v-html="errors.allowedSites"></div>
          </div>

          <div class="flex justify-end align-items-center mt-5">
            <router-link v-if="!loading && !finish" to="/resource-bookings" type="button" class="btn btn-outline-secondary w-24 mr-1">取消</router-link>
            <button v-if="!loading && !finish" type="button" class="btn btn-primary w-24" @click="onSave">儲存</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>

        <!-- END: Form Layout -->
      </div>

      <div class="intro-y col-span-12 lg:col-span-6" v-if="mode === 'create' && form.resource_id !== '' && from_time != null && to_time != null && $moment(from_time).isBefore(to_time)">
        <div class="intro-y box p-5">

          <div class="font-bold">所選擇日期時段內的其他預約紀錄</div>

          <div class="flex info mt-2">
            <div class="flex align-items-center mr-6">
              <div class="space booked mr-2"></div>
              <div>其他預約</div>
            </div>

            <div class="flex align-items-center">
              <div class="space booking mr-2"></div>
              <div>你的預約</div>
            </div>
          </div>

          <div class="list mt-3 overflow-x-auto">
            <div class="section">
              <div class="time">&nbsp;</div>
              <div class="space" v-for="(item, key) in range" :key="key">{{ $moment(item).format('YYYY-MM-DD') }}</div>
            </div>

            <div class="section" :class="{'last': i === 24}" v-for="i in 24" :key="i" ref="sections">
              <div class="time">{{ `${(i - 1)}:00 - ${(i - 1) + 1}:00`}}</div>
              <div class="space" v-for="(item, key) in range" :key="key"></div>
            </div>

            <div class="slots">
              <div class="slot" v-for="(item, key) in slots" :key="key" :style="`left: ${item.left}px; top: ${item.top}px; height: ${item.height}px;`" :class="{'booked': item.booked, 'booking': !item.booked}"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['site', 'data'],
  data() {
    return {
      mode: 'create',
      resources: [],
      resourceType: '',
      form: {
        resource_id: '',
        description: null,
        remark: null,
        from_time: null,
        to_time: null,
        is_whole_day: false,
        people_num: null,
        prepare_tea: false,
        borrower: null,
        prepare_cup: false,
        host: null,
        conditioner: '',
        repeat_enable: false,
        repeat_start: null,
        repeat_end: null,
        repeat_week_day: []
      },

      from_time: null,
      to_time: null,
      repeat_start: null,
      repeat_end: null,
      range: [],
      slots: [],

      sites: [],

      errors: {},
      loading: false,
      finish: false
    }
  },
  watch: {
    site() {
      this.init()
    },
    'form.is_whole_day'(val) {
      if (val && this.from_time) {
        this.from_time = this.$moment(this.from_time).startOf('day').toDate()
        this.to_time = this.$moment(this.from_time).endOf('day').toDate()
        this.setRange()
      }
    },
    from_time(val) {
      if (this.form.is_whole_day) {
        this.to_time = this.$moment(val).endOf('day').toDate()
      }
      this.setRange()
    },
    to_time() {
      this.setRange()
    },
    'form.resource_id'() {
      this.setRange()
    },
    data(val) {
      if (val != null) {
        this.form = this.data
        this.from_time = this.form.from_time != null ? this.$moment(this.form.from_time).toDate() : null
        this.to_time = this.form.to_time != null ? this.$moment(this.form.to_time).toDate() : null
        this.mode = 'update'
      }
    }
  },
  mounted() {
    window.tailwind.svgLoader()
    this.init()
  },
  methods: {
    async init() {
      await this.loadSites()

      if (this.site) {
        await this.loadResources()
      }

      if (this.data == null && this.mode === 'create') {
        this.from_time = this.$route.query.date != null ? this.$moment(this.$route.query.date).toDate() : null
      }
    },
    async loadSites() {
      this.sites = await this.getSites()
    },
    async loadResources() {
      const response = await this.getSiteAvailableResources(this.site.site_id, '', '', 99999999, 1)
      this.resources = response.data
    },
    filterArray() {
      const array = []
      for (let i = 0; i < this.resources.length; i++) {
        if (this.resources[i].resource_type === this.resourceType) {
          array.push(this.resources[i])
        }
      }

      return array
    },
    notBeforeToday(date) {
      const now = new Date()
      return date < new Date(now.getFullYear(), now.getMonth(), now.getDate())
    },
    notBeforeDate(date) {
      if (this.to_time) {
        const now = this.to_time
        return date > new Date(now.getFullYear(), now.getMonth(), now.getDate())
      } else {
        const now = new Date()
        return date < new Date(now.getFullYear(), now.getMonth(), now.getDate())
      }
    },
    notBeforeTime(date) {
      if (this.to_time) {
        const now = this.to_time
        return date >= new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds())
      } else {
        const now = new Date()
        return date < new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds())
      }
    },
    notBeforeDate2(date) {
      const now = this.from_time ?? new Date()
      return date < new Date(now.getFullYear(), now.getMonth(), now.getDate())
    },
    notBeforeTime2(date) {
      const now = this.from_time ?? new Date()
      return date <= new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours(), now.getMinutes(), now.getSeconds())
    },
    notBeforeDate3(date) {
      if (this.repeat_end) {
        const now = this.repeat_end
        return date > new Date(now.getFullYear(), now.getMonth(), now.getDate())
      } else {
        const now = new Date()
        return date < new Date(now.getFullYear(), now.getMonth(), now.getDate())
      }
    },
    notBeforeDate4(date) {
      if (this.repeat_start) {
        const now = this.repeat_start
        return date < new Date(now.getFullYear(), now.getMonth(), now.getDate())
      } else {
        const now = new Date()
        return date < new Date(now.getFullYear(), now.getMonth(), now.getDate())
      }
    },
    setRange() {
      if (this.mode !== 'create') {
        return
      }

      if (this.from_time != null && this.to_time != null && this.$moment(this.from_time).isBefore(this.to_time)) {
        const range = []
        let current = this.$moment(this.from_time)

        do {
          range.push(current)
          current = this.$moment(current).add(1, 'd')
        } while (current.isSameOrBefore(this.to_time, 'day'))

        this.range = range

        if (this.form.resource_id !== '') {
          setTimeout(() => {
            this.setSlots()
          }, 200)
        }
      }
    },
    setSlots() {
      let slots = []

      // Select Resource
      const bookings = this.getObjectValue(this.resources, 'resource_id', parseInt(this.form.resource_id), 'bookings')
      const selectFrom = this.$moment(this.from_time).startOf('day')
      const selectTo = this.$moment(this.to_time).endOf('day')

      slots = slots.concat(this.checkSlot(selectFrom, selectTo, this.$moment(this.from_time), this.$moment(this.to_time), false))

      for (let i = 0; i < bookings.length; i++) {
        const bookingFrom = this.$moment(bookings[i].from_time)
        const bookingTo = this.$moment(bookings[i].to_time)

        slots = slots.concat(this.checkSlot(selectFrom, selectTo, bookingFrom, bookingTo))
      }

      this.slots = slots
    },
    checkSlot(selectFrom, selectTo, bookingFrom, bookingTo, booked = true) {
      const slots = []
      // Check if booking day is in select day range
      if (selectFrom.isSameOrBefore(bookingTo, 'day') && bookingFrom.isSameOrBefore(selectTo, 'day')) {
        // Check if the booking start and end day is in the same day
        if (bookingFrom.isSame(bookingTo, 'day')) {
          slots.push(this.calculateSlot(bookingFrom, bookingTo, selectFrom, booked))
        } else {
          let current = this.$moment(bookingFrom).startOf('day')
          do {
            if (selectFrom.isSameOrBefore(current) && selectTo.isSameOrAfter(current)) {
              if (current.isSame(bookingFrom, 'day')) {
                slots.push(this.calculateSlot(bookingFrom, this.$moment(current).endOf('day'), selectFrom, booked))
              } else if (current.isSame(bookingTo, 'day')) {
                slots.push(this.calculateSlot(current, bookingTo, selectFrom, booked))
              } else {
                slots.push(this.calculateSlot(current, this.$moment(current).endOf('day'), selectFrom, booked))
              }
            }

            current = this.$moment(current).add(1, 'd')
          } while (current.isSameOrBefore(this.$moment(bookingTo).startOf('day'), 'day'))
        }
      }

      return slots
    },
    calculateSlot(bookingFrom, bookingTo, selectFrom, booked = true) {
      const sections = this.$refs.sections
      const rowHeight = sections[0].clientHeight
      const defaultTimetableHeight = rowHeight * 24 // in px, set in web.css
      const spaceWidth = 90

      const hour = bookingFrom.hour()
      const minute = bookingFrom.minute()

      const startMinute = hour * 60 + minute
      const totalMinute = bookingTo.diff(bookingFrom, 'minutes')
      const totalMinutesInADay = 24 * 60

      const slotLeft = this.$moment(bookingFrom).startOf('day').diff(selectFrom, 'day') * spaceWidth
      const slotTop = startMinute / totalMinutesInADay * defaultTimetableHeight + rowHeight + 1
      const slotHeight = totalMinute / totalMinutesInADay * defaultTimetableHeight

      return { left: slotLeft, top: slotTop, height: slotHeight, booked }
    },
    onSave: async function() {
      this.loading = true
      this.errors = {}

      if (this.from_time != null) {
        this.form.from_time = this.$moment(this.from_time).format()
      }
      if (this.to_time != null) {
        this.form.to_time = this.$moment(this.to_time).format()
      }

      if (this.repeat_start != null) {
        this.form.repeat_start = this.$moment(this.repeat_start).format()
      }
      if (this.repeat_end != null) {
        this.form.repeat_end = this.$moment(this.repeat_end).format()
      }

      let response

      if (this.mode === 'create') {
        response = await this.createSiteResourceBooking(this.site.site_id, this.form)
      } else {
        response = await this.updateSiteResourceBooking(this.site.site_id, this.data.booking_id, this.form)
      }

      if ('errors' in response.data) {
        this.errors = response.data.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? '已成功預約' : '已成功更改預約',
          message: '請稍後，將會轉至會議室及資源預借頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          setTimeout(() => {
            this.$router.push('/resource-bookings')
          }, 1000)
        } else if (response.data.code === 100) {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? '未能預約' : '未能更改預約',
            message: '你選擇的時間已被其他人佔用',
            type: 'error'
          }
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? '未能預約' : '未能更改預約',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    }
  }
}
</script>

<style scoped lang="scss">
#resource-booking-form {
  .info {
    .space {
      border: 1px solid #ccc;
      width: 90px;
      height: 32px;
    }
  }

  .list {
    position: relative;
    border: 1px solid #ccc;
    border-radius: 0.25rem;

    .section {
      display: flex;
      height: 32px;

      &:first-child {
        .time, .space {
          border-top: 0;
        }
      }

      &.last {
        .time, .space {
          border-bottom: 1px solid #ccc;
        }
      }

      .time, .space {
        flex: 0 0 90px;
        text-align: center;
        border-top: 1px solid #ccc;
        border-right: 1px solid #ccc;
        line-height: 31px;
      }
    }

    .slots {
      position: absolute;
      left: 90px;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;

      .slot {
        position: absolute;
        left: 0;
        width: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 12px;
      }
    }
  }

  .booked {
    background-color: rgba(185, 28, 28, 0.5);
  }

  .booking {
    background-color: rgba(13, 148, 136, 0.2);
  }
}
</style>
