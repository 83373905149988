import { Iframe } from 'element-tiptap'
import InsertYoutubeButton from './InsertYoutube'

export default class Bold extends Iframe {
  // here you can extend the extension like tiptap, such as schema, plugins ...

  menuBtnView(editorContext) {
    return {
      component: InsertYoutubeButton,
      componentProps: {
      },
      componentEvents: {
        insert: (url) => {
          const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
          const match = url.match(regExp)
          const id = (match && match[7].length === 11) ? match[7] : false

          if (id) {
            editorContext.commands.iframe({
              width: '560',
              height: '315',
              src: `https://www.youtube.com/embed/${id}`,
              title: 'YouTube video player',
              frameborder: '0',
              allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share',
              allowfullscreen: true
            })
          }
        }
      }
    }
  }
}
