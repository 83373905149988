<template>
  <div id="form-response-page">

    <div v-if="!blocked" class="flex justify-between mt-10">
      <h2 v-if="form != null && user != null" class="intro-y text-lg font-medium">
        {{ form.name }}
        <br>
        {{ user.display_name }}的回覆
      </h2>

      <router-link v-if="form != null" :to="(userId != null && userId != '') ? `/forms/${form.form_id}` : '/forms'" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
    </div>

    <div v-if="data && !blocked" class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <div class="flex justify-between">
          <select v-model="currentResponseId" class="form-select" :class="{'mr-2': self === true && form.is_response_editable}">
            <option v-for="(item, key) in data" :key="key" :value="item.response_id">於{{ item.created_at }}的回覆</option>
          </select>

          <router-link v-if="self && form.is_response_editable" :to="`/forms/${$route.params.formId}/update-response/${currentResponseId}`" class="btn btn-pending w-20 ">修改</router-link>
        </div>

        <!-- BEGIN: Form Layout -->
        <div v-if="response" class="intro-y box p-5 mt-4">

          <div v-for="(section, i) in response.response" :key="i" :class="{'mt-4 pt-4': i > 0}">
            <div class="intro-y col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6" :class="{'mt-8': i > 0}">
              <span>{{ section.display_order }}. {{ section.section_name }}</span>
            </div>

            <div v-for="(item, key) in section.items" :key="key" :class="{'mt-4 border-t pt-4': key > 0}">
              <label class="form-label font-medium text-primary">{{ section.display_order + '.' + item.display_order }} {{ item.title }}</label>

              <div v-if="item.form_item_type === 'text' || item.form_item_type === 'long-text' || item.form_item_type === 'file' || item.form_item_type === 'date'" class="font-medium">
                <span v-if="item.responses.length > 0">{{ item.responses[0].content }}</span>
                <span v-else>-</span>
              </div>

              <div v-else-if="item.form_item_type === 'centre'">
                <span v-if="item.responses.length > 0">{{ getObjectValue(centers, 'centre_id', item.responses[0].option_id, 'name_zh') }}</span>
                <span v-else>-</span>
              </div>

              <div v-else-if="item.form_item_type === 'post'">
                <span v-if="item.responses.length > 0">{{ getObjectValue(positions, 'position_id', item.responses[0].option_id, 'name') }}</span>
                <span v-else>-</span>
              </div>

              <div v-else>
                <div v-for="(option, i) in item.options" :key="i" :class="{'inactive': checkSelected(option, item.responses)}" class="font-medium">
                  {{ option.title }} {{ option.is_custom ? `${getContent(option, item.responses)}` : '' }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>

    <div v-else-if="blocked">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,
      userId: null,
      form: null,
      user: null,
      centers: [],
      positions: [],
      data: null,
      response: null,
      blocked: false,
      self: false,

      currentResponseId: null,
      currentResponseKey: null,
      errors: {},
      loading: false,
      finish: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    async currentSite(val) {
      await this.init()
    },
    async currentResponseId() {
      await this.loadResponse()
    }
  },
  async mounted() {
    window.tailwind.svgLoader()

    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadCenters()
        await this.loadPositions()
        await this.loadData()

        this.mounted = true
      }
    },
    async loadCenters() {
      this.centers = await this.getCenters()
    },
    async loadPositions() {
      this.positions = await this.getPositions()
    },
    async loadData() {
      this.form = await this.getSiteForm(this.currentSite.site_id, this.$route.params.formId)

      this.userId = this.$route.query.userId ?? null

      if (this.userId != null && this.userId !== '') {
        if (this.checkPermission(this.permissions, 'site.form.view')) {
          this.user = await this.getUser(this.userId)
          this.data = await this.getUserResponse(this.currentSite.site_id, this.$route.params.formId, this.userId)
        } else {
          this.blocked = true
        }
      } else {
        this.user = this.$cookies.get('profile')

        if (this.user == null) {
          const response = await this.getProfile()
          await this.$cookies.set('profile', response.profile)
          this.user = response.profile
        }

        this.data = await this.getMyResponse(this.currentSite.site_id, this.$route.params.formId)
        this.self = true
      }

      if (this.data.length > 0) {
        this.currentResponseId = this.data[0].response_id
      }
    },
    async loadResponse() {
      this.response = await this.getResponse(this.currentSite.site_id, this.$route.params.formId, this.currentResponseId)
    },
    checkSelected(option, responses) {
      for (let i = 0; i < responses.length; i++) {
        if (responses[i].form_item_id === option.form_item_id && responses[i].option_id === option.option_id) {
          return false
        }
      }
      return true
    },
    getContent(option, responses) {
      for (let i = 0; i < responses.length; i++) {
        if (responses[i].form_item_id === option.form_item_id && responses[i].option_id === option.option_id) {
          return `(${responses[i].content})`
        }
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
#form-response-page {
  .leading-relaxed {
    min-height: 400px;
  }

  .type {
    font-size: 16px;
  }

  .inactive {
    color: #bdbdbd;
  }
}
</style>
