<template>
  <div id="update-data-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      更改表格
    </h2>

    <performance-report-table-form v-if="checkPermission(permissions, 'system.performance-report.manage')" :data="data" :auth="auth" :permissions="permissions"></performance-report-table-form>
    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions'],
  data() {
    return {
      mounted: false,
      data: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      await this.loadData()
      this.mounted = true
    },
    async loadData() {
      this.data = await this.getPerformanceReportSectionTable(this.$route.params.sectionId, this.$route.params.tableId)
    }
  }
}
</script>

<style lang="scss" scoped></style>
