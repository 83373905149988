<template>
  <div id="line-of-supervisions-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      主管經理 (共{{ allLineOfSupervisions.length }}個)
    </h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
        <router-link to="/management/create-line-of-supervision" class="btn btn-primary shadow-md mr-2" v-if="checkPermission(permissions, ['system.line-of-supervision.create'])">新增經理</router-link>
        <div class="hidden md:block mx-auto text-slate-500">正在顯示第
          {{ filterLineOfSupervisions.length !== 0 ? parseInt((currentPage - 1) * itemPerPage + 1) : 0 }} 至 {{
            (((currentPage - 1) * itemPerPage + itemPerPage) < filterLineOfSupervisions.length) ? parseInt((currentPage - 1) * itemPerPage + itemPerPage) : filterLineOfSupervisions.length
          }} 個經理
        </div>
        <div v-if="false" class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-slate-500">
            <input v-model="search" type="text" class="form-control w-56 box pr-10" placeholder="搜尋">
            <font-awesome-icon icon="fa-solid fa-magnifying-glass"
                               class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0"/>
          </div>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
          <tr>
            <th class="whitespace-nowrap">名稱</th>
            <th class="text-center whitespace-nowrap">動作</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, key) in LineOfSupervisions" :key="key" class="intro-x">
            <td>
              <div class="font-medium whitespace-nowrap">{{ item.name }}</div>
            </td>
            <td class="table-report__action w-56" v-if="checkPermission(permissions, ['system.line-of-supervision.update', 'system.line-of-supervision.delete'])">
              <div class="flex justify-center items-center">
                <router-link :to="`/management/update-line-of-supervision/${item.id}`"
                             class="flex items-center mr-3">
                  <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
                  &nbsp;更改
                </router-link>
                <button class="flex items-center text-danger" @click="openDeleteModal(item.id)">
                  <font-awesome-icon icon="fa-regular fa-trash-can"/>
                  &nbsp;刪除
                </button>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Pagination -->
      <pagination-row :pages="pages" :current-page.sync="currentPage"
                      :item-per-page.sync="itemPerPage"></pagination-row>
      <!-- END: Pagination -->
    </div>

    <!-- BEGIN: Delete Confirmation Modal -->
    <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger"/>
              <div class="text-3xl mt-5">你確定嗎？</div>
              <div class="text-slate-500 mt-2">
                你是否要刪除此類別？
                <br>
                這動作不能還原。
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
              <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions'],
  data() {
    return {
      sites: [],
      allLineOfSupervisions: [],
      filterLineOfSupervisions: [],
      LineOfSupervisions: [],
      itemPerPage: 10,
      currentPage: 0,
      pages: 0,
      search: null,
      deleteId: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentPage(val) {
      this.updateList()
    },
    itemPerPage(val) {
      this.pages = Math.ceil(this.filterLineOfSupervisions.length / val)
      if (this.currentPage === 1) {
        this.updateList()
      }

      this.currentPage = 1
    },
    search(val) {
      if (val == null || val === '') {
        this.filterLineOfSupervisions = this.allLineOfSupervisions
      } else {
        this.filterLineOfSupervisions = this.allLineOfSupervisions.filter(o => ((o.name != null && o.name.includes(val)) || (o.description != null && o.description.includes(val))))
      }

      this.pages = Math.ceil(this.filterLineOfSupervisions.length / this.itemPerPage)
      this.currentPage = 1
      this.updateList()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      await this.loadSites()
      await this.loadLineOfSupervisions()
    },
    async loadSites() {
      this.sites = await this.getSites()
    },
    async loadLineOfSupervisions() {
      this.filterLineOfSupervisions = this.allLineOfSupervisions = await this.getLineOfSupervisions('ALL')
      this.pages = Math.ceil(this.filterLineOfSupervisions.length / this.itemPerPage)
      this.currentPage = 1
    },
    updateList() {
      this.LineOfSupervisions = this.filterLineOfSupervisions.slice((this.currentPage - 1) * this.itemPerPage, (this.currentPage - 1) * this.itemPerPage + this.itemPerPage)
    },
    openDeleteModal(id) {
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteLineOfSupervision(this.deleteId)

      let option = {
        title: '成功',
        message: '類別已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        await this.loadLineOfSupervisions()
        this.updateList()
      } else {
        option = {
          title: '未能刪除類別',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped></style>
