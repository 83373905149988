<template>
  <div id="user-group-users-page">
    <div class="flex justify-between mt-10">
      <h2 class="intro-y text-lg font-medium">
        {{ userGroup.name }}的用戶 (共{{ users.length }}名)
      </h2>

      <router-link to="/management/user-groups" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
    </div>

    <div>
      <div class="tab-content border-l border-r border-b mb-5 mt-4">
        <div class="tab-pane leading-relaxed p-5 active">

          <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center">
            <button class="btn btn-primary shadow-md mr-2" @click="onAddUserModal">添加用戶</button>

            <button v-if="selectedUserIds.length < users.length" class="btn btn-secondary shadow-md ml-2" @click="selectAllItems">
              全選
            </button>

            <button v-if="selectedUserIds.length > 0" class="btn btn-secondary shadow-md ml-2" @click="clearSelectedItems">
              清除選擇
            </button>

            <div v-if="selectedUserIds.length > 0" class="flex justify-center items-center action ml-2">
              <button type="button" class="btn btn-primary shadow-md dropdown-toggle" @click="action = !action" @focusout="action = false">
                動作
              </button>

              <div class="relative">
                <div class="dropdown-menu w-40" :class="{'show': action}">
                  <ul class="dropdown-content">
                    <li>
                      <button type="button" class="dropdown-item" @click.stop="openDeleteModal()">
                        <font-awesome-icon icon="fa-solid fa-trash" class="fa-1x w-5 mr-2"/>
                        移除
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="hidden md:block mx-auto text-slate-500">正在顯示第 {{ filterUsers.length !== 0 ? parseInt((currentPage - 1) * itemPerPage + 1) : 0 }} 至 {{ (((currentPage - 1) * itemPerPage + itemPerPage) < filterUsers.length) ? parseInt((currentPage - 1) * itemPerPage + itemPerPage) : filterUsers.length }} 名用戶</div>
            <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
              <div class="w-56 relative text-slate-500">
                <input v-model="search" type="text" class="form-control w-56 pr-10" placeholder="搜尋">
                <font-awesome-icon icon="fa-solid fa-magnifying-glass" class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0" />
              </div>
            </div>
          </div>

          <div class="overflow-x-auto mt-2">
            <table class="table table-striped">
              <thead>
              <tr>
                <th class="whitespace-nowrap">選取</th>
                <th class="whitespace-nowrap">名稱</th>
                <th class="whitespace-nowrap">顯示名稱</th>
                <th class="whitespace-nowrap">職員編號</th>
                <th class="whitespace-nowrap">服務單位</th>
                <th class="whitespace-nowrap">職位名稱</th>
                <th class="whitespace-nowrap">主任級或以上</th>
                <th class="whitespace-nowrap">電郵</th>
                <th class="text-center whitespace-nowrap">動作</th>
              </tr>
              </thead>

              <tbody>
              <tr class="" v-for="(item, key) in users" :key="key">
                <td><input class="form-check-input border border-slate-500" type="checkbox" @click.stop="onSelect(item)" :value="item.user_id" v-model="selectedUserIds"></td>
                <td class="">
                  <router-link :to="`/users/${item.user_id}`">{{ item.first_name + " " + item.last_name }}</router-link>
                </td>
                <td class="">
                  {{ item.display_name ?? '-' }}
                </td>
                <td class="">
                  {{ item.staff_number ?? '-' }}
                </td>
                <td class="">
                  {{ item.centre ? getObjectValue(centers, 'centre_id', item.centre.centre_id, 'abbrev') : '-' }}
                </td>
                <td class="">
                  {{ item.position ? getObjectValue(positions, 'position_id', item.position.position_id, 'name') : '-' }}
                </td>
                <td class="">
                  {{ item.is_high_level ? '是' : '否' }}
                </td>
                <td class="">
                  {{ item.email ?? '-' }}
                </td>
                <td class="w-56">
                  <div class="flex justify-center">
                    <button class="flex items-center text-danger" @click="openDeleteModal(item)">
                      <font-awesome-icon icon="fa-regular fa-trash-can" />
                      &nbsp;移除 </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- BEGIN: Pagination -->
      <pagination-row :pages="pages" :current-page.sync="currentPage" :item-per-page.sync="itemPerPage"></pagination-row>
      <!-- END: Pagination -->

      <!-- BEGIN: Add User Modal -->
      <div id="add-user-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <!-- BEGIN: Modal Header -->
            <div class="modal-header">
              <h2 class="font-medium text-base mr-auto">
                添加用戶
              </h2>
            </div>
            <!-- END: Modal Header -->
            <!-- BEGIN: Modal Body -->
            <div class="modal-body">
              <div class="">
                <label class="form-label"><span class="text-danger">*</span> 選擇用戶</label>
                <multi-select v-model="newUsers" :options="options" :multiple="true" :close-on-select="false" :clear-on-select="true" :preserve-search="true" placeholder="以名搜尋" selectLabel="按下Enter以選擇" label="searchLabel" track-by="email" :preselect-first="true" :hide-selected="true" >
                  <template slot="noResult"><span class="multiselect__option">沒有結果，請輸入其他字</span></template>
                </multi-select>
                <button type="button" class="btn btn-secondary mt-2" @click="toggleSelectAll">{{ newUsers.length > 0 ? '清除選擇' : '全選'}}</button>
              </div>
            </div>
            <!-- END: Modal Body -->
            <!-- BEGIN: Modal Footer -->
            <div class="modal-footer">
              <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-20 mr-1">取消</button>
              <button type="button" class="btn btn-primary w-20" @click="onAdd" :disabled="newUsers.length === 0">儲存</button>
            </div>
            <!-- END: Modal Footer -->
          </div>
        </div>
      </div>
      <!-- END: Add User Modal -->

      <!-- BEGIN: Delete Confirmation Modal -->
      <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
                <div class="text-3xl mt-5">你確定嗎？</div>
                <div class="text-slate-500 mt-2">
                  你是否要移除此用戶？
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->
    </div>

  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions', 'currentSite'],
  data() {
    return {
      mounted: false,
      userGroup: {
        name: null
      },

      allUsers: [],
      filterUsers: [],
      users: [],

      itemPerPage: 10,
      currentPage: 0,
      pages: 0,
      search: null,

      removeUser: null,

      centers: [],
      positions: [],

      roles: [],
      roleId: '',
      options: [],
      newUsers: [],

      selectedUserIds: [],
      action: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    },
    currentPage(val) {
      this.updateList()
    },
    itemPerPage(val) {
      this.pages = Math.ceil(this.filterUsers.length / val)
      if (this.currentPage === 1) {
        this.updateList()
      }

      this.currentPage = 1
    },
    search(val) {
      if (val == null || val === '') {
        this.filterUsers = this.allUsers
      } else {
        this.filterUsers = this.allUsers.filter(o => (o.name != null && o.name.includes(val)) || (o.description != null && o.description.includes(val)))
      }

      this.pages = Math.ceil(this.filterUsers.length / this.itemPerPage)
      this.currentPage = 1
      this.updateList()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadUserGroup()
        await this.loadCenters()
        await this.loadPositions()

        setTimeout(() => {
          this.mounted = true
        }, 500)
      }
    },
    async loadUserGroup() {
      const response = await this.getUserGroup(this.currentSite.site_id, this.$route.params.groupId)
      if (response.code === 0) {
        this.userGroup = response.data
        this.filterUsers = this.allUsers = this.userGroup.enrollments
        this.pages = Math.ceil(this.filterUsers.length / this.itemPerPage)
        this.currentPage = 1
        this.updateList()
      }
    },
    updateList() {
      this.users = this.filterUsers.slice((this.currentPage - 1) * this.itemPerPage, (this.currentPage - 1) * this.itemPerPage + this.itemPerPage)
    },
    async loadCenters() {
      this.centers = await this.getCenters()
    },
    async loadPositions() {
      this.positions = await this.getPositions()
    },
    async loadUsers() {
      const users = await this.getUsers()

      for (let i = 0; i < users.length; i++) {
        let centerText = ''
        if (users[i].centre_id != null) {
          centerText = '[' + this.getObjectValue(this.centers, 'centre_id', users[i].centre_id, 'abbrev') + ']'
        }

        users[i].searchLabel = `${users[i].staff_number} - ${users[i].full_name} (${users[i].email}) ${centerText}`
      }

      this.options = users.filter((x) => {
        for (let i = 0; i < this.users.length; i++) {
          if (JSON.stringify(this.users[i]) === JSON.stringify(x)) {
            return false
          }
        }

        return true
      })
    },
    onAddUserModal() {
      this.loadUsers()
      const el = document.querySelector('#add-user-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onAdd() {
      const el = document.querySelector('#add-user-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.addUserGroupUsers(this.currentSite.site_id, this.$route.params.groupId, { users: this.newUsers })

      let option = {
        title: '成功',
        message: '已添加用戶到組別',
        type: 'success'
      }

      if (response.code === 0) {
        this.roleId = ''
        this.newUsers = []
        await this.loadUserGroup()
      } else {
        option = {
          title: '未能添加用戶到組別',
          message: '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    openDeleteModal(item) {
      this.removeUser = item

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      if (this.removeUser != null) {
        const response = await this.removeUserGroupUsers(this.currentSite.site_id, this.$route.params.groupId, { users: [this.removeUser] })

        let option = {
          title: '成功',
          message: '用戶已從組別中移除',
          type: 'success'
        }

        if (response.code === 0) {
          await this.loadUserGroup()
        } else {
          option = {
            title: '未能從組別移除用戶',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      } else if (this.selectedUserIds.length > 0) {
        let option = {
          title: '成功',
          message: '用戶已從組別中移除',
          type: 'success'
        }

        for (let i = 0; i < this.selectedUserIds.length; i++) {
          const item = this.getSelectedItem(this.selectedUserIds[i])
          if (item) {
            const response = await this.removeUserGroupUsers(this.currentSite.site_id, this.$route.params.groupId, { users: [item] })

            if (response.code === 0) {
              console.log('Success')
            } else {
              option = {
                title: '未能從組別移除用戶',
                message: response.data.message ?? '系統錯誤，請稍後再試',
                type: 'error'
              }
              break
            }
          }
        }
        this.selectedUserIds = []

        this.$notify(option)
        this.loadUserGroup()
      }
    },
    onSelect(item) {
    },
    selectAllItems() {
      this.clearSelectedItems()
      for (let i = 0; i < this.users.length; i++) {
        this.selectedUserIds.push(this.users[i].user_id)
      }
    },
    clearSelectedItems() {
      this.selectedUserIds = []
    },
    getSelectedItem(userId) {
      const index = this.users.findIndex(e => e.user_id === userId)
      if (index > -1) {
        return this.users[index]
      }
      return null
    },
    toggleSelectAll() {
      if (this.newUsers.length > 0) {
        this.newUsers = []
      } else {
        this.newUsers = this.options
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
#user-group-users-page {
  .tab-content {
    background-color: #FFF;

    table {
      thead {
        th:first-child, th:nth-child(3) {
          width: 120px;
        }
      }

      td {
        position: relative;

        span {
          display: inline-block;
          width: 14px;

          &.unread::before {
            top: 23px;
            left: 20px;
          }
        }
      }
    }
  }

  .action {
    .show {
      visibility: visible;
      opacity: 1;
    }

    .dropdown-menu {
      margin-top: 10px;
      left: -100px;

      button {
        width: 100%;
      }
    }
  }
}
</style>
