<template>
  <div id="announcements-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      報告板
    </h2>

    <div v-if="checkPermission(permissions, 'site.announcement.view')">
      <ul class="nav nav-tabs mt-5 announcements-nav" role="tablist">
        <li v-for="(item, key) in announcementTypes" :key="key" :id="`announcement-${key}-tab`" class="nav-item flex-1"
            role="presentation">
          <button class="nav-link w-full py-2" :class="{'active': currentType === item.type_id}" type="button" @click="changeType(item)"> {{ item.name }}
          </button>
        </li>
      </ul>

      <div class="tab-content border-l border-r border-b mb-5">
        <div class="tab-pane leading-relaxed p-5 active">

          <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap justify-between items-center mt-2">
            <router-link v-if="checkPermission(permissions, 'site.announcement.create')" :to="`/create-announcement?type=${currentType}`" class="btn btn-primary shadow-md mr-2">新增消息</router-link>
            <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0 flex flex-col sm:flex-row">
              <div class="flex w-full mb-2 sm:mb-0 sm:mr-2">
                <select v-if="checkPermission(permissions, 'site.announcement.edit')" id="status" v-model="currentStatus" class="form-select mr-2" aria-label="請選擇狀態">
                  <option v-for="(item, key) in allStatus" :key="key" :value="item.value">{{ item.name }}</option>
                </select>

                <select v-if="checkPermission(permissions, 'site.announcement.edit')" id="status" v-model="archived" class="form-select" aria-label="請選擇">
                  <option :value="false">現時</option>
                  <option :value="true">封存</option>
                </select>
              </div>

              <div class="relative text-slate-500">
                <input v-model="search" type="text" class="form-control sm:w-56 pr-10" placeholder="搜尋">
                <font-awesome-icon icon="fa-solid fa-magnifying-glass" class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0" />
              </div>
            </div>
          </div>

          <div class="overflow-x-auto mt-5">
            <table class="table table-striped">
              <thead>
              <tr>
                <th class="whitespace-nowrap flex justify-center"><order-button title="ID" column="announcement_id" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="whitespace-nowrap"><order-button title="新增時間" column="created_at" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="whitespace-nowrap"><order-button title="標題" column="title" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="whitespace-nowrap"><order-button title="公開時間" column="display_at" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="whitespace-nowrap"><order-button title="結束時間" column="end_at" :order-by.sync="orderBy" :order.sync="order"></order-button></th>
                <th class="text-center whitespace-nowrap">檢視</th>
                <th v-if="checkPermission(permissions, 'site.announcement.edit') || checkPermission(permissions, 'site.announcement.delete')" class="text-center whitespace-nowrap">動作</th>
              </tr>
              </thead>
              <tbody>
              <tr class="" v-for="(item, key) in announcements" :key="key">
                <td class="text-center"><span :class="{'unread': !item.is_read}"></span>{{ item.announcement_id }}</td>
                <td>{{ $moment(item.post_date).format('YYYY-MM-DD') }}</td>
                <td><font-awesome-icon icon="fa-solid fa-thumbtack" class="mr-1" v-if="item.is_pinned"/> {{ item.title }}</td>
                <td>{{ item.display_at != null ? $moment(item.display_at).format('YYYY-MM-DD HH:mm:ss') : '-' }}</td>
                <td>{{ item.end_at != null ? $moment(item.end_at).format('YYYY-MM-DD HH:mm:ss') : '-' }}</td>
                <td class="table-report__action text-center">
                  <router-link :to="`/announcements/${item.announcement_id}`" class="btn btn-success">
                    <font-awesome-icon icon="fa-regular fa-eye" class="text-white" />
                  </router-link>
                </td>
                <td v-if="checkPermission(permissions, 'site.announcement.edit') || checkPermission(permissions, 'site.announcement.delete')" class="table-report__action w-56">
                  <div class="flex justify-center items-center">
                    <router-link v-if="checkPermission(permissions, 'site.announcement.edit')" :to="`/update-announcement/${item.announcement_id}`" class="flex items-center mr-3">
                      <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                      &nbsp;更改 </router-link>
                    <button v-if="checkPermission(permissions, 'site.announcement.delete')" class="flex items-center text-danger" @click="openDeleteModal(item.announcement_id)">
                      <font-awesome-icon icon="fa-regular fa-trash-can" />
                      &nbsp;刪除 </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>

            <div class="hidden md:block mx-auto text-slate-500 text-center mt-5">正在顯示第 {{ announcements.length !== 0 ? from : 0 }} 至 {{ to ?? 0 }} 個消息</div>
          </div>
        </div>
      </div>

      <!-- BEGIN: Pagination -->
      <pagination-row :pages="pages" :current-page.sync="currentPage" :item-per-page.sync="itemPerPage"></pagination-row>
      <!-- END: Pagination -->

      <!-- BEGIN: Delete Confirmation Modal -->
      <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
                <div class="text-3xl mt-5">你確定嗎？</div>
                <div class="text-slate-500 mt-2">
                  你是否要刪除此消息？
                  <br>
                  這動作不能還原。
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,
      announcementTypes: [],
      currentType: null,
      currentStatus: 'PUBLISHED',
      archived: false,

      announcements: [],

      itemPerPage: 10,
      currentPage: 1,
      pages: 0,
      from: 0,
      to: 0,
      search: '',
      orderBy: '',
      order: '',

      allStatus: [
        { name: '全部', value: 'ALL' },
        { name: '公開', value: 'PUBLISHED' },
        { name: '草稿', value: 'DRAFT' }
      ],

      deleteId: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    },
    currentType(val) {
      this.loadAnnouncements()
    },
    currentPage(val) {
      this.loadAnnouncements(false)
    },
    currentStatus(val) {
      this.loadAnnouncements()
    },
    archived(val) {
      this.loadAnnouncements()
    },
    itemPerPage(val) {
      this.loadAnnouncements()
    },
    order() {
      this.loadAnnouncements()
    },
    search(val) {
      this.loadAnnouncements()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadAnnouncementTypes()
        this.mounted = true
      }
    },
    async loadAnnouncementTypes() {
      this.announcementTypes = await this.getSiteAnnouncementTypes(this.currentSite.site_id)
      if (this.announcementTypes.length > 0) {
        if (this.$route.query.type != null && this.$route.query.type !== '') {
          this.currentType = parseInt(this.$route.query.type)
        } else {
          this.currentType = this.announcementTypes[0].type_id
        }

        await this.loadAnnouncements()
      }
    },
    async loadAnnouncements(resetCurrentPage = true) {
      const response = await this.getSiteAnnouncements(this.currentSite.site_id, this.search, this.currentType, this.itemPerPage, this.currentPage, this.currentStatus, this.archived, this.orderBy, this.order)
      this.announcements = response.data
      this.pages = response.meta.page.last_page
      this.from = response.meta.page.from
      this.to = response.meta.page.to

      if (resetCurrentPage) {
        this.currentPage = 1
      }
    },
    changeType(item) {
      if (this.currentType !== item.type_id) {
        this.currentType = item.type_id
        this.$router.replace({ path: '/announcements', query: { type: item.type_id } })
      }
    },
    openDeleteModal(id) {
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteSiteAnnouncement(this.currentSite.site_id, this.deleteId)

      let option = {
        title: '成功',
        message: '消息已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        this.loadAnnouncements()
      } else {
        option = {
          title: '未能刪除消息',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped>
#announcements-page {
  .tab-content {
    background-color: #FFF;

    table {
      td {
        position: relative;

        span {
          display: inline-block;
          width: 14px;

          &.unread::before {
            top: 23px;
            left: 20px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .announcements-nav {
    display: flex;
    flex-wrap: wrap;

    .nav-item {
      flex: 1 0 33%;
    }
  }
}
@media screen and (max-width: 767px) {
  .announcements-nav {
    .nav-item {
      flex: 1 0 50%;
    }
  }
}
</style>
