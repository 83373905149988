<template>
  <div id="library-item-page">
    <!-- BEGIN: View Modal -->
    <div id="view-modal" class="modal" tabindex="-1" aria-hidden="true" @click.self="onBack">
      <div class="flex justify-between align-items-center view-toolbar">
        <button type="button" @click="onDownload(item)" class="btn-download text-white">
          <font-awesome-icon icon="fa-solid fa-cloud-arrow-down" class="fa-2x"/>
        </button>

        <div class="text-white">{{ item != null ? item.file.file_name : ''}}</div>

        <button type="button" data-tw-dismiss="modal" class="btn-close text-white" @click="onBack">
          <font-awesome-icon icon="fa-solid fa-xmark" class="fa-2x"/>
        </button>
      </div>

      <div class="modal-dialog flex justify-center">
        <img v-if="item != null && item.file.format.mime_type.startsWith('image/')" :src="item.temp_access_url">
      </div>

      <div v-if="item != null && !item.file.format.mime_type.startsWith('image/')">
        <embed v-if="item.file.format.mime_type === 'application/pdf'" :src="item.temp_access_url" class="iframe-viewer"/>
        <iframe
          v-else-if="isViewable(item)"
          :src="`https://view.officeapps.live.com/op/embed.aspx?src=${item.temp_access_url}`" frameborder="0"
          class="iframe-viewer"></iframe>
      </div>

    </div>
    <!-- END: View Modal -->
  </div>
</template>

<script>
export default {
  props: ['auth'],
  data() {
    return {
      mounted: false,

      downloading: false,

      item: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    '$route'(val) {
      this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      this.item = null

      await this.getItem()

      if (!this.mounted) {
        window.tailwind.svgLoader()
      }

      this.mounted = true
    },
    async getItem() {
      if (this.folderCode !== '') {
        const response = await this.getItemByShareCode(this.$route.params.shareCode)

        if (response.code === 0) {
          this.item = response.data
          this.openViewModal()
        } else {
          this.$router.push('/library')
        }
      }
    },
    async openViewModal() {
      if (this.isViewable(this.item)) {
        const el = document.querySelector('#view-modal')
        const modal = window.tailwind.Modal.getOrCreateInstance(el)
        modal.show()
      } else {
        this.onDownload(this.item)
      }
    },
    async onDownload(item, filename = null) {
      this.downloading = true
      const response = await this.downloadItemByShareCode(this.$route.params.shareCode)
      const blob = new Blob([response], { type: item.file.format.mime_type })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename ?? item.file.file_name
      link.click()
      URL.revokeObjectURL(link.href)
      this.downloading = false
    },
    onBack() {
      this.$router.push('/library')
    }
  }
}
</script>

<style lang="scss" scoped>
#library-item-page {
}
</style>
