<template>
  <div id="resources-page">
    <h2 v-if="currentSite != null" class="intro-y text-lg font-medium mt-10">
      {{ currentSite.name }}的會議室及資源項目 (共{{ total }}個)
    </h2>

    <div v-if="checkPermission(permissions, 'site.resource.view')">

      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
          <router-link to="/management/create-resource" class="btn btn-primary shadow-md mr-2" v-if="checkPermission(permissions, 'site.resource.create')">新增項目</router-link>
          <div class="hidden md:block mx-auto text-slate-500">正在顯示第 {{ resources.length !== 0 ? from : 0 }} 至 {{ to ?? 0 }} 個項目</div>
          <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0 flex">

            <select id="status" v-model="type" class="form-select mr-2" aria-label="請選擇狀態">
              <option v-for="(item, key) in types" :key="key" :value="item.value">{{ item.key }}</option>
            </select>

            <div class="w-56 relative text-slate-500">
              <input v-model="search" type="text" class="form-control w-56 box pr-10" placeholder="搜尋">
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0" />
            </div>
          </div>
        </div>
        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-report -mt-2">
            <thead>
            <tr>
              <th class="whitespace-nowrap">項目名稱</th>
              <th class="text-center whitespace-nowrap">可借用</th>
              <th v-if="checkPermission(permissions, 'site.resource.edit') || checkPermission(permissions, 'site.resource.delete')" class="text-center whitespace-nowrap">動作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, key) in resources" :key="key" class="intro-x">
              <td>
                <div class="font-medium whitespace-nowrap">{{ item.name }}</div>
                <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ item.description }}</div>
              </td>
              <td class="w-40">
                <div v-if="item.is_available" class="flex items-center justify-center text-success">
                  <font-awesome-icon icon="fa-regular fa-circle-check" class="fa-2x" />
                </div>
                <div v-else class="flex items-center justify-center text-danger">
                  <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-2x" />
                </div>
              </td>
              <td class="table-report__action w-56" v-if="checkPermission(permissions, 'site.resource.edit') || checkPermission(permissions, 'site.resource.delete')">
                <div class="flex justify-center items-center">
                  <router-link :to="`/management/update-resource/${item.resource_id}`" class="flex items-center mr-3">
                    <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                    &nbsp;更改 </router-link>
                  <button class="flex items-center text-danger" @click="openDeleteModal(item.resource_id)">
                    <font-awesome-icon icon="fa-regular fa-trash-can" />
                    &nbsp;刪除 </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
        <!-- BEGIN: Pagination -->
          <pagination-row :pages="pages" :current-page.sync="currentPage" :item-per-page.sync="itemPerPage"></pagination-row>
        <!-- END: Pagination -->
      </div>

      <!-- BEGIN: Delete Confirmation Modal -->
      <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
                <div class="text-3xl mt-5">你確定嗎？</div>
                <div class="text-slate-500 mt-2">
                  你是否要刪除此項目？
                  <br>
                  這動作不能還原。
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->

    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,

      resources: [],

      type: 'resource_item',
      itemPerPage: 10,
      currentPage: 1,
      pages: 0,
      from: 0,
      to: 0,
      total: 0,
      search: '',

      deleteId: null,

      types: [
        {
          value: 'resource_item',
          key: '物品'
        },
        {
          value: 'room',
          key: '會議室'
        }
      ]
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    },
    type(val) {
      this.loadResources()
    },
    currentPage(val) {
      this.loadResources(false)
    },
    currentStatus(val) {
      this.loadResources()
    },
    itemPerPage(val) {
      this.loadResources()
    },
    search(val) {
      this.loadResources()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadResources()
        this.mounted = true
      }
    },
    async loadResources(resetCurrentPage = true) {
      const response = await this.getSiteResources(this.currentSite.site_id, this.type, this.search, this.itemPerPage, this.currentPage)
      this.resources = response.data
      this.pages = response.meta.page.last_page
      this.from = response.meta.page.from
      this.to = response.meta.page.to
      this.total = response.meta.page.total

      if (resetCurrentPage) {
        this.currentPage = 1
      }
    },
    openDeleteModal(id) {
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteSiteResource(this.currentSite.site_id, this.deleteId)

      let option = {
        title: '成功',
        message: '項目已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        await this.loadResources()
      } else {
        option = {
          title: '未能刪除項目',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped></style>
