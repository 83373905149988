import { render, staticRenderFns } from "./CreateForumCategoryPage.vue?vue&type=template&id=a620d3c0&scoped=true&"
import script from "./CreateForumCategoryPage.vue?vue&type=script&lang=js&"
export * from "./CreateForumCategoryPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a620d3c0",
  null
  
)

export default component.exports