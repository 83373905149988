<template>
  <div id="announcement-page">

    <div class="flex justify-end mt-8">
      <router-link to="/faq" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
    </div>

    <div v-if="checkPermission(permissions, 'site.knowledge_base.view')">
      <div v-if="data != null" class="intro-y news xl:w-3/5 p-5 box">
        <!-- BEGIN: Blog Layout -->
        <h2 class="intro-y font-medium text-xl sm:text-2xl">
          {{ data.title }}
        </h2>
        <div class="intro-y text-slate-600 dark:text-slate-500 mt-3 text-xs sm:text-sm"> {{ $moment(data.post_date).format('YYYY-MM-DD') }} <span
          class="mx-1">•</span> <span class="text-primary"> {{ getObjectValue(knowledgeTypes, 'type_id', data.type_id, 'name') }}</span> <span class="mx-1">•</span> 最後更新 {{ $moment(data.updated_at).format('YYYY-MM-DD') }}
        </div>
        <div class="intro-y text-justify html-content mt-6" v-html="data.content">
        </div>
        <!-- END: Blog Layout -->
      </div>
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,
      knowledgeTypes: [],
      data: null,
      user: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    async currentSite(val) {
      await this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadKnowledgeTypes()
        await this.loadData()

        this.mounted = true
      }
    },
    async loadKnowledgeTypes() {
      this.knowledgeTypes = await this.getSiteFaqTypes(this.currentSite.site_id)
    },
    async loadData() {
      this.data = await this.getSiteFaqBase(this.currentSite.site_id, this.$route.params.knowledgeId)
    }
  }
}
</script>

<style lang="scss" scoped>
#announcement-page {
}
</style>
