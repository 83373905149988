<template>
  <div>
    <div class="el-tooltip el-tiptap-editor__command-button" aria-describedby="el-tooltip-1942" tabindex="0"
         @click="loadYoutubeInsertor" data-tooltip="插入Youtube影片">
      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512">
        <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
        <path
          d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"/>
      </svg>
    </div>

    <youtube-insertor @on-confirm="onConfirm" ref="yInsetor"></youtube-insertor>
  </div>
</template>

<script>
export default {
  name: 'InsertYoutubeButton',

  props: ['isActive'],

  methods: {
    loadYoutubeInsertor() {
      this.$refs.yInsetor.onShow()
    },
    onConfirm(link) {
      this.$emit('insert', link)
    }
  }
}
</script>
