import { Link } from 'element-tiptap'
import InsertFileButton from './InsertFile'

export default class Bold extends Link {
  // here you can extend the extension like tiptap, such as schema, plugins ...

  constructor(auth, currentSite, permissions) {
    super()
    this.auth = auth
    this.currentSite = currentSite
    this.permissions = permissions
  }

  menuBtnView(editorContext) {
    return {
      component: InsertFileButton,
      componentProps: {
        auth: this.auth,
        currentSite: this.currentSite,
        permissions: this.permissions
      },
      componentEvents: {
        insertFile: (item) => {
          editorContext.commands.link({ href: `/file/${item.item_code}`, target: '_blank' })
        },
        insertImage: (item) => {
          editorContext.commands.image({ src: item.public_url })
        }
      }
    }
  }
}
