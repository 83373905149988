import { render, staticRenderFns } from "./CreateResourceBookingPage.vue?vue&type=template&id=3b4f3532&scoped=true&"
import script from "./CreateResourceBookingPage.vue?vue&type=script&lang=js&"
export * from "./CreateResourceBookingPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b4f3532",
  null
  
)

export default component.exports