<template>
  <div id="file-page" class="flex justify-center align-items-center">
    <img v-if="viewItem != null && viewItem.file.format.mime_type.startsWith('image/')" :src="viewItem.view_url">

    <div v-if="viewItem != null && !viewItem.file.format.mime_type.startsWith('image/')">
      <embed v-if="viewItem.file.format.mime_type === 'application/pdf'" :src="viewItem.view_url" class="iframe-viewer"/>
      <iframe
        v-else-if="isViewable(viewItem)"
        :src="`https://view.officeapps.live.com/op/embed.aspx?src=${viewItem.view_url}`" frameborder="0" class="iframe-viewer"></iframe>
    </div>
  </div>
</template>

<script>

export default {
  props: ['auth', 'currentSite'],
  data() {
    return {
      viewItem: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        const itemCode = this.$route.params.itemCode
        this.loadFile(itemCode)
      }
    },
    async loadFile(itemCode) {
      const response = await this.getLibraryItem(this.currentSite.site_id, itemCode)

      if (response.code === 0) {
        this.viewItem = response.data

        if (!this.isViewable(this.viewItem)) {
          this.onDownload(this.viewItem)
        }
      }
    },
    async onDownload(item, filename = null, versionId = '') {
      const response = await this.downloadLibraryItem(this.currentSite.site_id, item.item_code, versionId)
      const blob = new Blob([response], { type: item.file.format.mime_type })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = filename ?? item.file.file_name
      link.click()
      URL.revokeObjectURL(link.href)
    }
  }
}
</script>

<style lang="scss" scoped>
#file-page {
  width: 100vw;
  height: 100vh;
  background-color: #000;

  img {
    text-align: center;
  }

  > div {
    flex: 1;
    height: 100%;

    .iframe-viewer {
      width: 100%;
      height: 100%;
    }
  }

}
</style>
