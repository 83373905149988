<template>
  <div id="announcement-page">

    <div class="flex justify-end mt-8">
      <router-link to="/announcements" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
    </div>

    <div v-if="checkPermission(permissions, 'site.announcement.view')">
      <div v-if="data != null" class="intro-y news xl:w-3/5 p-5 box">
        <!-- BEGIN: Blog Layout -->
        <h2 class="intro-y font-medium text-xl sm:text-2xl">
          {{ data.title }}
        </h2>
        <div class="intro-y text-slate-600 dark:text-slate-500 mt-3 text-xs sm:text-sm"> {{ $moment(data.post_date).format('YYYY-MM-DD') }} <span
          class="mx-1">•</span> <span class="text-primary"> {{ getObjectValue(announcementTypes, 'type_id', data.type_id, 'name') }}</span> <span class="mx-1">•</span> 已讀 <span class="mx-1">•</span> 最後更新 {{ $moment(data.updated_at).format('YYYY-MM-DD') }}
        </div>
        <div class="intro-y text-justify html-content mt-6" v-html="data.content">
        </div>
        <div
          v-if="user != null"
          class="intro-y flex text-xs sm:text-sm flex-col sm:flex-row items-center mt-5 pt-5 border-t border-slate-200/60 dark:border-darkmode-400">
          <div class="flex items-center">
            <div class="w-12 h-12 flex-none image-fit">
              <img alt="" class="rounded-full" :src="user.avatar != null && user.avatar !== '' ? user.avatar : '/images/user.png'">
            </div>
            <div class="ml-3 mr-auto">
              <div class="font-medium">{{ user.display_name }}</div>
              <div class="text-slate-500">{{ user.username }}</div>
            </div>
          </div>
        </div>
        <!-- END: Blog Layout -->
      </div>
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,
      announcementTypes: [],
      data: null,
      user: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    async currentSite(val) {
      await this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadAnnouncementTypes()
        await this.loadData()

        this.mounted = true
      }
    },
    async loadAnnouncementTypes() {
      this.announcementTypes = await this.getSiteAnnouncementTypes(this.currentSite.site_id)
    },
    async loadData() {
      this.data = await this.getSiteAnnouncement(this.currentSite.site_id, this.$route.params.announcementId)
      await this.loadUser()
    },
    async loadUser() {
      this.user = await this.getUser(this.data.user_id)
    }
  }
}
</script>

<style lang="scss" scoped>
#announcement-page {
}
</style>
