const mixin = {
  data() {
    return {
      tinyMCEApiKey: 'g407hat6lid3zf81fdep3tg95su6oo0n723f3ek3vzojkj15',
      siteTypes: [
        { name: '中心', value: 'CENTER' },
        { name: '區域', value: 'DISTRICT' },
        { name: '社會服務大樓', value: 'BUILDING' },
        { name: '服務', value: 'PROJECT' },
        { name: '工作小組 ', value: 'GROUP' }
      ],
      titles: [
        {
          key: '博士',
          value: 'Dr'
        },
        {
          key: '先生',
          value: 'Mr'
        },
        {
          key: '太太',
          value: 'Mrs'
        },
        {
          key: '女士',
          value: 'Ms'
        },
        {
          key: '小姐',
          value: 'Miss'
        }
      ],
      posts: [
        {
          key: '主任',
          value: 'Supervisor'
        },
        {
          key: '計劃主任',
          value: 'Project Officer'
        },
        {
          key: '計劃經理',
          value: 'Project Manager'
        },
        {
          key: '家長',
          value: 'House Parent'
        },
        {
          key: '服務經理(圓滿人生服務)',
          value: 'Service Manager (Endless Care Services)'
        },
        {
          key: '項目主任(護老者服務)',
          value: 'Project Supervisor (Carers Services)'
        },
        {
          key: '校長',
          value: 'Principal'
        },
        {
          key: '區域主任(港島東)',
          value: 'District Coordinator (Hong Kong East)'
        },
        {
          key: '區域主任(九龍東)',
          value: 'District Coordinator (Kowloon East)'
        },
        {
          key: '區域主任(九龍西)',
          value: 'District Coordinator (Kowloon West)'
        },
        {
          key: '區域主任(新界東)',
          value: 'District Coordinator (New Territories East)'
        },
        {
          key: '區域主任(新界北)',
          value: 'District Coordinator (New Territories North)'
        },
        {
          key: '區域主任(新界西)',
          value: 'District Coordinator (New Territories West)'
        },
        {
          key: '區域主任(港島南)',
          value: 'District Coordinator (Southern)'
        }
      ],
      weekDays: [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六'
      ],
      resourceTypes: [
        {
          value: 'resource_item',
          key: '物品'
        },
        {
          value: 'room',
          key: '會議室'
        }
      ],
      applicationTypes: [
        {
          key: '新增',
          value: 'ADD',
          existUser: false
        },
        {
          key: '更新',
          value: 'UPDATE',
          existUser: true
        },
        {
          key: '刪除',
          value: 'DELETE',
          existUser: true
        }
      ]
    }
  },
  methods: {
    showErrors(messages) {
      let html = ''

      messages.forEach((message) => {
        html += message + '<br/ >'
      })

      return html
    },
    getObjectValue(array, searchKey, searchValue, returnValue = null) {
      // eslint-disable-next-line eqeqeq
      const item = array.find(o => o[searchKey] == searchValue)

      if (returnValue == null) {
        return item
      } else if (item == null) {
        return searchValue
      }

      return item[returnValue]
    },
    trim(str, ch) {
      let start = 0
      let end = str.length

      while (start < end && str[start] === ch) { ++start }

      while (end > start && str[end - 1] === ch) { --end }

      return (start > 0 || end < str.length) ? str.substring(start, end) : str
    },
    checkPermission(permissions = [], permission) {
      if (Array.isArray(permission)) {
        return permissions.find(element => permission.includes(element.code))
      } else {
        return permissions.find(element => element.code === permission)
      }
    },
    isDateDifferent(time1, time2) {
      const date1 = this.$moment(time1).format('YYYY-MM-DD')
      const date2 = this.$moment(time2).format('YYYY-MM-DD')
      return this.$moment(date1).diff(date2, 'days') > 0
    },
    linkify(inputText) {
      let replacedText

      // URLs starting with http://, https://, or ftp://
      const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim
      replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>')

      // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
      const replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim
      replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>')

      // Change email addresses to mailto:: links.
      const replacePattern3 = /(([a-zA-Z0-9\-_.])+@[a-zA-Z_]+?(\.[a-zA-Z]{2,6})+)/gim
      replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>')

      return replacedText
    },
    getExtension(item, field = null) {
      if (field == null) {
        return item.file.file_name.split('.').pop().toUpperCase()
      } else {
        return item[field].split('.').pop().toUpperCase()
      }
    },
    isViewable(item) {
      if (item.file == null || !item.file.format) {
        return false
      }

      if (item.file.format.mime_type.startsWith('image/')) {
        return true
      }

      switch (item.file.format.mime_type) {
        case 'application/pdf':
        case 'application/msword':
        case 'application/vnd.ms-excel':
        case 'application/vnd.ms-powerpoint':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
          return true
      }

      return false
    },
    isLetterOrNumber(e) {
      const char = String.fromCharCode(e.keyCode)
      if (/^[A-Za-z0-9]+$/.test(char)) return true
      else e.preventDefault()
    },
    isSlashOrNumber(e) {
      const char = String.fromCharCode(e.keyCode)
      if (/^[/0-9]+$/.test(char)) return true
      else e.preventDefault()
    },
    split(str, index) {
      return [str.slice(0, index), str.slice(index)]
    },
    checkTextEmpty(text) {
      if (text == null || text === '') {
        return ''
      } else {
        return text
      }
    }
  }
}

export default mixin
