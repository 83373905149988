<template>
  <div id="update-data-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      更改巡禮
    </h2>

    <social-essay-form v-if="checkPermission(permissions, 'site.announcement.edit')" :data="data" :site="currentSite" :auth="auth" :current-site="currentSite" :permissions="permissions"></social-essay-form>
    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,
      data: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadData()
        this.mounted = true
      }
    },
    async loadData() {
      this.data = await this.getSiteEssay(this.currentSite.site_id, this.$route.params.essayId)
    }
  }
}
</script>

<style lang="scss" scoped></style>
