<template>
  <div id="pagination-row" class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center">
    <nav class="w-full sm:w-auto sm:mr-auto">
      <ul class="pagination">
        <li v-if="nowPage - 2 > 0" class="page-item">
          <button class="page-link" @click="nowPage = 1">
            <font-awesome-icon icon="fa-solid fa-angles-left" />
          </button>
        </li>
        <li v-if="nowPage - 1 > 0" class="page-item">
          <button class="page-link" @click="nowPage = Math.max(nowPage - 1, 1)">
            <font-awesome-icon icon="fa-solid fa-angle-left" />
          </button>
        </li>
        <li v-if="nowPage - 2 > 0" class="page-item"><span class="page-link">...</span></li>
        <li v-if="nowPage - 1 > 0" class="page-item"><button class="page-link" @click="nowPage -= 1">{{ nowPage - 1 }}</button></li>
        <li class="page-item active"><button class="page-link">{{ nowPage }}</button></li>
        <li v-if="nowPage + 1 <= pages" class="page-item"><button class="page-link" @click="nowPage += 1">{{ nowPage + 1 }}</button></li>
        <li v-if="nowPage + 2 <= pages" class="page-item"><span class="page-link">...</span></li>
        <li v-if="nowPage + 1 <= pages" class="page-item">
          <button class="page-link" @click="nowPage = Math.min(nowPage + 1, pages)">
            <font-awesome-icon icon="fa-solid fa-angle-right" />
          </button>
        </li>
        <li v-if="nowPage + 2 <= pages" class="page-item">
          <button class="page-link" @click="nowPage = pages">
            <font-awesome-icon icon="fa-solid fa-angles-right" />
          </button>
        </li>
      </ul>
    </nav>
    <select v-model="itemsNo" class="w-20 form-select box mt-3 sm:mt-0">
      <option value="10">10</option>
      <option value="25">25</option>
      <option value="35">35</option>
      <option value="50">50</option>
    </select>
  </div>
</template>

<script>
export default {
  props: ['pages', 'currentPage', 'itemPerPage'],
  computed: {
    nowPage: {
      get() {
        return this.currentPage
      },
      set(val) {
        this.$emit('update:currentPage', val)
      }
    },
    itemsNo: {
      get() {
        return this.itemPerPage
      },
      set(val) {
        this.$emit('update:itemPerPage', val)
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
