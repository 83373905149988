<template>
  <div id="submit-form-form" >
    <div v-if="data && data.sections.length > 0" class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5" v-if="form.responses.length > 0 || Object.keys(form).length > 1">

          <div v-for="(section, i) in data.sections" :key="i">
            <div class="intro-y col-span-11 alert alert-primary alert-dismissible show flex items-center mb-6" :class="{'mt-8': i > 0}">
              <span>{{ section.section_name }}</span>
            </div>

            <div v-for="(item, key) in section.items" :key="key" :class="{'mt-4 border-t pt-4': key > 0}">
              <label class="form-label font-medium text-primary"><span v-if="item.is_required" class="text-danger">*</span> {{ item.title }}</label>

              <input v-if="item.form_item_type === 'text'" v-model="form.responses[getResponseIndex(i, key)].content" type="text" class="form-control w-full" :placeholder="`請輸入答案`">

              <textarea v-else-if="item.form_item_type === 'long-text'" v-model="form.responses[getResponseIndex(i, key)].content" class="form-control w-full" :placeholder="`請輸入答案`" rows="5"></textarea>

              <div v-else-if="item.form_item_type === 'radio'">
                <div v-for="(option, j) in item.options" :key="j" class="form-check mt-2">
                  <input :id="`item-${item.form_item_id}-option-${option.option_id}`" class="form-check-input" type="radio" :name="`item-${item.form_item_id}`" :value="option.option_id" v-model="form.responses[getResponseIndex(i, key)].option_id">
                  <label class="form-check-label" :for="`item-${item.form_item_id}-option-${option.option_id}`">{{ option.title }}</label>
                </div>
                <input v-if="checkIsCustom(item.options, getResponseIndex(i, key))" v-model="form.responses[getResponseIndex(i, key)].content" type="text" class="form-control w-full mt-2" :placeholder="`請輸入答案`">
              </div>

              <div v-else-if="item.form_item_type === 'checkbox'">
                <div v-for="(option, j) in item.options" :key="j" class="form-check mt-2">
                  <div>
                    <input :id="`item-${item.form_item_id}-option-${option.option_id}`" class="form-check-input" type="checkbox" :value="option.option_id" v-model="form.responses[getResponseIndex(i, key)].option_id[j]">
                    <label class="form-check-label" :for="`item-${item.form_item_id}-option-${option.option_id}`">{{ option.title }}</label>
                  </div>
                  <div v-if="option.is_custom && form.responses[getResponseIndex(i, key)].option_id[j]">
                    <input v-model="form.responses[getResponseIndex(i, key)].content[j]" type="text" class="form-control w-full ml-2" :placeholder="`請輸入答案`">
                  </div>
                </div>
              </div>

              <div v-else-if="item.form_item_type === 'dropdown'">
                <select v-model="form.responses[getResponseIndex(i, key)].option_id" class="form-select" aria-label="請選擇">
                  <option value="">--- 請選擇 ---</option>
                  <option v-for="(option, i) in item.options" :key="i" :value="option.option_id">{{ option.title }}</option>
                </select>

                <input v-if="checkIsCustom(item.options, getResponseIndex(i, key))" v-model="form.responses[getResponseIndex(i, key)].content" type="text" class="form-control w-full" :placeholder="`請輸入答案`">
              </div>

              <div v-else-if="item.form_item_type === 'file'">
                <div v-if="form[item.form_item_id]" class="mb-2">{{ form[item.form_item_id].name }}</div>

                <div class="flex">
                  <input :id="`file-${item.form_item_id}`" @change="onSelectFile($event, item.form_item_id)" type="file" hidden>
                  <button type="button" class="btn btn-outline-secondary" @click="openFileSelect(item.form_item_id)">{{ form[item.form_item_id] ? '變更檔案' : '選擇檔案' }}</button>
                  <button v-if="form[item.form_item_id] != null" type="button" class="btn btn-outline-danger ml-2" @click="onRemoveFile(item.form_item_id)">取消選擇</button>
                </div>
              </div>

              <div v-else-if="item.form_item_type === 'date'">
                <date-picker v-model="form.responses[getResponseIndex(i, key)].content" type="date" placeholder="請選擇日期" input-class="form-control w-full" value-type="YYYY-MM-DD"></date-picker>
              </div>

              <div v-else-if="item.form_item_type === 'centre'">
                <select v-model="form.responses[getResponseIndex(i, key)].option_id" class="form-select" aria-label="請選擇">
                  <option value="">--- 請選擇 ---</option>
                  <option v-for="(option, i) in centers" :key="i" :value="option.centre_id">{{ option.name_zh
                    }}</option>
                </select>
              </div>

              <div v-else-if="item.form_item_type === 'post'">
                <select v-model="form.responses[getResponseIndex(i, key)].option_id" class="form-select" aria-label="請選擇">
                  <option value="">--- 請選擇 ---</option>
                  <option v-for="(option, i) in positions" :key="i" :value="option.position_id">{{ option.name
                    }}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="flex justify-end align-items-center mt-5">
            <router-link v-if="!loading && !finish" :to="mode === 'create' ? '/forms' : `/forms/${this.response.form_id}/response`" type="button" class="btn btn-outline-secondary w-24 mr-1">取消</router-link>
            <button v-if="!loading && !finish" type="button" class="btn btn-primary w-24" @click="onSave">提交</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>

    <div v-else>
      <div class="alert alert-warning show mt-2" role="alert">此問巻/電子表格未有設置題目</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['currentSite', 'response', 'data'],
  data() {
    return {
      mode: 'create',

      centers: [],
      positions: [],

      form: {
        responses: []
      },
      errors: {},
      loading: false,
      finish: false
    }
  },
  watch: {
    currentSite(val) {
      if (val) {
        this.init()
      }
    },
    data(val) {
      if (val) {
        this.init()
      }
    },
    response(val) {
      if (val != null) {
        // this.form = this.response
        this.mode = 'update'
      }

      this.init()
    }
  },
  mounted() {
    window.tailwind.svgLoader()

    this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadCenters()
        await this.loadPositions()
      }

      if (this.mode === 'create' && this.data) {
        await this.loadData()
      } else if (this.mode === 'update' && this.response && this.data) {
        await this.loadData()
      }
    },
    async loadCenters() {
      this.centers = await this.getCenters()
    },
    async loadPositions() {
      this.positions = await this.getPositions()
    },
    async loadData() {
      const responses = []
      for (let i = 0; i < this.data.sections.length; i++) {
        for (let j = 0; j < this.data.sections[i].items.length; j++) {
          const ids = []
          const optionIds = []
          const contents = []

          switch (this.data.sections[i].items[j].form_item_type) {
            case 'checkbox':
              // Get Response option_id
              // eslint-disable-next-line no-case-declarations
              const currentOptionIds = []

              if (this.response != null) {
                for (let k = 0; k < this.response.response[i].items[j].responses.length; k++) {
                  currentOptionIds.push(this.response.response[i].items[j].responses[k].option_id)
                }
              }

              for (let k = 0; k < this.data.sections[i].items[j].options.length; k++) {
                ids.push(this.data.sections[i].items[j].options[k].option_id)
                optionIds.push(currentOptionIds.includes(this.data.sections[i].items[j].options[k].option_id))
                contents.push(null)
              }

              responses.push({
                id: this.response != null && this.response.response[i].items[j].responses.length > 0 ? this.response.response[i].items[j].responses[0].id : null,
                sectionItemId: `${i}-${j}`,
                type: 'checkbox',
                form_item_id: this.data.sections[i].items[j].form_item_id,
                ids,
                option_id: optionIds,
                content: contents
              })
              break
            case 'file':
              this.form[this.data.sections[i].items[j].form_item_id] = null
              break
            case 'text':
            case 'long-text':
              responses.push({
                id: this.response != null && this.response.response[i].items[j].responses.length > 0 ? this.response.response[i].items[j].responses[0].id : null,
                sectionItemId: `${i}-${j}`,
                type: this.data.sections[i].items[j].form_item_type,
                form_item_id: this.data.sections[i].items[j].form_item_id,
                option_id: null,
                content: this.response != null && this.response.response[i].items[j].responses.length > 0 ? this.response.response[i].items[j].responses[0].content : null
              })
              break
            case 'date':
              responses.push({
                id: this.response != null && this.response.response[i].items[j].responses.length > 0 ? this.response.response[i].items[j].responses[0].id : null,
                sectionItemId: `${i}-${j}`,
                type: this.data.sections[i].items[j].form_item_type,
                form_item_id: this.data.sections[i].items[j].form_item_id,
                option_id: null,
                content: this.response != null && this.response.response[i].items[j].responses.length > 0 ? this.response.response[i].items[j].responses[0].content : null
              })
              break
            case 'centre':
            case 'post':
              responses.push({
                id: this.response != null && this.response.response[i].items[j].responses.length > 0 ? this.response.response[i].items[j].responses[0].id : null,
                sectionItemId: `${i}-${j}`,
                type: this.data.sections[i].items[j].form_item_type,
                form_item_id: this.data.sections[i].items[j].form_item_id,
                option_id: this.response != null && this.response.response[i].items[j].responses.length > 0 ? this.response.response[i].items[j].responses[0].option_id : '',
                content: null
              })
              break
            default:
              responses.push({
                id: this.response != null && this.response.response[i].items[j].responses.length > 0 ? this.response.response[i].items[j].responses[0].id : null,
                sectionItemId: `${i}-${j}`,
                type: this.data.sections[i].items[j].form_item_type,
                form_item_id: this.data.sections[i].items[j].form_item_id,
                option_id: this.response != null && this.response.response[i].items[j].responses.length > 0 ? this.response.response[i].items[j].responses[0].option_id : '',
                content: null
              })
          }
        }
      }

      this.form.responses = responses
    },
    checkIsCustom(options, key) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].is_custom && options[i].option_id === this.form.responses[key].option_id) {
          return true
        }
      }

      this.form.responses[key].content = null

      return false
    },
    getResponseIndex(sectionIndex, itemIndex) {
      for (let i = 0; i < this.form.responses.length; i++) {
        if (this.form.responses[i].sectionItemId === `${sectionIndex}-${itemIndex}`) {
          return i
        }
      }

      return 0
    },
    openFileSelect(formItemId) {
      document.getElementById(`file-${formItemId}`).click()
    },
    onSelectFile(event, formItemId) {
      this.$set(this.form, formItemId, event.target.files[0])
      this.$forceUpdate()
    },
    onRemoveFile(formItemId) {
      document.getElementById(`file-${formItemId}`).value = null
      this.form[formItemId] = null
      this.$forceUpdate()
    },
    async onSave() {
      this.loading = true
      this.errors = {}

      const form = JSON.parse(JSON.stringify(this.form))
      const checkboxResponses = []

      for (let i = form.responses.length - 1; i >= 0; i--) {
        // Check if form item is checkbox
        if (form.responses[i].type === 'checkbox') {
          for (let j = 0; j < form.responses[i].option_id.length; j++) {
            // Check if is the checkbox is true
            if (form.responses[i].option_id[j]) {
              checkboxResponses.push({
                form_item_id: form.responses[i].form_item_id,
                option_id: form.responses[i].ids[j],
                content: form.responses[i].content[j]
              })
            }
          }
          form.responses.splice(i, 1)
        } else {
          delete form.responses[i].type
          delete form.responses[i].sectionItemId
        }
      }

      form.responses = form.responses.concat(checkboxResponses)

      const formData = new FormData()

      for (const key in form) {
        if (key === 'responses') {
          formData.append('responses', JSON.stringify(form[key]))
        } else {
          formData.append(`item_${key}`, this.form[key])
        }
      }

      let response

      if (this.mode === 'create') {
        response = await this.submitForm(this.currentSite.site_id, this.$route.params.formId, formData)
      } else {
        response = await this.updateResponse(this.currentSite.site_id, this.$route.params.formId, this.response.response_id, formData)
      }

      if ('errors' in response.data) {
        this.errors = response.data.errors
        this.loading = false
      } else {
        let option = {
          title: this.mode === 'create' ? '已成功提交' : '已成功修改',
          message: this.mode === 'create' ? '請稍後，將會轉至問巻/電子表格頁面' : '請稍後，將會轉至回覆頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          setTimeout(() => {
            this.$router.push(this.mode === 'create' ? '/forms' : `/forms/${this.response.form_id}/response`)
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: this.mode === 'create' ? '未能提交問巻/電子表格' : '未能修改問巻/電子表格',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
