<template>
  <div id="performance-report-tables-page">

    <div class="flex justify-between mt-10">
      <h2 class="intro-y text-lg font-medium">
        {{ section ? section.section_name_zh : '-' }}<br>
        {{ section ? section.section_name_en : '-' }}
      </h2>

      <div v-if="section">
        <router-link type="button" to="/management/performance-reports" class="btn btn-outline-secondary mr-2">返回</router-link>

        <router-link
          v-if="checkPermission(permissions, ['system.performance-report.manage'])"
          type="button" class="btn shadow-md btn-primary" :to="`/management/performance-reports/${section.section_id}/create-table`">{{ '新增表格' }}
        </router-link>
      </div>
    </div>

    <div v-if="checkPermission(permissions, 'system.performance-report.manage') && section">

      <div v-for="(table, tableKey) in section.tables" :key="tableKey" class="tab-content border-l border-r border-b mb-5 mt-5">
        <div class="tab-pane leading-relaxed p-5 active">
          <div class="flex justify-evenly align-items-start mb-4">
            <div class="flex-1">
              <button
                type="button" class="btn shadow-md" @click="onEdit(table.table_id)"
                :class="{'btn-primary': editId !== table.table_id, 'btn-secondary': editId === table.table_id, 'd-none': editId != null && editId !== table.table_id}">
                {{ editId === table.table_id ? '完成修改' : '修改內容' }}
              </button>
            </div>

            <div class="flex-1 text-center mb-2">
              <h3>{{ table.table_name_zh }}</h3>
              <h5>{{ table.table_name_en }}</h5>
            </div>

            <div class="flex-1 flex justify-end items-start">
              <router-link :to="`/management/performance-reports/${section.section_id}/update-table/${table.table_id}`"
                           class="flex items-center mr-3 font-normal">
                <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
                &nbsp;更改
              </router-link>
              <button
                      class="flex items-center text-danger font-normal"
                      @click="openDeleteModal('table', table.table_id)">
                <font-awesome-icon icon="fa-regular fa-trash-can"/>
                &nbsp;刪除
              </button>
            </div>
          </div>

          <div class="overflow-x-auto">
            <table class="table table-striped">
              <tbody>
                <tr v-for="(row, key) in table.rows" :key="key" :class="{'font-bold': row.is_header }">

                  <td v-show="editId === table.table_id">
                    <div class="flex justify-center items-center">
                      <button @click="openRowModal('update', row)"
                              class="btn btn-pending mr-3">
                        <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
                      </button>
                      <button class="btn btn-danger"
                              @click="openDeleteModal('row', row.row_id)">
                        <font-awesome-icon icon="fa-regular fa-trash-can"/>
                      </button>
                    </div>
                  </td>

                  <td v-for="(field, i) in row.fields" :key="i" :colspan="field.colspan" :rowspan="field.rowspan">
                    <div v-html="field.value"></div>
                    <div v-if="!field.is_default && !field.is_disabled" class="text-success"><font-awesome-icon icon="fa-solid fa-check"/> 可填寫</div>
                    <div v-if="field.is_disabled" class="text-danger"><font-awesome-icon icon="fa-solid fa-xmark"/> 已停用</div>
                    <div v-if="editId === table.table_id" class="flex justify-start items-center mt-2">
                      <button @click="openFieldModal('update', row.row_id, field)"
                              class="flex items-center mr-3 font-normal">
                        <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
                        &nbsp;更改
                      </button>
                      <button v-if="checkPermission(permissions, 'system.performance-report.manage')"
                              class="flex items-center text-danger font-normal"
                              @click="openDeleteModal('field', field.field_id, row.row_id)">
                        <font-awesome-icon icon="fa-regular fa-trash-can"/>
                        &nbsp;刪除
                      </button>
                    </div>
                  </td>

<!--                  <td v-for="i in getRemainColumnNumber(table.rows, row.fields.length)" :key="`empty-${i}`"></td>-->

                  <td v-show="editId === table.table_id">
                    <div class="flex justify-center items-center">
                      <button type="button" @click="openFieldModal('create', row.row_id)"
                              class="btn btn-primary shadow-md">
                        <font-awesome-icon icon="fa-solid fa-plus"/>
                      </button>
                    </div>
                  </td>
                </tr>
                <tr v-show="editId === table.table_id">

                  <td>
                    <div class="flex justify-center items-center">
                      <button type="button" @click="openRowModal('create')"
                              class="btn btn-primary shadow-md">
                        <font-awesome-icon icon="fa-solid fa-plus"/>
                      </button>
                    </div>
                  </td>

                  <td :colspan="getRemainColumnNumber(table.rows, 0)"></td>
                </tr>
              </tbody>
            </table>

            <div class="mt-4" v-html="table.footer"></div>
          </div>

        </div>
      </div>

      <!-- BEGIN: Delete Confirmation Modal -->
      <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger"/>
                <div class="text-3xl mt-5">你確定嗎？</div>
                <div class="text-slate-500 mt-2">
                  你是否要刪除此{{
                    deleteType === 'table' ? '表格' : (deleteType === 'row' ? '行' : (deleteType === 'field' ? '格' : '資料'))
                  }}？
                  <br>
                  這動作不能還原。
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->

      <!-- BEGIN: Row Modal -->
      <div id="row-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="font-medium text-base mr-auto">
                行
              </h2>
            </div>
            <div class="modal-body p-0">
              <div class="p-5">
                <div class="text-slate-500 mt-2">
                  <div class="">
                    <label class="form-label">表格</label><br>
                    <strong>{{ getObjectValue(section.tables, 'table_id', editId, 'table_name_zh') }}</strong>
                  </div>

                  <div class="mt-3">
                    <label class="form-label">所屬部/組/服務單位</label>
                    <multi-select
                      v-model="form.centres"
                      :options="centers"
                      :custom-label="centresSearchName"
                      track-by="centre_id"
                      :multiple="true"
                      :close-on-select="false"
                      placeholder="以名搜尋"
                      selectLabel="按下Enter以選擇"
                    >
                      <template slot="noResult"><span class="multiselect__option">沒有結果，請輸入其他字</span></template>
                    </multi-select>
                    <div v-if="'centres' in errors" class="text-danger mt-2" v-html="errors.centres"></div>
                  </div>

                  <div class="form-switch mt-3">
                    <label>是否標題</label>
                    <div class="mt-1">
                      <input v-model="form.is_header" type="checkbox" class="form-check-input">
                    </div>
                  </div>

                  <div class="mt-3">
                    <label class="form-label"><span class="text-danger">*</span> 顯示次序</label>
                    <input type="number" v-model="form.display_order" placeholder="請輸入顯示次序"
                           class="form-control w-full"/>
                    <div v-if="'display_order' in errors" class="text-danger mt-2" v-html="errors.display_order"></div>
                  </div>
                </div>
              </div>
              <div class="px-5 pb-8 flex justify-center align-items-center">
                <button v-if="!loading" type="button" data-tw-dismiss="modal"
                        class="btn btn-outline-secondary w-24 mr-1">取消
                </button>
                <button v-if="!loading" type="button" class="btn btn-primary w-24 text-white" @click="onSubmitRow">
                  確定
                </button>
                <div>
                  <span v-show="loading"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Row Modal -->

      <!-- BEGIN: Field Modal -->
      <div id="field-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h2 class="font-medium text-base mr-auto">
                格
              </h2>
            </div>
            <div class="modal-body p-0">
              <div class="p-5">
                <div class="text-slate-500 mt-2">
                  <div class="">
                    <label class="form-label">表格</label><br>
                    <strong>{{ getObjectValue(section.tables, 'table_id', editId, 'table_name_zh') }}</strong>
                  </div>

                  <div class="mt-3">
                    <label class="form-label"><span class="text-danger">*</span> 直列佔格</label>
                    <input type="number" v-model="form.rowspan" placeholder="請輸入直列佔格"
                           class="form-control w-full"/>
                    <div v-if="'rowspan' in errors" class="text-danger mt-2" v-html="errors.rowspan"></div>
                  </div>

                  <div class="mt-3">
                    <label class="form-label"><span class="text-danger">*</span> 橫行佔格</label>
                    <input type="number" v-model="form.colspan" placeholder="請輸入橫行佔格"
                           class="form-control w-full"/>
                    <div v-if="'colspan' in errors" class="text-danger mt-2" v-html="errors.colspan"></div>
                  </div>

                  <div class="mt-3">
                    <label class="form-label">值名稱</label>
                    <input type="text" v-model="form.value" placeholder="請輸入值名稱" class="form-control w-full"/>
                    <div v-if="'value' in errors" class="text-danger mt-2" v-html="errors.value"></div>
                  </div>

                  <div class="mt-3">
                    <label class="form-label"><span class="text-danger">*</span> 顯示次序</label>
                    <input type="number" v-model="form.display_order" placeholder="請輸入顯示次序"
                           class="form-control w-full"/>
                    <div v-if="'display_order' in errors" class="text-danger mt-2" v-html="errors.display_order"></div>
                  </div>

                  <div class="form-switch mt-3">
                    <label>非填寫項</label>
                    <div class="mt-1">
                      <input v-model="form.is_default" type="checkbox" class="form-check-input">
                    </div>
                  </div>

                  <div class="form-switch mt-3">
                    <label>停用</label>
                    <div class="mt-1">
                      <input v-model="form.is_disabled" type="checkbox" class="form-check-input">
                    </div>
                  </div>
                </div>
              </div>
              <div class="px-5 pb-8 flex justify-center align-items-center">
                <button v-if="!loading" type="button" data-tw-dismiss="modal"
                        class="btn btn-outline-secondary w-24 mr-1">取消
                </button>
                <button v-if="!loading" type="button" class="btn btn-primary w-24 text-white"
                        @click="onSubmitField">確定
                </button>
                <div>
                  <span v-show="loading"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Field Modal -->
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions'],
  data() {
    return {
      mounted: false,

      centers: [],

      editId: null,
      editRowId: null,

      section: null,
      tables: [],

      deleteType: null,
      deleteRowId: null,
      deleteId: null,

      mode: 'create',
      form: {
        centres: [],
        is_header: false,
        display_order: null,
        row_id: null,
        rowspan: 1,
        colspan: 1,
        value: null,
        is_default: false,
        is_disabled: false,
        field_id: null
      },
      errors: {},

      loading: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    }
  },
  async mounted() {
    window.tailwind.svgLoader()

    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      this.mounted = true
      await this.loadCenters()
      await this.loadSection()
    },
    async loadCenters() {
      this.centers = await this.getCenters()
    },
    async loadSection() {
      this.section = await this.getPerformanceReportSection(this.$route.params.sectionId)
    },
    getRemainColumnNumber(rows, length) {
      let max = 0
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].fields.length > max) {
          max = rows[i].fields.length
        }
      }

      return Math.max(max - length, 0)
    },
    onEdit(tableId) {
      if (this.editId == null) {
        this.editId = tableId
      } else {
        this.editId = null
      }
    },
    openDeleteModal(type, id, rowId = null) {
      this.deleteType = type
      this.deleteRowId = rowId
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      let response = null

      if (this.deleteType === 'table') {
        response = await this.deletePerformanceReportSectionTable(this.section.section_id, this.deleteId)
      } else if (this.deleteType === 'row') {
        response = await this.deletePerformanceReportSectionTableRow(this.section.section_id, this.editId, this.deleteId)
      } else if (this.deleteType === 'field') {
        response = await this.deletePerformanceReportSectionTableRowField(this.section.section_id, this.editId, this.deleteRowId, this.deleteId)
      } else {
        response = await this.deletePerformanceReportSectionTableRowField(this.section.section_id, this.editId, this.deleteRowId, this.deleteId)
      }

      let option = {
        title: '成功',
        message: this.deleteType === 'table' ? '表格已被刪除' : (this.deleteType === 'row' ? '行已被刪除' : (this.deleteType === 'field' ? '格已被刪除' : '資料已被刪除')),
        type: 'success'
      }

      if (response != null && response.code === 0) {
        await this.loadSection()
      } else {
        option = {
          title: this.deleteType === 'table' ? '未能刪除表格' : (this.deleteType === 'row' ? '未能刪除行' : (this.deleteType === 'field' ? '未能刪除格' : '未能刪除資料')),
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    openRowModal(mode, data) {
      this.mode = mode

      if (data != null) {
        this.form = structuredClone(data)
      } else {
        this.clearForm()
      }

      const el = document.querySelector('#row-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onSubmitRow() {
      this.loading = true
      this.errors = {}

      let response = null

      if (this.mode === 'create') {
        response = await this.createPerformanceReportSectionTableRow(this.section.section_id, this.editId, this.form)
      } else {
        response = await this.updatePerformanceReportSectionTableRow(this.section.section_id, this.editId, this.form.row_id, this.form)
      }

      let option = {
        title: '成功',
        message: this.mode === 'create' ? '已新增行' : '已更改行',
        type: 'success'
      }

      if (response.body && 'errors' in response.body) {
        this.errors = response.body.errors
      } else if (response.code === 0) {
        const el = document.querySelector('#row-modal')
        const modal = window.tailwind.Modal.getOrCreateInstance(el)
        modal.hide()

        await this.loadSection()

        this.$notify(option)

        this.clearForm()
      } else {
        option = {
          title: this.mode === 'create' ? '未能新增行' : '未能更改行',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }

        this.$notify(option)
      }

      this.loading = false
    },
    openFieldModal(mode, rowId, data) {
      this.mode = mode
      this.editRowId = rowId
      if (data != null) {
        this.form = structuredClone(data)
      } else {
        this.clearForm()
      }

      const el = document.querySelector('#field-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onSubmitField() {
      this.loading = true
      this.errors = {}

      let response = null

      if (this.mode === 'create') {
        response = await this.createPerformanceReportSectionTableRowField(this.section.section_id, this.editId, this.editRowId, this.form)
      } else {
        response = await this.updatePerformanceReportSectionTableRowField(this.section.section_id, this.editId, this.editRowId, this.form.field_id, this.form)
      }

      let option = {
        title: '成功',
        message: this.mode === 'create' ? '已新增格' : '已更改格',
        type: 'success'
      }

      if (response.body && 'errors' in response.body) {
        this.errors = response.body.errors
      } else if (response.code === 0) {
        const el = document.querySelector('#field-modal')
        const modal = window.tailwind.Modal.getOrCreateInstance(el)
        modal.hide()

        await this.loadSection()

        this.$notify(option)

        this.clearForm()
      } else {
        option = {
          title: this.mode === 'create' ? '未能新增格' : '未能更改格',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }

        this.$notify(option)
      }

      this.loading = false
    },
    clearForm() {
      this.form = {
        centers: [],
        is_header: false,
        display_order: null,
        row_id: null,
        rowspan: 1,
        colspan: 1,
        value: null,
        is_default: false,
        is_disabled: false,
        field_id: null
      }
    },
    centresSearchName(item) {
      return `${item.abbrev} ${item.name_zh}`
    }
  }
}
</script>
<style lang="scss" scoped>
#performance-report-tables-page {
  h2 {
    font-size: 32px;
    font-weight: bold;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 16px;
  }

  .tab-content {
    background-color: #FFF;

    table {
      table-layout: fixed;

      tbody {
        tr {
          td {
            height: inherit;
            background-color: #fff;
            border-right-width: 1px;
            vertical-align: top;

            &:first-child {
              text-align: center;
              border-right-width: 2px;
              background-color: rgb(var(--color-slate-100) / var(--tw-bg-opacity));
            }

            .content-action {
              flex: 0 0 104px;
            }

            .link {
              color: blue;
              text-decoration: underline;
            }
          }

          &:last-child {
            td {
              border-bottom-width: 0;

              &:last-child {
                border-right-width: 0;
              }
            }
          }

          &.font-bold {
            td {
              background-color: rgb(var(--color-slate-100) / var(--tw-bg-opacity));
            }
          }
        }
      }

      tr, td {
        height: 0;
      }

      @-moz-document url-prefix() {
        tr, th, td {
          height: 100%;
        }
      }
    }
  }
}
</style>
