<template>
  <div id="performance-report-page">

    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        執行報告
      </h2>
    </div>

    <div v-if="checkPermission(permissions, 'centre.performance-report.fill') && version != null && rows.length > 0" class="mt-4">

      <div v-if="section">
        <h3>
          {{ section.section_name_zh }}<br>
          {{ section.section_name_en }}
        </h3>

        <div v-for="(table, tableKey) in section.tables" :key="tableKey">
          <div v-if="hasRow(table)" class="tab-content border-l border-r border-b mb-5 mt-5">
            <div class="tab-pane leading-relaxed p-5 active">
              <div class="flex justify-evenly align-items-start mb-4">
                <div class="flex-1 text-center mb-2">
                  <h3>{{ table.table_name_zh }}</h3>
                  <h5>{{ table.table_name_en }}</h5>
                </div>
              </div>

              <div class="overflow-x-auto">
                <table class="table table-striped">
                  <tbody>
                  <template v-for="(row, key) in table.rows">
                    <tr :key="key" :class="{'font-bold': row.is_header}" v-if="isFillable(row.row_id)">
                      <td v-for="(field, i) in row.fields" :key="i" :colspan="field.colspan" :rowspan="field.rowspan">
                        <div v-html="field.value"></div>
                        <input type="number" class="form-control" v-if="!row.is_header && !field.is_default && field.field_id in form" :disabled="field.is_disabled" v-model="form[field.field_id]">
                      </td>
                    </tr>
                  </template>
                  </tbody>
                </table>

                <div class="mt-4" v-html="table.footer"></div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div v-if="!loading && !finish" class="flex justify-between align-items-center">
        <button v-if="sectionIndex > 0" class="btn btn-secondary" type="button" @click="changeSection(-1)"> 上一部分
        </button>
        <div v-else></div>

        <button v-if="sectionIndex < sections.length - 1" class="btn btn-secondary" type="button" @click="changeSection(1)"> 下一部分
        </button>

        <button v-else-if="sectionIndex === sections.length - 1" class="btn btn-primary" type="button" @click="onSubmit"> 提交
        </button>
      </div>
    </div>

    <div v-else-if="checkPermission(permissions, 'centre.performance-report.fill') && (version == null || rows.length === 0)" class="mt-4">
      <div class="alert alert-pending show mt-2" role="alert">現時未有執行報告需要填寫</div>
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>

    <div class="flex justify-end align-items-center">
      <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions'],
  data() {
    return {
      mounted: false,

      version: null,
      rows: [],
      sections: [],
      section: null,

      sectionIndex: 0,

      form: {
      },
      errors: {},

      loading: false,
      finish: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    }
  },
  async mounted() {
    window.tailwind.svgLoader()

    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      this.mounted = true
      await this.loadVersion()
      await this.loadPermissionRows()
      await this.loadSections()
      await this.loadSection()
      this.generateForm()
    },
    async loadVersion() {
      const response = await this.getPerformanceReportVersions()
      const versions = response.data

      for (let i = 0; i < versions.length; i++) {
        if (this.$moment().isAfter(versions[i].start_at) && this.$moment().isBefore(versions[i].end_at)) {
          this.version = versions[i]
          break
        }
      }
    },
    async loadPermissionRows() {
      this.rows = await this.getPerformanceReportPermissionRows()
    },
    async loadSections() {
      const response = await this.getPerformanceReportSections()
      this.sections = response.data
    },
    async loadSection() {
      if (this.sections.length > 0) {
        this.section = await this.getPerformanceReportSection(this.sections[this.sectionIndex].section_id)
      }
    },
    generateForm() {
      for (let i = 0; i < this.section.tables.length; i++) {
        for (let j = 0; j < this.section.tables[i].rows.length; j++) {
          if (!this.section.tables[i].rows[j].is_header) {
            const row = this.getObjectValue(this.rows, 'row_id', this.section.tables[i].rows[j].row_id)
            if (row != null) {
              for (let k = 0; k < this.section.tables[i].rows[j].fields.length; k++) {
                if (!(this.section.tables[i].rows[j].fields[k].field_id in this.form) && !this.section.tables[i].rows[j].fields[k].is_default && !this.section.tables[i].rows[j].fields[k].is_disabled) {
                  this.$set(this.form, this.section.tables[i].rows[j].fields[k].field_id, null)
                }
              }
            }
          }
        }
      }
    },
    hasRow(table) {
      for (let i = 0; i < table.rows.length; i++) {
        if (this.getObjectValue(this.rows, 'row_id', table.rows[i].row_id)) {
          return true
        }
      }

      return false
    },
    isFillable(rowId) {
      return this.getObjectValue(this.rows, 'row_id', rowId) != null
    },
    async changeSection(num) {
      this.section = null
      this.sectionIndex += num
      await this.loadSection()
      this.generateForm()
    },
    async onSubmit() {
      this.loading = true
      this.errors = {}

      const submitForm = []

      for (const key in this.form) {
        if (this.form[key] != null && this.form[key] !== '') {
          submitForm.push({
            field_id: key,
            value: this.form[key]
          })
        }
      }

      const response = await this.submitPerformanceReportData(this.version.version_id, { datas: submitForm })

      if (response.body && 'errors' in response.body) {
        this.errors = response.body.errors
        this.loading = false
      } else {
        let option = {
          title: '已成功提交執行報告',
          message: '請稍後，將會轉至主頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          setTimeout(() => {
            this.$router.push('/home')
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: '未能提交執行報告',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
#performance-report-page {
  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 16px;
  }

  .tab-content {
    background-color: #FFF;

    table {
      table-layout: fixed;

      tbody {
        tr {
          td {
            height: inherit;
            background-color: #fff;
            border-right-width: 1px;
            vertical-align: top;

            .content-action {
              flex: 0 0 104px;
            }

            .link {
              color: blue;
              text-decoration: underline;
            }
          }

          &:last-child {
            td {
              border-bottom-width: 0;

              &:last-child {
                border-right-width: 0;
              }
            }
          }

          &.font-bold td {
            text-align: center;
            border-right-width: 2px;
            background-color: rgb(var(--color-slate-100) / var(--tw-bg-opacity));
          }
        }
      }

      tr, td {
        height: 0;
      }

      @-moz-document url-prefix() {
        tr, th, td {
          height: 100%;
        }
      }
    }
  }
}
</style>
