<template>
  <div id="forms-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      問巻/電子表格
    </h2>

    <div>
      <div class="box grid grid-cols-12 gap-6 mt-5 p-5">
        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center">
          <router-link v-if="checkPermission(permissions, 'site.form.create')" to="/create-form" class="btn btn-primary shadow-md mr-2">新增</router-link>

          <div class="hidden md:block mx-auto text-slate-500">正在顯示第 {{ forms.length !== 0 ? from : 0 }}
            至 {{ to ?? 0 }} 份問巻/電子表格
          </div>

          <div class="mt-3 sm:mt-0 flex w-full sm:w-auto">
            <select v-if="checkPermission(permissions, 'site.form.edit')" id="status" v-model="currentStatus" class="form-select mr-2" aria-label="請選擇狀態">
              <option v-for="(item, key) in allStatus" :key="key" :value="item.value">{{ item.name }}</option>
            </select>

            <div class="w-56 relative text-slate-500">
              <input v-model="search" type="text" class="form-control w-56 pr-10" placeholder="搜尋">
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0" />
            </div>
          </div>
        </div>

        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="whitespace-nowrap">
                <order-button title="名稱" column="name" :order-by.sync="orderBy" :order.sync="order"></order-button>
              </th>
              <th class="whitespace-nowrap" v-if="checkPermission(permissions, ['site.form.create', 'site.form.edit', 'site.form.delete'])">
                <order-button title="建立時間" column="created_at" :order-by.sync="orderBy" :order.sync="order"></order-button>
              </th>
              <th class="whitespace-nowrap">
                <order-button title="開始日期時間" column="from_time" :order-by.sync="orderBy" :order.sync="order"></order-button>
              </th>
              <th class="whitespace-nowrap">
                <order-button title="完結日期時間" column="to_time" :order-by.sync="orderBy" :order.sync="order"></order-button>
               </th>
              <th class="text-center whitespace-nowrap" v-if="checkPermission(permissions, ['site.form.create', 'site.form.edit'])">回覆數目</th>
              <th class="text-center whitespace-nowrap">回答</th>
              <th class="text-center whitespace-nowrap" v-if="checkPermission(permissions, 'site.form.edit')">檢視</th>
              <th
                v-if="checkPermission(permissions, 'site.form.edit') || checkPermission(permissions, 'site.form.delete')"
                class="text-center whitespace-nowrap">動作
              </th>
            </tr>
            </thead>
            <tbody>
            <tr class="" v-for="(item, key) in forms" :key="key">
              <td>{{ item.name }}</td>
              <td v-if="checkPermission(permissions, ['site.form.create', 'site.form.edit', 'site.form.delete'])">{{ item.created_at ? $moment(item.created_at).format('YYYY-MM-DD HH:mm:ss') : '-' }}</td>
              <td>{{ item.from_time ? $moment(item.from_time).format('YYYY-MM-DD HH:mm:ss') : (item.created_at ? $moment(item.created_at).format('YYYY-MM-DD HH:mm:ss') : '-') }}</td>
              <td>{{ item.to_time ? $moment(item.to_time).format('YYYY-MM-DD HH:mm:ss') : '-' }}</td>
              <td>{{ item.responses_count }}</td>
              <td class="table-report__action text-center">
                <router-link v-if="item.is_submitted" :to="`/forms/${item.form_id}/response`" class="btn btn-warning-soft mr-2">
                  <font-awesome-icon icon="fa-regular fa-file-lines"/>
                </router-link>
                <router-link v-if="((!item.is_submitted) || (item.is_submitted && item.is_multi_submit && (item.quota == null || item.quota === 0 || item.quota > item.responses_count))) && (item.to_time == null || $moment().isBefore(item.to_time))" :to="`/submit-form/${item.form_id}`" class="btn btn-pending">
                  <font-awesome-icon icon="fa-solid fa-pencil" class="text-white"/>
                </router-link>
              </td>
              <td v-if="checkPermission(permissions, 'site.form.edit')" class="table-report__action text-center" >
                <router-link :to="`/forms/${item.form_id}`" class="btn btn-success">
                  <font-awesome-icon icon="fa-regular fa-eye" class="text-white"/>
                </router-link>
              </td>
              <td v-if="checkPermission(permissions, 'site.form.edit') || checkPermission(permissions, 'site.form.delete')" class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <router-link v-if="checkPermission(permissions, 'site.form.edit')" :to="`/update-form/${item.form_id}`" class="flex items-center mr-3">
                    <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                    &nbsp;更改 </router-link>
                  <button v-if="checkPermission(permissions, 'site.form.create')" class="flex items-center text-pending mr-3" @click="openCopyModal(item.form_id)">
                    <font-awesome-icon icon="fa-regular fa-copy" />
                    &nbsp;複製 </button>
                  <button v-if="checkPermission(permissions, 'site.form.delete')" class="flex items-center text-danger" @click="openDeleteModal(item.form_id)">
                    <font-awesome-icon icon="fa-regular fa-trash-can" />
                    &nbsp;刪除 </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->

        <!-- BEGIN: Pagination -->
        <pagination-row :pages="pages" :current-page.sync="currentPage"
                        :item-per-page.sync="itemPerPage"></pagination-row>
        <!-- END: Pagination -->

        <!-- BEGIN: Copy Confirmation Modal -->
        <div id="copy-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body p-0">
                <div class="p-5 text-center">
                  <font-awesome-icon icon="fa-regular fa-copy" class="fa-3x text-pending" />
                  <div class="text-3xl mt-5">你確定嗎？</div>
                  <div class="text-slate-500 mt-2">
                    你是否要複製此問巻/電子表格？
                    <br>
                    舊有的回答記錄不會被複製的新的問卷
                  </div>
                </div>
                <div class="px-5 pb-8 text-center">
                  <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                  <button type="button" class="btn btn-pending w-24" @click="onCopy">確定</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Copy Confirmation Modal -->

        <!-- BEGIN: Delete Confirmation Modal -->
        <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body p-0">
                <div class="p-5 text-center">
                  <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
                  <div class="text-3xl mt-5">你確定嗎？</div>
                  <div class="text-slate-500 mt-2">
                    你是否要刪除此問巻/電子表格？
                    <br>
                    這動作不能還原。
                  </div>
                </div>
                <div class="px-5 pb-8 text-center">
                  <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                  <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Delete Confirmation Modal -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,

      resources: [],
      forms: [],

      downloading: false,

      itemPerPage: 10,
      currentPage: 1,
      pages: 0,
      from: 0,
      to: 0,
      search: '',
      orderBy: 'created_at',
      order: 'DESC',
      currentStatus: 'ALL',

      allStatus: [
        { name: '全部', value: 'ALL' },
        { name: '公開', value: 'PUBLISHED' },
        { name: '草稿', value: 'DRAFT' }
      ],

      downloadDate: new Date(),

      action: null,
      selectId: null,
      copyId: null,
      deleteId: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    },
    currentPage(val) {
      this.loadForms(false)
    },
    itemPerPage(val) {
      this.loadForms()
    },
    currentStatus(val) {
      this.loadForms()
    },
    order() {
      this.loadForms()
    },
    search(val) {
      this.loadForms()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
    window.tailwind.svgLoader()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadResources()
        await this.loadForms()
        this.mounted = true
      }
    },
    async loadResources() {
      const response = await this.getSiteResources(this.currentSite.site_id, '', 99999999, 1)
      this.resources = response.data
    },
    async loadForms(resetCurrentPage = true) {
      const response = await this.getSiteForms(this.currentSite.site_id, this.search, this.itemPerPage, this.currentPage, this.orderBy, this.order, this.currentStatus)

      this.forms = response.data
      this.pages = response.meta.page.last_page
      this.from = response.meta.page.from
      this.to = response.meta.page.to

      if (resetCurrentPage) {
        this.currentPage = 1
      }
    },
    openCopyModal(id) {
      this.copyId = id

      const el = document.querySelector('#copy-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onCopy() {
      const el = document.querySelector('#copy-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.copySiteForm(this.currentSite.site_id, this.copyId)

      let option = {
        title: '成功',
        message: '問巻/電子表格已被複製',
        type: 'success'
      }

      if (response.code === 0) {
        this.loadForms()
      } else {
        option = {
          title: '未能複製問巻/電子表格',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    openDeleteModal(id) {
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteSiteForm(this.currentSite.site_id, this.deleteId)

      let option = {
        title: '成功',
        message: '問巻/電子表格已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        this.loadForms()
      } else {
        option = {
          title: '未能刪除問巻/電子表格',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped>
#forms-page {
  .btn-download {
    white-space: nowrap;
  }

  .svg-inline--fa {
    height: 10px;
  }
}
</style>
