import Dropzone from 'dropzone'
import Vue from 'vue'

const dropZone = {
  methods: {
    dropZone() {
      $('.dropzone').each(function() {
        const options = {
          acceptedFiles: 'image/*',
          autoQueue: false,
          accept: (file, done) => {
            console.log('Uploaded')
            done()
          }
        }

        if ($(this).data('single')) {
          options.maxFiles = 1
        }

        if ($(this).data('file-types')) {
          options.accept = (file, done) => {
            if ($(this).data('file-types').split('|').indexOf(file.type) === -1) {
              alert('Error! Files of this type are not accepted')
              done('Error! Files of this type are not accepted')
            } else {
              console.log('Uploaded')
              done()
            }
          }
        }

        Vue.prototype.$dz = new Dropzone(this, options)
      })
    },
    dropZoneAll() {
      $('.dropzoneAll').each(function() {
        const options = {
          autoQueue: false,
          accept: (file, done) => {
            console.log('Uploaded')
            done()
          }
        }

        Vue.prototype.$dz = new Dropzone(this, options)
      })
    },
    dropZoneOne() {
      $('.dropzoneOne').each(function() {
        const options = {
          autoQueue: false,
          accept: (file, done) => {
            console.log('Uploaded')
            done()
          }
        }

        options.maxFiles = 1

        Vue.prototype.$dz1 = new Dropzone(this, options)
      })
    }
  }
}

export default dropZone
