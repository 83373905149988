<template>
  <div id="site-permissions-page" v-if="site != null">
    <div class="flex justify-between mt-10">
      <h2 class="intro-y text-lg font-medium">
        {{ site.name }}的職能權限 (共{{ allRoles.length }}個)
      </h2>

      <router-link :to="`/management/sites/${site.site_id}/roles`" type="button" class="btn btn-outline-secondary w-24">返回</router-link>
    </div>

    <div v-if="checkPermission(permissions, 'system.role_permission.view')">
      <div class="grid grid-cols-12 gap-6 mt-5 mb-5">
        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
          <div class="hidden md:block mx-auto text-slate-500">正在顯示第
            {{ filterRoles.length !== 0 ? parseInt((currentPage - 1) * itemPerPage + 1) : 0 }} 至 {{
              (((currentPage - 1) * itemPerPage + itemPerPage) < filterRoles.length) ? parseInt((currentPage - 1) * itemPerPage + itemPerPage) : filterRoles.length
            }} 個職能
          </div>
          <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
            <div class="w-56 relative text-slate-500">
              <input v-model="search" type="text" class="form-control w-56 box pr-10" placeholder="搜尋">
              <font-awesome-icon icon="fa-solid fa-magnifying-glass"
                                 class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0"/>
            </div>
          </div>
        </div>

        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-striped">
            <thead>
            <tr>
              <th class="whitespace-nowrap"></th>
              <th class="whitespace-nowrap" v-for="(role, i) in roles" :key="i">
                  <div class="flex flex-column align-items-center">
                    <span>{{ role.name }}</span>
                    <div class="btn-container" v-if="!role.is_default && checkPermission(permissions, 'system.role.edit')">
                        <button type="button" class="btn btn-primary mr-1" @click="selectAll(role)">全選</button>
                        <button type="button" class="btn btn-secondary" @click="unSelectAll(role)">清空</button>
                    </div>
                  </div>
              </th>
            </tr>
            </thead>
            <tbody>
            <tr class="" v-for="(item, key) in allPermissions" :key="key">
              <td>{{ item.name }}</td>
              <td v-for="(role, i) in roles" :key="i">
                <div class="form-switch text-center">
                  <input v-model="role.permissions" :value="item.permission_id" type="checkbox" class="form-check-input" @change="onChange(role)" :disabled="role.is_default ||  !checkPermission(permissions, 'system.role.edit')">
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
      </div>

      <!-- BEGIN: Pagination -->
      <pagination-row :pages="pages" :current-page.sync="currentPage" :item-per-page.sync="itemPerPage"></pagination-row>
      <!-- END: Pagination -->
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>

  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions', 'currentSite'],
  data() {
    return {
      mounted: false,
      site: null,
      allPermissions: [],

      allRoles: [],
      filterRoles: [],
      roles: [],
      itemPerPage: 10,
      currentPage: 1,
      pages: 0,
      search: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    async currentSite(val) {
      await this.init()
    },
    currentPage(val) {
      this.updateList()
    },
    itemPerPage(val) {
      this.pages = Math.ceil(this.filterRoles.length / val)
      if (this.currentPage === 1) {
        this.updateList()
      }

      this.currentPage = 1
    },
    search(val) {
      if (val == null || val === '') {
        this.filterRoles = this.allRoles
      } else {
        this.filterRoles = this.allRoles.filter(o => (o.name != null && o.name.includes(val)) || (o.description != null && o.description.includes(val)))
      }

      this.pages = Math.ceil(this.filterRoles.length / this.itemPerPage)
      this.currentPage = 1
      this.updateList()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadSite()
        await this.loadPermissions()
        await this.loadRoles()

        setTimeout(() => {
          this.mounted = true
        }, 500)
      }
    },
    async loadSite() {
      this.site = await this.getSite(this.$route.params.siteId)
    },
    async loadPermissions() {
      const allPermissions = await this.getPermissions()
      this.allPermissions = allPermissions.filter(permission => {
        return this.site.site_id === 1 || (this.site.site_id !== 1 && !permission.code.startsWith('system.'))
      })
    },
    async loadRoles() {
      this.filterRoles = this.allRoles = await this.getRoles(this.$route.params.siteId)
      this.pages = Math.ceil(this.filterRoles.length / this.itemPerPage)
      this.currentPage = 1

      for (let i = 0; i < this.allRoles.length; i++) {
        const role = await this.getRole(this.allRoles[i].role_id)
        this.$set(this.allRoles[i], 'permissions', role.permissions.map(a => a.permission_id))
      }

      this.updateList()
    },
    updateList() {
      this.roles = this.filterRoles.slice((this.currentPage - 1) * this.itemPerPage, (this.currentPage - 1) * this.itemPerPage + this.itemPerPage)
    },
    selectAll(role) {
      const permissions = this.permissions.map((a) => { return a.permission_id })
      role.permissions = permissions
      this.onChange(role)
    },
    unSelectAll(role) {
      role.permissions = []
      this.onChange(role)
    },
    async onChange(role) {
      const permissions = role.permissions.map((a) => { return { permission_id: a } })

      const response = await this.updateRolePermission(role.role_id, { permissions })

      let option = {
        title: '成功',
        message: `${role.name}的權限已被更改`,
        type: 'success'
      }

      if (response.code !== 0) {
        option = {
          title: `未能更改${role.name}的權限`,
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-container {
    button {
        padding: 2px 10px;
    }
}
</style>
