<template>
  <!-- BEGIN: Youtube Modal -->
  <div id="youtube-modal" class="modal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="font-medium text-base mr-auto">
            插入Youtube影片
          </h2>
        </div>
        <div class="modal-body p-0">
          <div class="p-5">
            <div class="text-slate-500">
              <div class="">
                <label class="form-label">Youtube影片網址</label>
                <input type="text" v-model="link" placeholder="請輸入Youtube連結" class="form-control w-full"/>
              </div>
            </div>
          </div>
          <div class="px-5 pb-8 text-center">
            <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">關閉
            </button>
            <button type="button" data-tw-dismiss="modal" class="btn btn-primary w-24 text-white"
                    :disabled="link == null || link === ''"
                    @click="onConfirm">確定
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Youtube Modal -->
</template>

<script>
export default {
  data() {
    return {
      modal: null,
      link: null
    }
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    onShow() {
      this.link = null

      const el = document.querySelector('#youtube-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    onConfirm() {
      this.$emit('on-confirm', this.link)

      const el = document.querySelector('#youtube-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()
    }
  }
}
</script>
<style lang="scss" scoped>

#youtube-insector {
}
</style>
