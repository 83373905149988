<template>
  <div id="side-menu">
    <!-- BEGIN: Side Menu -->
    <nav class="side-nav" v-if="currentSite">
      <ul>
        <li v-for="(item, key) in pages" :key="key">
          <router-link :to="item.url" class="side-menu" :class="{'side-menu--active': isActive(item)}" v-if="item.sitePermission && (item.permission == null || checkPermission(permissions, item.permission))">
            <div class="side-menu__icon"><font-awesome-icon :icon="item.icon" class="fa-2x" /></div>
            <div class="side-menu__title"> {{ item.name }}</div>
          </router-link>
        </li>
      </ul>
    </nav>
    <!-- END: Side Menu -->
  </div>
</template>

<script>
export default {
  props: ['currentSite', 'permissions'],
  data() {
    return {
      pages: [
        { name: '報告板', url: '/announcements', icon: 'fa-solid fa-rss', sitePermission: false, permission: 'site.announcement.view' },
        { name: '行事曆', url: '/calendar', icon: 'fa-regular fa-calendar', sitePermission: false, permission: 'site.calendar.view' },
        { name: '通訊錄', url: '/address-books', icon: 'fa-regular fa-address-book', sitePermission: false, permission: 'site.phone_directory.view' },
        { name: '檔案櫃', url: '/library', icon: 'fa-regular fa-hard-drive', sitePermission: false, permission: null },
        { name: '申請表', url: '/workflows', icon: 'fa-solid fa-table-columns', sitePermission: false, permission: ['system.workflow.email-workflow', 'system.workflow.account-workflow'] },
        { name: '系統管理', url: '/management', icon: 'fa-solid fa-gear', sitePermission: false, permission: ['site.manage'] },
        { name: '問巻/電子表格', url: '/forms', icon: 'fa-regular fa-file-lines', sitePermission: false, permission: 'site.form.view' },
        { name: '會議室及資源預借', url: '/resource-bookings', icon: 'fa-solid fa-clipboard-check', sitePermission: false, permission: 'site.resource.view' },
        { name: 'FAQ', url: '/faq', icon: 'fa-regular fa-circle-question', sitePermission: false, permission: 'site.knowledge_base.view' },
        { name: '討論區', url: '/forum', icon: 'fa-regular fa-comment', sitePermission: false, permission: 'site.forum.view' },
        { name: '執行報告', url: '/performance-report', icon: 'fa-regular fa-file', sitePermission: false, permission: 'centre.performance-report.fill' }
      ]
    }
  },
  watch: {
    currentSite() {
      this.init()
    }
  },
  mounted() {
    this.sideMenu()
    this.$emit('pass-pages', this.pages)
    this.init()
  },
  methods: {
    init() {
      if (this.currentSite) {
        this.$set(this.pages[0], 'sitePermission', this.currentSite.is_announcement_enabled)
        this.$set(this.pages[1], 'sitePermission', this.currentSite.is_calendar_enabled)
        this.$set(this.pages[2], 'sitePermission', this.currentSite.is_phone_directory_enabled)
        this.$set(this.pages[3], 'sitePermission', true)
        this.$set(this.pages[4], 'sitePermission', this.currentSite.is_workflow_enabled)
        this.$set(this.pages[5], 'sitePermission', true)
        this.$set(this.pages[6], 'sitePermission', this.currentSite.is_form_enabled)
        this.$set(this.pages[7], 'sitePermission', this.currentSite.is_resource_booking_enabled)
        this.$set(this.pages[8], 'sitePermission', this.currentSite.is_knowledge_base_enabled)
        this.$set(this.pages[9], 'sitePermission', this.currentSite.is_forum_enabled)
        this.$set(this.pages[10], 'sitePermission', true)
      }
    },
    isActive(item) {
      return this.$route.path.startsWith(item.url)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
