<template>
  <div id="contact-form">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">

          <div class="highlight p-2">
            如申請電郵的同事為專業職級(包括社工、護士、輔助醫療人員、行政人員)，請在「帳戶類別」一項選「行政人員」，以便獲得相應較高的電郵容量。
          </div>

          <div class="intro-y col-span-11 alert alert-primary show flex justify-between items-center mb-6 mt-6"
               role="alert">
            <span class="font-medium">第一部份 - 帳戶資料（請以正楷填寫）<sup>note1</sup></span>
            <span>*必須填寫</span>
          </div>

          <div class="mt-3">
            <div class="flex">
              <div class="flex-1 mr-1">
                <label for="honorific_title" class="form-label">稱謂</label>
                <select id="honorific_title" v-model="form.honorific_title" class="form-select" aria-label="請選擇稱呼">
                  <option value="">--- 請選擇 ---</option>
                  <option v-for="(item, key) in titles" :key="key" :value="item.value">{{
                      item.value
                    }}
                  </option>
                </select>
                <div v-if="'honorific_title' in errors" class="text-danger mt-2" v-html="errors.honorific_title"></div>
              </div>
              <div class="flex-1 mr-1">
                <label for="last_name" class="form-label">英文姓氏</label>
                <input v-model="form.last_name" id="last_name" type="text" class="form-control w-full"
                       placeholder="請輸入姓氏">
                <div>(First note2&3&4<br>e.g. David</div>
                <div v-if="'last_name' in errors" class="text-danger mt-2" v-html="errors.last_name"></div>
              </div>
              <div class="flex-1 mr-1">
                <label for="middle_name" class="form-label">英文中間名</label>
                <input v-model="form.middle_name" id="middle_name" type="text" class="form-control w-full"
                       placeholder="請輸入中間名">
                <div>/Middle<br>Tai Man</div>
                <div v-if="'middle_name' in errors" class="text-danger mt-2" v-html="errors.middle_name"></div>
              </div>
              <div class="flex-1">
                <label for="first_name" class="form-label">英文名字</label>
                <input v-model="form.first_name" id="first_name" type="text" class="form-control w-full"
                       placeholder="請輸入名字">
                <div>/ Last)<br> LEE</div>
                <div v-if="'first_name' in errors" class="text-danger mt-2" v-html="errors.first_name"></div>
              </div>
            </div>
          </div>

          <div class="mt-3">
            <label for="staff_number" class="form-label">職員編號</label>
            <input v-model="form.staff_number" id="staff_number" type="text" class="form-control w-full"
                   placeholder="請輸入職員編號">
            <div v-if="'staff_number' in errors" class="text-danger mt-2" v-html="errors.staff_number"></div>
          </div>

          <div class="mt-3">
            <label for="centre_id" class="form-label"><span class="text-danger">*</span> 所屬部/組/服務單位</label>
            <select id="centre_id" v-model="form.centre_id" class="form-select" aria-label="請選擇分站" @change="onSelectCenter">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in centers" :key="key" :value="item.centre_id">{{ item.abbrev + ` (${item.name_en})` }}</option>
            </select>
            <div v-if="'centre_id' in errors" class="text-danger mt-2" v-html="errors.centre_id"></div>
          </div>

          <div class="mt-3">
            <label for="cost_centre_code" class="form-label">單位編號</label>
            <input v-model="form.cost_centre_code" id="cost_centre_code" type="text" class="form-control w-full"
                   placeholder="請輸入單位編號">
            <div v-if="'cost_centre_code' in errors" class="text-danger mt-2"
                 v-html="errors.cost_centre_code"></div>
          </div>

          <div class="mt-3">
            <label for="contact_person" class="form-label"><span class="text-danger">*</span> 聯絡人名稱</label>
            <input v-model="form.contact_person" id="contact_person" type="text" class="form-control w-full"
                   placeholder="請輸入聯絡人名稱">
            <div v-if="'contact_person' in errors" class="text-danger mt-2"
                 v-html="errors.contact_person"></div>
          </div>

          <div class="mt-3">
            <label for="contact_number" class="form-label"><span class="text-danger">*</span> 辦公室電話</label>
            <input v-model="form.contact_number" id="contact_number" type="text" class="form-control w-full"
                   placeholder="請輸入辦公室電話">
            <div v-if="'contact_number' in errors" class="text-danger mt-2" v-html="errors.contact_number"></div>
          </div>

          <div class="mt-3">
            <label for="account_category" class="form-label"><span class="text-danger">*</span> 帳戶類別</label>
            <select id="account_category" v-model="form.account_category" class="form-select"
                    aria-label="請選擇帳戶類別">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in accountCategories" :key="key" :value="item.value">{{ item.key }}</option>
            </select>
            <div v-if="'account_category' in errors" class="text-danger mt-2" v-html="errors.account_category"></div>
          </div>

          <div class="mt-3">
            <label for="request_for" class="form-label"><span class="text-danger">*</span> 申請要求</label>
            <select id="request_for" v-model="form.request_for" class="form-select" aria-label="請選擇申請要求">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in requestTypes" :key="key" :value="item.value">{{ item.key }}</option>
            </select>
            <div v-if="'request_for' in errors" class="text-danger mt-2" v-html="errors.request_for"></div>
          </div>

          <div class="mt-3" v-if="form.request_for === 1 || form.request_for === 2">
            <div class="flex">
              <div class="flex-1 mr-1">
                <label for="request_email_1" class="form-label">首選帳戶電郵</label>
                <div class="input-group">
                  <input v-model="form.request_email_1" id="request_email_1" type="text" class="form-control w-full"
                         placeholder="請輸入首選帳戶電郵">
                  <div class="input-group-text">@tungwah.org.hk</div>
                </div>
                <div class="mt-1">(首選帳戶名稱)<sup>note5</sup></div>
                <div v-if="'request_email_1' in errors" class="text-danger mt-2" v-html="errors.request_email_1"></div>
              </div>
              <div class="flex-1 ml-1">
                <label for="request_email_2" class="form-label">次選帳戶電郵</label>
                <div class="input-group">
                  <input v-model="form.request_email_2" id="request_email_2" type="text" class="form-control w-full"
                         placeholder="請輸入次選帳戶電郵">
                  <div class="input-group-text">@tungwah.org.hk</div>
                </div>
                <div class="mt-1">(次選帳戶名稱)</div>
                <div v-if="'request_email_2' in errors" class="text-danger mt-2" v-html="errors.request_email_2"></div>
              </div>
            </div>
          </div>

          <div class="mt-3" v-if="form.request_for === 3 || form.request_for === 4">
            <label for="request_email_1" class="form-label">現有帳戶電郵</label>
            <div class="input-group">
              <input v-model="form.request_email_1" id="request_email_1" type="text" class="form-control w-full"
                     placeholder="請輸入現有帳戶電郵">
              <div class="input-group-text">@tungwah.org.hk</div>
            </div>
            <div v-if="'request_email_1' in errors" class="text-danger mt-2" v-html="errors.request_email_1"></div>
          </div>

          <div class="mt-3" v-if="form.request_for === 5">
            <label for="request_message" class="form-label">請詳述</label>
            <input v-model="form.request_message" id="contact_number" type="text" class="form-control w-full"
                   placeholder="請輸入要求內容">
            <div v-if="'request_message' in errors" class="text-danger mt-2" v-html="errors.request_message"></div>
          </div>

          <div class="mt-3">
            <label for="reason_type" class="form-label">申請原因</label>
            <select id="reason_type" v-model="form.reason_type" class="form-select" aria-label="請選擇申請要求">
              <option value="">--- 請選擇 ---</option>
              <option v-for="(item, key) in reasonTypes" :key="key" :value="item.value">{{ item.key }}</option>
            </select>
            <div>note 6 & 7</div>
            <div v-if="'reason_type' in errors" class="text-danger mt-2" v-html="errors.reason_type"></div>
          </div>

          <div class="mt-3">
            <label for="reason" class="form-label">如申請功能/職務帳戶，必須詳述原因<sup>note 8</sup></label>
            <input v-model="form.reason" id="reason" type="text" class="form-control w-full" placeholder="請輸入原因" maxlength="250">
            <div class="text-right mt-1 text-gray-600" v-if="form.reason != null"> {{ form.reason.length }} / 250</div>
            <div v-if="'reason' in errors" class="text-danger mt-2" v-html="errors.reason"></div>
          </div>

          <div class="mt-3">
            <label for="note" class="form-label">備註欄</label>
            <input v-model="form.note" id="note" type="text" class="form-control w-full" placeholder="請輸入備註" maxlength="250">
            <div class="text-right mt-1 text-gray-600" v-if="form.note != null"> {{ form.note.length }} / 250</div>
            <div v-if="'note' in errors" class="text-danger mt-2" v-html="errors.note"></div>
          </div>

          <div class="flex justify-end align-items-center mt-5">
            <router-link v-if="!loading && !finish" to="/workflows?type=email" type="button"
                         class="btn btn-outline-secondary w-24 mr-1">取消
            </router-link>

            <button v-if="!loading && !finish" type="button" class="btn btn-outline-pending w-24 mr-1"
                    @click="onSave(true)">儲存
            </button>

            <button v-if="!loading && !finish" type="button" class="btn btn-primary w-24" @click="onSave(false)">遞交</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>

          <div class="mt-4">
            <div class="form-titie">附註 NOTES</div>

            <table class="mt-2">
              <tbody>
              <tr>
                <td class="align-top">1.</td>
                <td>
                  東華三院公司電郵帳戶主要給予現任雇用於「東華三院長期性合約制」的員工，在工作上對外及內部溝通而設。如果有多位申請人填寫表格，請前往東華三院互聯網頁https://www.twghintranet.org/
                  的資訊科技科網站下載'ITDF19AS - Supplementary Form for Setting Up TWGHs Corporate E-Mail Accounts'
                  填寫多位申請人的補充資料，並遞交資訊科技科。
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  TWGHs Corporate E-mail user account is mainly issued to each currently employed staff member with
                  "HRIS Permanent Staff Payroll" contract for external and internal E-mail communication purposes. If
                  more than one applicant is filled in the form, please enclose a supplementary sheet for submission.
                  For the downloading of 'ITDF19AS - Supplementary Form for Setting Up TWGHs Corporate E-mail Accounts),
                  please access ITD sub-site of TWGHs Intranet Application Portal (https://www.twghintranet.org/itd/)
                  and get the relevant form from session 'Forms'.
                </td>
              </tr>
              <tr>
                <td><br></td>
              </tr>
              <tr>
                <td class="align-top">2.</td>
                <td>如有關帳戶為功能/職務帳戶，即其帳戶為某玏能/職務而設置者(例如﹕fund-raising@tungwah.org.hk)，請於姓名欄(First
                  / Middle /Last Name)內填上"(N/A)"以作識別。
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Please indicate "(N/A)" for Function/Role-based E-mail Accounts in “(First / Middle / Last Name)”
                  field which are used for specific function and role-based purposes. (e.g. fund-raising@tungwah.org.hk)
                </td>
              </tr>
              <tr>
                <td><br></td>
              </tr>
              <tr>
                <td class="align-top">3.</td>
                <td>我們建議帳戶使用者填寫其常用的洋名(例如﹕David)，即使有關名字並沒未曾於身分證/護照上登記。為確保公司電郵帳戶的命名標準化，這裡的“First
                  Name”指帳戶使用者的洋名(例如﹕David)，“Middle Name” 為帳戶使用者的英文名(例如﹕Tai Man)，而“Last
                  Name”則指帳戶使用者的姓氏(例如﹕Lee)。
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Users are recommended to indicate their Christian names (e.g. David) even though they are not
                  officially shown on HKID cards/Passports. For standardization, the “First Name” is herein equivalent
                  to user's Christian name (e.g. David), the “Middle Name” is equivalent to user’s English names (e.g.
                  Tai Man), and the “Last Name” is equivalent to user's surname or family name (e.g. Lee),.
                </td>
              </tr>
              <tr>
                <td><br></td>
              </tr>
              <tr>
                <td class="align-top">4.</td>
                <td>帳戶使用者透過網上電郵服務使用電郵系統時，使用者的「顯示名稱」將於顯示於系統的介面上以之識别。</td>
              </tr>
              <tr>
                <td></td>
                <td>Users “displayed names” are shown on the screen interface for identification while accessing the
                  E-mail system through its webmail service.
                </td>
              </tr>
              <tr>
                <td><br></td>
              </tr>
              <tr>
                <td class="align-top">5.</td>
                <td>
                  請參閱及遵守有關新電郵地址命名的規則(詳列於檔案公函ITD
                  MAS/09-10之附件二)。當遇上新開設的電郵地址跟現有的名稱相撞時，資訊科技科將會與有關的使用者/負責主任重新確認。
                  <br>
                  (a) 電郵地址現時分為「員工個人地址」及「功能/職務地址」。
                  <br>
                  (b)
                  有關員工的電郵地址，電郵地址應使用英文小寫，其形式為＜first-name＞.＜last-name＞@tungwah.org.hk。當地址出現相撞的情況時，將會加上員工的英文名字之縮寫如＜first-name＞.＜middle-initial＞.＜last-name＞@tungwah.org.hk。
                  而現有的電郵地址則會以「別名」的形式搬遷到新電郵系統中。
                  <br>
                  (c) 當有需要時，功能/職務電郵地址可以採用＜division/centre/unit＞ -
                  ＜function/role＞@tungwah.org.hk的形式細分，以避免東華三院機構內有重覆電郵地址的情況出現。
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  Please review and observe the naming convention for the new E-mail addresses below (as stipulated in
                  memorandum of our Ref (2) in ITD MAS/09-10). For any clashes in addresses arises, IT Division will
                  approach related users/responsible officers for re-confirmation.
                  <br>
                  (a) TWGHs Corporate E-mail address is currently classified as “Staff Personal Address” or
                  “Function/Role-based Address”
                  <br>
                  (b) For staff email address, the E-mail address should be in lowercase in the format of
                  ＜first-name＞.＜last-name＞@tungwah.org.hk. When there is a clash in the address, the second or/and third
                  initial of staff’s middle name will be incorporated into the address as
                  ＜first-name＞.＜middle-initial＞.＜last-name＞@tungwah.org.hk.
                  <br>
                  (c) Function/role-based Email Address can be further subdivided as ＜division/centre/unit＞ -
                  ＜function/role＞@tungwah.org.hk when necessary so as to avoid duplications in email address among
                  TWGHs.
                </td>
              </tr>
              <tr>
                <td><br></td>
              </tr>
              <tr>
                <td class="align-top">6.</td>
                <td>帳戶使用者如轉職後，其工作所屬科/處/部/組/服務單位/學校 及/或 帳戶類別有所更改，請於「第一部份 –
                  帳戶資料」清楚地填寫轉職後的帳戶资料。使用者的電郵帳戶及其有關電郵资料不會因轉職後被刪除及重設。
                </td>
              </tr>
              <tr>
                <td></td>
                <td>In case of any change in current posting and account category after staff movement, please clearly
                  fill in ALL the updated information in “Part I - Particular of Account”. User’s E-mail account and
                  relevant E-mail messages will NOT be deleted and reset because of such change request.
                </td>
              </tr>
              <tr>
                <td><br></td>
              </tr>
              <tr>
                <td class="align-top">7.</td>
                <td>
                  直轄主管必需經申請表格盡快通知我們有關電郵帳戶使用者離職事宜，我們會在收到有關申請後，將該電郵帳戶及其所有的電郵內容全部刪除。
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Immediate supervisor shall inform IT Division of any staff termination under his/her charge as soon
                  as possible. IT Division will delete relevant E-mail accounts and messages completely once receiving
                  such notification through this form.
                </td>
              </tr>
              <tr>
                <td><br></td>
              </tr>
              <tr>
                <td class="align-top">8.</td>
                <td>請填妥此申請表，並依據申請科的內部程序，呈遞有關主管批核，然後傳真到資訊科技科。 傳真號碼為 2803
                  2948。另外，為便於對外與公衆人士聯絡或刊印電郵地址於印刷品上，有關功能/職務電郵帳戶之申請必須按科主管之批核而發出，資訊科技科不會處理不合規格的申請。
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Please complete and submit this form to your supervisor/responsible officer(s) for endorsement in
                  accordance with your Division’s internal procedure before delivery or by fax to Information Technology
                  Division. The fax number is 2803 2948. For the Function/Role-based E-mail accounts, with a view to
                  facilitating the communication with the public or appearing in publication, those accounts must be
                  granted upon request with justification and Division Head’s approval.
                </td>
              </tr>
              <tr>
                <td><br></td>
              </tr>
              <tr>
                <td class="align-top">9.</td>
                <td>
                  一般帳戶新增及更改的申請，將會在收到申請表當日起十個工作天後以電郵方式知會該申請人的直轄主管是否已接納及處理有關要求。
                </td>
              </tr>
              <tr>
                <td></td>
                <td>For typical account creation and amendment, we will process your request within 10 working days
                  since this signed form is received. Our E-mail confirmation will be sent to the applicant’s immediate
                  supervisor to indicate whether we have accepted and completed your request.
                </td>
              </tr>
              <tr>
                <td><br></td>
              </tr>
              <tr>
                <td class="align-top">10.</td>
                <td>
                  有關已授權之科電郵管理員須自行於電郵系統其科/處發送名單中新增、移除以及管理有關科使用者的帳戶群組，以方便有關使用者操作某些系統的協同功能。
                </td>
              </tr>
              <tr>
                <td></td>
                <td>Authorized Division’s E-mail Administrators shall add, remove and manage their own Divisions’ users
                  to respective E-mail distribution lists (i.e. user groups) for their convenience in using some
                  collaborative functions at the E-mail system.
                </td>
              </tr>
              <tr>
                <td><br></td>
              </tr>
              <tr>
                <td class="align-top">11.</td>
                <td>
                  如對電郵服務有任何疑問，請參照東華三院相關守則。
                </td>
              </tr>
              <tr>
                <td></td>
                <td>For details about our email service, please refer to TWGHs Regulations.</td>
              </tr>
              <tr>
                <td colspan="2">&nbsp;</td>
              </tr>
              <tr>
                <td colspan="2"><span class="text-danger">*</span> 必須填寫 Please fill in.</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      mode: 'create',
      isCopy: false,
      mounted: false,
      form: {
        honorific_title: '',
        first_name: null,
        middle_name: null,
        last_name: null,
        staff_number: null,
        centre_id: '',
        cost_centre_code: null,
        contact_person: null,
        contact_number: null,
        account_category: '',
        request_for: '',
        request_email_1: null,
        request_email_2: null,
        request_message: null,
        reason_type: '',
        reason: '',
        note: ''
      },

      errors: {},
      loading: false,
      finish: false,

      profile: this.$cookies.get('profile'),

      centers: [],

      accountCategories: [
        {
          key: '行政總監/科主任',
          value: 1
        },
        {
          key: '服務單位主任',
          value: 2
        },
        {
          key: '學校校長',
          value: 3
        },
        {
          key: '部門主管',
          value: 4
        },
        {
          key: '行政人員',
          value: 5
        },
        {
          key: '總部行政秘書',
          value: 6
        },
        {
          key: '一般使用者帳戶',
          value: 7
        },
        {
          key: '其他 (功能職務帳戶)',
          value: 8
        }
      ],
      requestTypes: [
        {
          key: '新增使用者帳戶',
          value: 1
        },
        {
          key: '新增功能職務帳戶',
          value: 2
        },
        {
          key: '更改帳戶資料',
          value: 3
        },
        {
          key: '刪除帳戶',
          value: 4
        },
        {
          key: '其他',
          value: 5
        }
      ],
      reasonTypes: [
        {
          key: '入職',
          value: 1
        },
        {
          key: '轉職',
          value: 2
        },
        {
          key: '離職',
          value: 3
        }
      ]
    }
  },
  watch: {
    data(val) {
      this.init()
    },
    profile(val) {
      this.init()
    }
  },
  created() {
  },
  async mounted() {
    window.tailwind.svgLoader()

    if (this.profile == null) {
      const response = await this.getProfile()
      await this.$cookies.set('profile', response.profile)
      this.profile = response.profile
    }

    await this.loadCenters()
    await this.init()
  },
  methods: {
    async init() {
      this.mounted = true

      if (this.data != null) {
        this.form = this.data
        this.form.honorific_title = this.form.honorific_title == null ? '' : this.form.honorific_title
        this.form.account_category = this.form.account_category == null ? '' : this.form.account_category
        this.form.reason_type = this.form.reason_type == null ? '' : this.form.reason_type
        this.form.request_for = this.form.request_for == null ? '' : this.form.request_for
        this.mode = 'update'

        if (this.$route.query.copy != null && this.$route.query.copy === 'true') {
          this.$emit('isCopy', true)
          this.mode = 'create'
        }
      } else if (this.data == null && this.profile != null && this.mode === 'create') {
        this.form.contact_person = this.profile.full_name
        this.form.contact_number = this.profile.contact_number
      }
    },
    async loadCenters() {
      this.centers = await this.getCenters()
    },
    onSelectCenter(event) {
      const center = this.getObjectValue(this.centers, 'centre_id', event.target.value)
      this.form.cost_centre_code = center.cost_centre_code
    },
    async onSave(isDraft) {
      this.loading = true
      this.errors = {}

      const form = JSON.parse(JSON.stringify(this.form))
      if (form.request_email_1 != null && form.request_email_1 !== '') {
        form.request_email_1 += '@tungwah.org.hk'
      }
      if (form.request_email_2 != null && form.request_email_2 !== '') {
        form.request_email_2 += '@tungwah.org.hk'
      }

      let response

      if (this.mode === 'create') {
        response = await this.createWorkflow('email', isDraft, form)
      } else {
        response = await this.updateWorkflow('email', this.data.application_id, form)
      }

      if (response.body && 'errors' in response.body) {
        this.errors = response.body.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? (!isDraft ? '已成功遞交申請表' : '已成功儲存申請表') : '已成功更改申請表',
          message: '請稍後，將會轉至申請表頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          setTimeout(() => {
            this.$router.push('/workflows?type=email')
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? (!isDraft ? '未能遞交申請表' : '未能儲存申請表') : '未能更改申請表',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    }
  }
}
</script>

<style scoped lang="scss">
#contact-form {
  .btn-remove {
    height: 38px;
  }
}
</style>
