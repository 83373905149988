<template>
  <div id="faq-type-form">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">

          <div class="mt-3">
            <label for="site_id" class="form-label">分站</label>
            <div id="site_id" class="w-full font-bold" v-if="site">{{ site.name }}</div>
            <div v-if="'site_id' in errors" class="text-danger mt-2" v-html="errors.site_id"></div>
          </div>

          <div class="mt-3">
            <label for="parent_id" class="form-label">父層類別</label>
            <select id="parent_id" v-model="form.parent_id" class="form-select" aria-label="請選擇父層類別">
              <option value="0">--- 請選擇 ---</option>
              <option v-for="(item, key) in allTypes" :key="key" :value="item.type_id">{{ item.name }}</option>
            </select>
            <div v-if="'parent_id' in errors" class="text-danger mt-2" v-html="errors.parent_id"></div>
          </div>

          <div class="mt-3">
            <label for="name" class="form-label"><span class="text-danger">*</span> 類別名稱</label>
            <input v-model="form.name" id="name" type="text" class="form-control w-full" placeholder="請輸入類別名稱">
            <div v-if="'name' in errors" class="text-danger mt-2" v-html="errors.name"></div>
          </div>

          <div class="mt-3">
            <label for="description" class="form-label">簡介</label>
            <input v-model="form.description" id="description" type="text" class="form-control w-full" placeholder="請輸入簡介">
            <div v-if="'description' in errors" class="text-danger mt-2" v-html="errors.description"></div>
          </div>

          <div class="mt-3">
            <label for="display_order" class="form-label">顯示次序</label>
            <input v-model="form.display_order" id="display_order" type="number" class="form-control w-full" placeholder="請輸入顯示次序">
            <div v-if="'display_order' in errors" class="text-danger mt-2" v-html="errors.display_order"></div>
          </div>

          <div class="mt-3">
            <label for="is_effective">公開</label>
            <div class="form-switch mt-2">
              <input id="is_effective" v-model="form.is_effective" type="checkbox" class="form-check-input">
            </div>

            <div v-if="'is_effective' in errors" class="text-danger mt-2" v-html="errors.is_effective"></div>
          </div>

          <div class="flex justify-end align-items-center mt-5">
            <router-link v-if="!loading && !finish" to="/management/faq-types" type="button" class="btn btn-outline-secondary w-24 mr-1">取消</router-link>
            <button v-if="!loading && !finish" type="button" class="btn btn-primary w-24" @click="onSave">儲存</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>

      <div v-if="mode === 'update' && data.children.length > 0" class="intro-y col-span-12 lg:col-span-6">
        <h3 class="intro-y text-lg font-medium">
          子層類別
        </h3>

        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-report -mt-2">
            <thead>
            <tr>
              <th class="whitespace-nowrap">類別名稱</th>
              <th class="text-center whitespace-nowrap">公開</th>
              <th v-if="checkPermission(permissions, 'site.knowledge_base.edit') || checkPermission(permissions, 'site.knowledge_base.delete')" class="text-center whitespace-nowrap">動作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, key) in form.children" :key="key" class="intro-x">
              <td>
                <div class="font-medium whitespace-nowrap">{{ item.name }}</div>
                <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ item.description }}</div>
              </td>
              <td class="w-40">
                <div v-if="item.is_effective" class="flex items-center justify-center text-success">
                  <font-awesome-icon icon="fa-regular fa-circle-check" class="fa-2x" />
                </div>
                <div v-else class="flex items-center justify-center text-danger">
                  <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-2x" />
                </div>
              </td>
              <td class="table-report__action w-56" v-if="checkPermission(permissions, 'site.knowledge_base.edit') || checkPermission(permissions, 'site.knowledge_base.delete')">
                <div class="flex justify-center items-center">
                  <router-link :to="`/management/update-faq-type/${item.type_id}`" class="flex items-center mr-3">
                    <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                    &nbsp;更改 </router-link>
                  <button class="flex items-center text-danger" @click="openDeleteModal(item.type_id, key)">
                    <font-awesome-icon icon="fa-regular fa-trash-can" />
                    &nbsp;刪除 </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <!-- BEGIN: Delete Confirmation Modal -->
        <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body p-0">
                <div class="p-5 text-center">
                  <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
                  <div class="text-3xl mt-5">你確定嗎？</div>
                  <div class="text-slate-500 mt-2">
                    你是否要刪除此類別？
                    <br>
                    這動作不能還原。
                  </div>
                </div>
                <div class="px-5 pb-8 text-center">
                  <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                  <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Delete Confirmation Modal -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['site', 'permissions', 'data'],
  data() {
    return {
      mode: 'create',
      sites: [],
      allTypes: [],
      form: {
        parent_id: 0,
        name: null,
        description: null,
        display_order: 0,
        is_effective: false
      },
      errors: {},
      loading: false,
      finish: false,

      deleteId: null,
      deleteKey: null
    }
  },
  watch: {
    data(val) {
      if (val != null) {
        this.form = this.data
        this.mode = 'update'
        this.getTypes()
      }
    }
  },
  async mounted() {
    window.tailwind.svgLoader()
    await this.loadSites()
    await this.getTypes()
  },
  methods: {
    async loadSites() {
      this.sites = await this.getSites()
    },
    async getTypes() {
      const types = await this.getSiteFaqTypes(this.site.site_id)

      if (this.mode === 'update') {
        this.allTypes = types.filter(type => type.type_id !== this.data.type_id)
      } else {
        this.allTypes = types
      }
    },
    onSave: async function() {
      this.loading = true
      this.errors = {}

      let response
      const form = JSON.parse(JSON.stringify(this.form))

      // eslint-disable-next-line eqeqeq
      if (this.form.parent_id == 0) {
        delete form.parent_id
      }

      if (this.mode === 'create') {
        response = await this.createSiteFaqType(this.site.site_id, form)
      } else {
        response = await this.updateSiteFaqType(this.site.site_id, this.data.type_id, form)
      }

      if ('errors' in response.data) {
        this.errors = response.data.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? '已成功新增類別' : '已成功更新類別',
          text: `請稍後，將會轉至${this.site.name}的FAQ類別頁面`,
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          setTimeout(() => {
            this.$router.push('/management/faq-types')
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? '未能新增類別' : '未能更新類別',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    },
    openDeleteModal(id, key) {
      this.deleteId = id
      this.deleteKey = key

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteSiteFaqType(this.site.site_id, this.deleteId)

      let option = {
        title: '成功',
        message: '類別已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        this.$delete(this.form.children, this.deleteKey)
      } else {
        option = {
          title: '未能刪除類別',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style scoped lang="scss"></style>
