<template>
  <div id="update-data-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      更改部/組/服務單位
    </h2>
    <center-form :data="data"></center-form>
  </div>
</template>

<script>
export default {
  props: ['auth'],
  data() {
    return {
      data: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.loadData()
      }
    }
  },
  mounted() {
    if (!this.auth) {
      return
    }

    this.loadData()
  },
  methods: {
    async loadData() {
      this.data = await this.getCenter(this.$route.params.centerId)
    }
  }
}
</script>

<style lang="scss" scoped></style>
