<template>
  <div id="announcement-types-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      最新消息類別 (共{{ allAnnouncementTypes.length }}個)
    </h2>

    <div v-if="checkPermission(permissions, 'site.announcement-type.view')">
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
          <router-link to="/management/create-announcement-type" class="btn btn-primary shadow-md mr-2">新增類別</router-link>
          <div class="hidden md:block mx-auto text-slate-500">正在顯示第
            {{ filterAnnouncementTypes.length !== 0 ? parseInt((currentPage - 1) * itemPerPage + 1) : 0 }} 至 {{
              (((currentPage - 1) * itemPerPage + itemPerPage) < filterAnnouncementTypes.length) ? parseInt((currentPage - 1) * itemPerPage + itemPerPage) : filterAnnouncementTypes.length
            }} 個類別
          </div>
          <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
            <div class="w-56 relative text-slate-500">
              <input v-model="search" type="text" class="form-control w-56 box pr-10" placeholder="搜尋">
              <font-awesome-icon icon="fa-solid fa-magnifying-glass"
                                 class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0"/>
            </div>
          </div>
        </div>
        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-report -mt-2">
            <thead>
            <tr>
              <th class="whitespace-nowrap">類別名稱</th>
              <th class="whitespace-nowrap">分站</th>
              <th class="whitespace-nowrap">顯示次序</th>
              <th class="text-center whitespace-nowrap">預設</th>
              <th class="text-center whitespace-nowrap" v-if="checkPermission(permissions, 'site.announcement-type.edit')">公開</th>
              <th class="text-center whitespace-nowrap" v-if="checkPermission(permissions, 'site.announcement-type.edit') || checkPermission(permissions, 'site.announcement-type.delete')">動作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, key) in announcementTypes" :key="key" class="intro-x">
              <td>
                <div class="font-medium whitespace-nowrap">{{ item.name }}</div>
                <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ item.description }}</div>
              </td>
              <td>
                {{ getObjectValue(sites, 'site_id', item.site_id, 'name') }}
              </td>
              <td>
                {{ item.display_order }}
              </td>
              <td class="w-40">
                <div v-if="item.is_default" class="flex items-center justify-center text-success">
                  <font-awesome-icon icon="fa-regular fa-circle-check" class="fa-2x"/>
                </div>
              </td>
              <td class="w-40 text-center" v-if="checkPermission(permissions, 'site.announcement-type.edit')">
                <div class="form-switch">
                  <input v-model="item.is_effective" class="form-check-input" type="checkbox"
                         @change="onChangeStatus(item.site_id, item.type_id, item.is_effective)">
                </div>
              </td>
              <td class="table-report__action w-56" v-if="checkPermission(permissions, 'site.announcement-type.edit') || checkPermission(permissions, 'site.announcement-type.delete')">
                <div class="flex justify-center items-center">
                  <router-link :to="`/management/update-announcement-type/${item.type_id}`"
                               class="flex items-center mr-3" v-if="checkPermission(permissions, 'site.announcement-type.edit')">
                    <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
                    &nbsp;更改
                  </router-link>
                  <button class="flex items-center text-danger" @click="openDeleteModal(item.type_id)" v-if="checkPermission(permissions, 'site.announcement-type.delete')">
                    <font-awesome-icon icon="fa-regular fa-trash-can"/>
                    &nbsp;刪除
                  </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
        <!-- BEGIN: Pagination -->
        <pagination-row :pages="pages" :current-page.sync="currentPage"
                        :item-per-page.sync="itemPerPage"></pagination-row>
        <!-- END: Pagination -->
      </div>

      <!-- BEGIN: Delete Confirmation Modal -->
      <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger"/>
                <div class="text-3xl mt-5">你確定嗎？</div>
                <div class="text-slate-500 mt-2">
                  你是否要刪除此類別？
                  <br>
                  這動作不能還原。
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,
      sites: [],
      allAnnouncementTypes: [],
      filterAnnouncementTypes: [],
      announcementTypes: [],
      itemPerPage: 10,
      currentPage: 0,
      pages: 0,
      search: null,
      deleteId: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentPage(val) {
      this.updateList()
    },
    itemPerPage(val) {
      this.pages = Math.ceil(this.filterAnnouncementTypes.length / val)
      if (this.currentPage === 1) {
        this.updateList()
      }

      this.currentPage = 1
    },
    search(val) {
      if (val == null || val === '') {
        this.filterAnnouncementTypes = this.allAnnouncementTypes
      } else {
        this.filterAnnouncementTypes = this.allAnnouncementTypes.filter(o => ((o.name != null && o.name.includes(val)) || (o.description != null && o.description.includes(val))))
      }

      this.pages = Math.ceil(this.filterAnnouncementTypes.length / this.itemPerPage)
      this.currentPage = 1
      this.updateList()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      await this.loadSites()

      if (this.currentSite) {
        await this.loadAnnouncementTypes()
        this.mounted = true
      }
    },
    async loadSites() {
      this.sites = await this.getSites()
    },
    async loadAnnouncementTypes() {
      this.filterAnnouncementTypes = this.allAnnouncementTypes = await this.getSiteAnnouncementTypes(this.currentSite.site_id, 'ALL')
      this.pages = Math.ceil(this.filterAnnouncementTypes.length / this.itemPerPage)
      this.currentPage = 1
    },
    updateList() {
      this.announcementTypes = this.filterAnnouncementTypes.slice((this.currentPage - 1) * this.itemPerPage, (this.currentPage - 1) * this.itemPerPage + this.itemPerPage)
    },
    async onChangeStatus(siteId, typeId, status) {
      const response = await this.changeSiteAnnouncementType(siteId, typeId, status ? 'enable' : 'disable')

      let option = {
        title: '成功',
        message: '已更改類別狀態',
        type: 'success'
      }

      if (response.code === 0) {
        console.log()
      } else {
        option = {
          title: '未能更改類別狀態',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    },
    openDeleteModal(id) {
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteSiteAnnouncementType(this.currentSite.site_id, this.deleteId)

      let option = {
        title: '成功',
        message: '類別已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        await this.loadAnnouncementTypes()
        this.updateList()
      } else {
        option = {
          title: '未能刪除類別',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped></style>
