<template>
  <button v-if="disabled == null || !disabled" type="button" class="flex align-items-center" @click="changeOrder">{{ title }} <div class="flex flex-column ml-1" ><font-awesome-icon v-if="(orderBy === column && order === 'ASC') || order === ''" icon="fa-solid fa-caret-up" /> <font-awesome-icon v-if="(orderBy === column && order === 'DESC') || order === ''" icon="fa-solid fa-caret-down" /></div></button>
  <span v-else> {{ title }}</span>
</template>

<script>
export default {
  props: ['title', 'column', 'orderBy', 'order', 'disabled'],
  methods: {
    changeOrder() {
      this.$emit('update:orderBy', this.column)

      if (this.order === '') {
        this.$emit('update:order', 'ASC')
      } else if (this.order === 'ASC') {
        this.$emit('update:order', 'DESC')
      } else {
        this.$emit('update:order', '')
      }
    }
  }
}
</script>

<style scoped>
button {
  .svg-inline--fa {
    height: 10px;
  }
}
</style>
