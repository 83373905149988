import { render, staticRenderFns } from "./UpdateResourceBookingPage.vue?vue&type=template&id=6f8ea6a8&scoped=true&"
import script from "./UpdateResourceBookingPage.vue?vue&type=script&lang=js&"
export * from "./UpdateResourceBookingPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f8ea6a8",
  null
  
)

export default component.exports