<template>
  <div id="resource-bookings-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      會議室及資源預借 - {{ option === 'my' ? '我的預約' : '管理預約' }}
    </h2>

    <div>
      <ul class="nav nav-tabs mt-5" role="tablist">
        <li v-for="(item, key) in options" :key="key" :id="`${item.value}-tab`" class="nav-item flex-1"
            role="presentation">
          <template v-if="item.value === 'my' || checkPermission(permissions, `site.resource.manage`)">
            <button class="nav-link w-full py-2" :class="{'active': option === item.value}" type="button" @click="option = item.value"> {{ item.key }}
            </button>
          </template>
        </li>
      </ul>

      <div class="tab-content border-l border-r border-b mb-5">
        <div class="tab-pane leading-relaxed p-5" :class="{'active': option === 'my'}">
          <div v-if="option === 'my'" class="flex">
            <div class="mr-2">
              <router-link  to="/create-resource-booking" class="btn btn-primary shadow-md ">預約</router-link>
            </div>

            <select id="resource_type" v-model="resourceType" class="form-select w-44 mr-2" aria-label="請選擇類別">
              <option value="">物品及會議室</option>
              <option v-for="(item, key) in resourceTypes" :key="key" :value="item.value">{{ item.key }}</option>
            </select>

            <select id="site_id" v-model="resourceId" class="form-select w-44" aria-label="請選擇資源" :disabled="resourceType === ''">
              <option value="">全部</option>
              <option v-for="(item, key) in filterResources" :key="key" :value="item.resource_id">{{ item.name }} ({{ getObjectValue(sites, 'site_id', item.site_id, 'name') }})</option>
            </select>
          </div>

          <FullCalendar class="mt-4" :options="calendarOptions"/>
        </div>

        <div class="tab-pane leading-relaxed p-5" :class="{'active': option === 'manage'}">

          <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center">
            <div class="hidden md:block mx-auto text-slate-500">正在顯示第 {{ resourceBookings.length !== 0 ? from : 0 }}
              至 {{ to ?? 0 }} 個預約
            </div>

            <div class="mt-3 sm:mt-0 flex">
              <button v-if="!downloading && currentSite != null && currentSite.is_roster_site && option === 'manage'" class="btn btn-primary shadow-md mr-2 btn-download" @click="openDownloadModal">下載記錄</button>
              <span v-show="downloading"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
            </div>
          </div>

          <!-- BEGIN: Data List -->
          <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
            <table class="table table-striped">
              <thead>
              <tr>
                <th v-if="option === 'manage'" class="whitespace-nowrap">
                  <order-button title="員工" column="user_id" :order-by.sync="orderBy" :order.sync="order" :disabled="this.option === 'my'"></order-button>
                </th>
                <th class="whitespace-nowrap">
                  <order-button title="資源" column="resource_id" :order-by.sync="orderBy" :order.sync="order" :disabled="this.option === 'my'"></order-button>
                </th>
                <th class="whitespace-nowrap">
                  <order-button title="借用日期" column="from_time" :order-by.sync="orderBy" :order.sync="order" :disabled="this.option === 'my'"></order-button>
                </th>
                <th class="whitespace-nowrap">
                  <order-button title="歸還日期" column="to_time" :order-by.sync="orderBy" :order.sync="order" :disabled="this.option === 'my'"></order-button>
                </th>
                <th class="text-center whitespace-nowrap">檢視</th>
                <th class="whitespace-nowrap">
                  <order-button title="狀態" column="booking_status" :order-by.sync="orderBy" :order.sync="order" :disabled="this.option === 'my'"></order-button>
                </th>
                <th
                  v-if="checkPermission(permissions, 'site.phone_directory.edit') || checkPermission(permissions, 'site.phone_directory.delete')"
                  class="text-center whitespace-nowrap">動作
                </th>
              </tr>
              </thead>
              <tbody>
              <tr class="" v-for="(item, key) in resourceBookings" :key="key">
                <td v-if="option === 'manage'">{{ item.user ? item.user.full_name : '' }}</td>
                <td>{{ getObjectValue(resources, 'resource_id', item.resource_id, 'name') }}</td>
                <td>{{ item.from_time ?? '-' }}</td>
                <td>{{ item.to_time ?? '-' }}</td>
                <td class="table-report__action text-center">
                  <router-link :to="`/resource-bookings/${item.booking_id}`" class="btn btn-success">
                    <font-awesome-icon icon="fa-regular fa-eye" class="text-white"/>
                  </router-link>
                </td>
                <td>
                  {{ getObjectValue(allStatus, 'value', item.booking_status, 'name') }}
                  <div v-if="item.approval_remark" class="text-pending">{{ item.approval_remark }}</div>
                </td>
                <td class="table-report__action w-120">
                  <div class="flex justify-center items-center">
                    <router-link v-if="option === 'my'" :to="`/update-resource-booking/${item.booking_id}`"
                                 class="flex items-center mr-3">
                      <font-awesome-icon icon="fa-solid fa-pen-to-square"/>
                      &nbsp;更改
                    </router-link>

                    <button v-if="false && option === 'manage' && item.booking_status === 'Pending'" type="button" @click="openModal('accept', item.booking_id)"
                            class="flex items-center mr-3 text-success">
                      <font-awesome-icon icon="fa-regular fa-square-check"/>
                      &nbsp;批准
                    </button>
                    <button v-if="false && option === 'manage' && item.booking_status === 'Pending'" type="button" @click="openModal('reject', item.booking_id)"
                            class="flex items-center mr-3 text-danger">
                      <font-awesome-icon icon="fa-regular fa-circle-xmark"/>
                      &nbsp;拒絕
                    </button>
                    <button v-if="option === 'manage' && item.booking_status === 'Accepted' && $moment().isAfter(item.from_time) && $moment().isBefore(item.to_time)" type="button" @click="openModal('force-return', item.booking_id)"
                            class="flex items-center mr-3 text-pending">
                      <font-awesome-icon icon="fa-solid fa-rotate-left"/>
                      &nbsp;強制歸還
                    </button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!-- END: Data List -->

          <!-- BEGIN: Action Confirmation Modal -->
          <div id="download-modal" class="modal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-body p-0">
                  <div class="p-5 text-center">
                    <div class="text-slate-500 mt-2">
                      請選擇下載紀錄日期<br><br>
                      <date-picker v-model="downloadDate" type="date" placeholder="請選擇下載紀錄日期" input-class="form-control w-full" format="YYYY-MM-DD"></date-picker>
                    </div>
                  </div>
                  <div class="px-5 pb-8 text-center">
                    <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消
                    </button>
                    <button type="button" data-tw-dismiss="modal" class="btn btn-primary w-24 text-white" :disabled="downloadDate == null"
                            @click="onDownload">下載
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Action Confirmation Modal -->

          <!-- BEGIN: Action Confirmation Modal -->
          <div id="action-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-body p-0">
                  <div class="p-5 text-center">
                    <font-awesome-icon v-if="action === 'accept'" icon="fa-regular fa-square-check"
                                       class="fa-3x text-success"/>
                    <font-awesome-icon v-else-if="action === 'reject'" icon="fa-regular fa-circle-xmark"
                                       class="fa-3x text-danger"/>
                    <font-awesome-icon v-else-if="action === 'force-return'" icon="fa-solid fa-rotate-left"
                                       class="fa-3x text-pending"/>
                    <div class="text-3xl mt-5">你確定嗎？</div>
                    <div class="text-slate-500 mt-2">
                      <span v-if="action === 'accept'">你是否要批准此預約？</span>
                      <span v-else-if="action === 'reject'">你是否要拒絕此預約？</span>
                      <span v-else-if="action === 'force-return'">你是否要強制歸還此資源？</span>
                      <br>
                      這動作不能還原。
                    </div>
                  </div>
                  <div class="px-5 pb-8 text-center">
                    <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消
                    </button>
                    <button type="button" class="btn w-24 text-white"
                            :class="{'btn-success': action === 'accept', 'btn-danger': action === 'reject', 'btn-pending': action === 'force-return'}"
                            @click="onConfirm">確定
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: Action Confirmation Modal -->
        </div>
      </div>

      <!-- BEGIN: Pagination -->
      <pagination-row v-if="option === 'manage'" :pages="pages" :current-page.sync="currentPage"
                      :item-per-page.sync="itemPerPage"></pagination-row>
      <!-- END: Pagination -->
    </div>
  </div>
</template>

<script>
import interactionPlugin from '@fullcalendar/interaction'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import FullCalendar from '@fullcalendar/vue'

export default {
  props: ['auth', 'currentSite', 'permissions'],
  components: {
    FullCalendar
  },
  data() {
    return {
      mounted: false,

      resources: [],
      resourceBookings: [],
      filterResources: [],
      sites: [],

      downloading: false,

      option: 'my',
      resourceType: '',
      resourceId: '',
      itemPerPage: 10,
      currentPage: 1,
      pages: 0,
      from: 0,
      to: 0,
      search: '',
      orderBy: '',
      order: '',

      options: [
        { key: '我的預約', value: 'my' },
        { key: '管理預約', value: 'manage' }
      ],

      allStatus: [
        { name: '全部', value: 'ALL' },
        { name: '已批准', value: 'Accepted' },
        { name: '已拒絕', value: 'Rejected' },
        { name: '等待審批', value: 'Pending' }
      ],

      downloadDate: new Date(),

      action: null,
      selectId: null,

      calendarOptions: {
        plugins: [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin],
        initialView: 'dayGridMonth',
        selectable: true,
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,listWeek'
        },
        buttonText: {
          today: '今天',
          month: '月',
          week: '周',
          day: '日',
          list: '表'
        },
        locale: 'zh-HK',
        events: [],
        initialDate: this.$moment(this.$route.query.date).isValid() ? this.$route.query.date : this.$moment().format('YYYY-MM-DD'),
        dateClick: (info) => {
          if (info.dateStr.match(/^\d{4}-\d{2}-\d{2}$/)) {
            this.$router.push(`/create-resource-booking?date=${info.dateStr}`)
          }
        },
        eventClick: (info) => {
          const props = info.event.extendedProps
          this.$router.push(`/resource-bookings/${props.bookingId}`)
        }
      }
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    },
    resourceType() {
      this.resourceId = ''

      const array = []
      for (let i = 0; i < this.resources.length; i++) {
        if (this.resources[i].resource_type === this.resourceType) {
          array.push(this.resources[i])
        }
      }

      this.filterResources = array
      this.filterResourcesBookings()
    },
    resourceId() {
      this.filterResourcesBookings()
    },
    option() {
      this.loadResourceBookings()
    },
    currentPage(val) {
      this.loadResourceBookings(false)
    },
    itemPerPage(val) {
      this.loadResourceBookings()
    },
    order() {
      this.loadResourceBookings()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
    window.tailwind.svgLoader()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadSites()
        await this.loadResources()
        await this.loadResourceBookings()
        this.mounted = true
      }
    },
    async loadSites() {
      this.sites = await this.getSites()
    },
    async loadResources() {
      const response1 = await this.getSiteResources(this.currentSite.site_id, '', '', 99999999, 1)
      const response2 = await this.getSiteAvailableResources(this.currentSite.site_id, '', '', 99999999, 1)
      const resources = [...response1.data, ...response2.data]
      this.resources = [...new Map(resources.map((m) => [m.resource_id, m])).values()]
    },
    async loadResourceBookings(resetCurrentPage = true) {
      let response

      if (this.option === 'my') {
        response = await this.getSiteUserResourceBookings(this.currentSite.site_id, 999999, this.currentPage)
      } else {
        response = await this.getSiteResourceBookings(this.currentSite.site_id, this.itemPerPage, this.currentPage, this.orderBy, this.order)
      }

      this.resourceBookings = response.data
      this.pages = response.meta.page.last_page
      this.from = response.meta.page.from
      this.to = response.meta.page.to

      if (this.option === 'my') {
        this.filterResourcesBookings()
      }

      if (resetCurrentPage) {
        this.currentPage = 1
      }
    },
    filterResourcesBookings() {
      const events = []

      for (let i = 0; i < this.resourceBookings.length; i++) {
        const resource = this.getObjectValue(this.resources, 'resource_id', this.resourceBookings[i].resource_id)
        if (resource != null && (this.resourceType === '' || (this.resourceType === resource.resource_type && this.resourceId === '') || (this.resourceId === resource.resource_id))) {
          const item = {
            title: resource.name,
            start: this.resourceBookings[i].from_time,
            end: this.resourceBookings[i].to_time,
            bookingId: this.resourceBookings[i].booking_id,
            color: 'orange'
          }

          events.push(item)
          this.calendarOptions.events = events
        }
      }
    },
    openModal(action, id) {
      this.action = action
      this.selectId = id

      const el = document.querySelector('#action-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onConfirm() {
      const el = document.querySelector('#action-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      let response

      let option = {
        title: '成功',
        message: '',
        type: 'success'
      }

      switch (this.action) {
        case 'accept':
          response = await this.acceptSiteResourceBooking(this.currentSite.site_id, this.selectId)
          option.message = '已批准預約'
          break
        case 'reject':
          response = await this.rejectSiteResourceBooking(this.currentSite.site_id, this.selectId)
          option.message = '已拒絕預約'
          break
        case 'force-return':
          response = await this.forceReturnSiteResourceBooking(this.currentSite.site_id, this.selectId)
          option.message = '已強制歸還資源'
          break
      }

      if (response.code === 0) {
        this.loadResourceBookings()
      } else {
        option = {
          title: '',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }

        switch (this.action) {
          case 'accept':
            option.title = '未能批准預約'
            break
          case 'reject':
            response = await this.rejectSiteResourceBooking(this.currentSite.site_id, this.selectId)
            option.title = '未能拒絕預約'
            break
          case 'force-return':
            response = await this.forceReturnSiteResourceBooking(this.currentSite.site_id, this.selectId)
            option.title = '未能強制歸還資源'
            break
        }
      }

      this.$notify(option)
    },
    openDownloadModal() {
      const el = document.querySelector('#download-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDownload() {
      this.downloading = true

      const date = this.$moment(this.downloadDate).format('YYYY-MM-DD')
      const response = await this.downloadResourceBookingRoster(this.currentSite.site_id, date)
      const blob = new Blob([response], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `resource_booking_roster (${date}).pdf`
      link.click()
      URL.revokeObjectURL(link.href)
      this.downloading = false
    }
  }
}
</script>

<style lang="scss" scoped>
#resource-bookings-page {
  .tab-content {
    background-color: #FFF;
  }

  .btn-download {
    white-space: nowrap;
  }

  .svg-inline--fa {
    height: 10px;
  }
}
</style>
