<template>
  <div id="users-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      用戶 (共{{ allUsers.length }}名)
    </h2>

    <div v-if="checkPermission(permissions, 'system.user.view')">
      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
          <router-link to="/management/create-user" v-if="checkPermission(permissions, 'system.user.create')" class="btn btn-primary shadow-md mr-2">新增用戶</router-link>
          <div class="hidden md:block mx-auto text-slate-500">正在顯示第 {{ filterUsers.length !== 0 ? parseInt((currentPage - 1) * itemPerPage + 1) : 0 }} 至 {{ (((currentPage - 1) * itemPerPage + itemPerPage) < filterUsers.length) ? parseInt((currentPage - 1) * itemPerPage + itemPerPage) : filterUsers.length }} 名用戶</div>
          <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
            <div class="w-56 relative text-slate-500">
              <input v-model="search" type="text" class="form-control w-56 box pr-10" placeholder="搜尋">
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0" />
            </div>
          </div>
        </div>
        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-report -mt-2">
            <thead>
            <tr>
              <th class="whitespace-nowrap">頭像</th>
              <th class="whitespace-nowrap">用戶名稱</th>
              <th class="whitespace-nowrap">服務單位</th>
              <th class="whitespace-nowrap">職位名稱</th>
              <th class="whitespace-nowrap">主任級或以上</th>
              <th class="text-center whitespace-nowrap">電郵</th>
              <th class="text-center whitespace-nowrap">狀態</th>
              <th v-if="checkPermission(permissions, 'system.user.edit') || checkPermission(permissions, 'system.user.delete')" class="text-center whitespace-nowrap">動作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, key) in users" :key="key" class="intro-x">
              <td class="w-40">
                <div class="flex">
                  <div class="w-10 h-10 image-fit">
                    <img alt="" class="tooltip rounded-full" :src="item.avatar != null && item.avatar !== '' ? item.avatar : '/images/user.png'">
                  </div>
                </div>
              </td>
              <td>
                  <router-link :to="`/users/${item.user_id}`">
                      <div class="font-medium whitespace-nowrap">{{ item.first_name + " " + item.last_name }}</div>
                      <div class="text-slate-500 text-xs whitespace-nowrap mt-0.5">{{ item.username }}</div>
                  </router-link>
              </td>
                <td class="">
                    {{ item.position_id ? getObjectValue(centers, 'centre_id', item.centre_id, 'abbrev') : '-' }}
                </td>
                <td class="">
                    {{ item.position_id ? getObjectValue(positions, 'position_id', item.position_id, 'name') : '-' }}
                </td>
                <td class="">
                    {{ item.is_high_level ? '是' : '否' }}
                </td>
              <td class="text-center">{{ item.email }}</td>
              <td class="w-40">
                <div v-if="item.deleted_at != null" class="flex items-center justify-center text-danger">
                  <font-awesome-icon icon="fa-solid fa-ban" />
                  <div class="ml-2">已刪除</div>
                </div>
                <div v-else-if="item.email_verified_at == null" class="flex items-center justify-center text-pending">
                  <font-awesome-icon icon="fa-regular fa-square-check" />
                  <div class="ml-2">未認證</div>
                </div>
                <div v-else class="flex items-center justify-center text-success">
                  <font-awesome-icon icon="fa-regular fa-square-check" />
                  <div class="ml-2">已認證</div>
                </div>
              </td>
              <td class="table-report__action w-56" v-if="checkPermission(permissions, 'system.user.edit') || checkPermission(permissions, 'system.user.delete')">
                <div class="flex justify-center items-center">
                  <router-link :to="`/management/update-user/${item.user_id}`" v-if="checkPermission(permissions, 'system.user.edit')" class="flex items-center mr-3">
                    <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                    &nbsp;更改 </router-link>
                  <button class="flex items-center text-danger" v-if="checkPermission(permissions, 'system.user.delete')" @click="openDeleteModal(item.user_id)">
                    <font-awesome-icon icon="fa-regular fa-trash-can" />
                    &nbsp;刪除 </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
        <!-- BEGIN: Pagination -->
        <pagination-row :pages="pages" :current-page.sync="currentPage" :item-per-page.sync="itemPerPage"></pagination-row>
        <!-- END: Pagination -->
      </div>

      <!-- BEGIN: Delete Confirmation Modal -->
      <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
                <div class="text-3xl mt-5">你確定嗎？</div>
                <div class="text-slate-500 mt-2">
                  你是否要刪除此用戶？
                  <br>
                  這動作不能還原。
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions'],
  data() {
    return {
      mounted: false,

      centers: [],
      positions: [],

      allUsers: [],
      filterUsers: [],
      users: [],

      itemPerPage: 10,
      currentPage: 0,
      pages: 0,
      search: null,

      deleteId: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentPage(val) {
      this.updateList()
    },
    itemPerPage(val) {
      this.pages = Math.ceil(this.filterUsers.length / val)
      if (this.currentPage === 1) {
        this.updateList()
      }

      this.currentPage = 1
    },
    search(val) {
      if (val == null || val === '') {
        this.filterUsers = this.allUsers
      } else {
        this.filterUsers = this.allUsers.filter(o => (o.username != null && o.username.includes(val)) || (o.email != null && o.email.includes(val)) || (o.first_name != null && o.first_name.includes(val)) || (o.last_name != null && o.last_name.includes(val)))
      }

      this.pages = Math.ceil(this.filterUsers.length / this.itemPerPage)
      this.currentPage = 1
      this.updateList()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      await this.loadCenters()
      await this.loadPositions()
      await this.loadUsers()

      setTimeout(() => {
        this.mounted = true
      }, 500)
    },
    async loadCenters() {
      this.centers = await this.getCenters()
    },
    async loadPositions() {
      this.positions = await this.getPositions()
    },
    async loadUsers() {
      this.filterUsers = this.allUsers = await this.getUsers()
      this.pages = Math.ceil(this.filterUsers.length / this.itemPerPage)
      this.currentPage = 1
    },
    updateList() {
      this.users = this.filterUsers.slice((this.currentPage - 1) * this.itemPerPage, (this.currentPage - 1) * this.itemPerPage + this.itemPerPage)
    },
    openDeleteModal(id) {
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteUser(this.deleteId)

      let option = {
        title: '成功',
        message: '用戶已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        await this.loadUsers()
        this.updateList()
      } else {
        option = {
          title: '未能刪除用戶',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped></style>
