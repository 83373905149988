<template>
  <div id="sites-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      系統參數設定
    </h2>

    <div class="grid grid-cols-12 gap-6" v-if="checkPermission(permissions, 'system.config.workflow')">
      <!-- BEGIN: Config Menu -->
      <div class="intro-y col-span-12 lg:col-span-4 xl:col-span-3">
        <div class="box mt-5">
          <div class="px-4 pb-3 pt-5">
            <button type="button" @click="currentConfig = 'workflow'" class="flex rounded-lg items-center px-4 py-2 bg-primary text-white font-medium w-full">
              <font-awesome-icon icon="fa-solid fa-table-columns" class="w-4 h-4 mr-2" />
              <div class="flex-1 truncate text-left">申請表參數</div>
            </button>
          </div>
        </div>
      </div>
      <!-- END: Config Menu -->
      <!-- BEGIN: Config Content -->
      <div class="intro-y col-span-12 lg:col-span-8 xl:col-span-9">
        <div v-if="currentConfig == 'workflow'" class="intro-y box lg:mt-5">
          <div class="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
            <h2 class="font-medium text-base mr-auto">
              申請表參數
            </h2>
          </div>
          <div class="accordion accordion-boxed p-5">
            <div class="accordion-item">
              <div class="accordion-header flex justify-between align-items-center">
                <span>第一階段核準權限分站</span>

                <select v-model="form.approver_site_id" class="form-select w-auto" aria-label="請選擇分站">
                  <option value="">請選擇分站</option>
                  <option v-for="(item, key) in sites" :key="key" :value="item.site_id">{{ item.name }}</option>
                </select>
              </div>

              <div v-if="'approver_site_id' in errors" class="text-danger mt-2" v-html="errors.approver_site_id"></div>
            </div>
            <div class="accordion-item">
              <div class="accordion-header flex justify-between align-items-center">
                <span>最終階段核準權限分站</span>

                <select v-model="form.high_level_approver_site_id" class="form-select w-auto" aria-label="請選擇分站">
                  <option value="">請選擇分站</option>
                  <option v-for="(item, key) in sites" :key="key" :value="item.site_id">{{ item.name }}</option>
                </select>
              </div>

              <div v-if="'high_level_approver_site_id' in errors" class="text-danger mt-2" v-html="errors.high_level_approver_site_id"></div>
            </div>

            <div>
              <button class="btn btn-primary mt-4" type="button" @click="onSave">儲存</button>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Config Content -->
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions'],
  data() {
    return {
      mounted: false,
      sites: [],
      configs: [],
      currentConfig: 'workflow',

      form: {
        approver_site_id: '',
        high_level_approver_site_id: ''
      },

      errors: {}
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentConfig(val) {
      this.loadConfigs()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      await this.loadSites()
      await this.loadConfigs()

      setTimeout(() => {
        this.mounted = true
      }, 500)
    },
    async loadSites() {
      this.sites = await this.getSites()
    },
    async loadConfigs() {
      const response = await this.getSystemConfig(this.currentConfig)

      for (const key in this.form) {
        if (key in response) {
          this.$set(this.form, key, response[key])
        }
      }
    },
    async onSave() {
      const response = await this.saveSystemConfig(this.currentConfig, this.form)

      if ('errors' in response.data) {
        this.errors = response.data.errors
        this.loading = false
      } else {
        let option = {
          title: '成功',
          message: '已儲存參數',
          type: 'success'
        }

        if (response.code !== 0) {
          option = {
            title: '未能儲存參數',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#site-page {
  .table-report__action {
    width: 18rem;
  }
}
</style>
