<template>
  <div id="performance-report-section-form">
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">

          <div class="mt-3">
            <label for="table_name_zh" class="form-label">中文名稱</label>
            <input v-model="form.table_name_zh" id="table_name_zh" type="text" class="form-control w-full" placeholder="請輸入中文名稱">
            <div v-if="'table_name_zh' in errors" class="text-danger mt-2" v-html="errors.table_name_zh"></div>
          </div>

          <div class="mt-3">
            <label for="table_name_en" class="form-label">英文名稱</label>
            <input v-model="form.table_name_en" id="table_name_en" type="text" class="form-control w-full" placeholder="請輸入英文名稱">
            <div v-if="'table_name_en' in errors" class="text-danger mt-2" v-html="errors.table_name_en"></div>
          </div>

          <div class="mt-3">
            <label for="table_index" class="form-label">表格ID</label>
            <input v-model="form.table_index" id="table_index" type="text" class="form-control w-full" placeholder="請輸入表格ID">
            <div v-if="'table_index' in errors" class="text-danger mt-2" v-html="errors.table_index"></div>
          </div>

          <div class="form-switch mt-3">
            <label>是否計算總數</label>
            <div class="mt-1">
              <input v-model="form.is_count_total" type="checkbox" class="form-check-input">
            </div>
          </div>

          <div class="mt-3">
            <label for="footer" class="form-label">備註</label>
            <el-tiptap v-model="form.footer" :extensions="extensions" />
            <div v-if="'footer' in errors" class="text-danger mt-2" v-html="errors.footer"></div>
          </div>

          <div class="mt-3">
            <label for="display_order" class="form-label"><span class="text-danger">*</span> 顯示次序</label>
            <input v-model="form.display_order" id="display_order" type="number" class="form-control w-full" placeholder="請輸入顯示次序">
            <div v-if="'display_order' in errors" class="text-danger mt-2" v-html="errors.display_order"></div>
          </div>

          <div class="flex justify-end align-items-center mt-5">
            <router-link v-if="!loading && !finish" :to="`/management/performance-reports/${this.$route.params.sectionId}`" type="button" class="btn btn-outline-secondary w-24 mr-1">取消</router-link>
            <button v-if="!loading && !finish" type="button" class="btn btn-primary w-24" @click="onSave">儲存</button>
            <span v-show="loading && !finish"><i data-loading-icon="oval" class="w-8 h-8"></i></span>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import {
  // all extensions
  Doc,
  Text,
  Paragraph,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Link,
  Image,
  Blockquote,
  ListItem,
  BulletList, // use with ListItem
  OrderedList, // use with ListItem
  TodoItem,
  TodoList, // use with TodoItem
  TextAlign,
  Indent,
  HorizontalRule,
  HardBreak,
  History,
  // LineHeight,
  // Iframe,
  // CodeBlock,
  // TrailingNode,
  Table, // use with TableHeader, TableCell, TableRow
  TableHeader,
  TableCell,
  TableRow,
  // FormatClear,
  TextColor,
  TextHighlight,
  FontType,
  FontSize,
  // Preview,
  // Print,
  Fullscreen,
  CodeView
  // SelectAll,
} from 'element-tiptap'

import InsertYoutube from '../extension/insertYoutube/index'
import InsertFile from '../extension/insertFile/index'

import codemirror from 'codemirror'
import 'codemirror/lib/codemirror.css' // import base style
import 'codemirror/mode/xml/xml.js' // language
import 'codemirror/addon/selection/active-line.js' // require active-line.js
import 'codemirror/addon/edit/closetag.js' // autoCloseTags
export default {
  props: ['data'],
  data() {
    return {
      mode: 'create',
      sites: [],
      form: {
        table_name_zh: null,
        table_name_en: null,
        table_index: null,
        footer: null,
        is_count_total: false,
        display_order: null
      },
      errors: {},
      loading: false,
      finish: false,

      extensions: [
        new Doc(),
        new Text(),
        new Paragraph(),
        new Heading({ level: 6 }),
        new Bold({ bubble: true }),
        new Underline({ bubble: true }),
        new Italic({ bubble: true }),
        new Strike({ bubble: true }),
        new Link({ bubble: true }),
        new Image(),
        new InsertFile(this.auth, this.currentSite, this.permissions),
        new InsertYoutube(),
        new Blockquote(),
        new TextAlign(),
        new ListItem(),
        new BulletList({ bubble: true }),
        new OrderedList({ bubble: true }),
        new TodoItem(),
        new TodoList(),
        new Indent(),
        new HardBreak(),
        new HorizontalRule({ bubble: true }),
        new Fullscreen(),
        new CodeView({
          codemirror,
          codemirrorOptions: {
            styleActiveLine: true,
            autoCloseTags: true
          }
        }),
        new History(),
        new Table(),
        new TableHeader(),
        new TableCell(),
        new TableRow(),
        new TextColor(),
        new TextHighlight(),
        new FontType({
          fontTypes: {
            Arial: 'Arial',
            'Arial Black': 'Arial Black',
            Impact: 'Impact',
            Tahoma: 'Tahoma',
            'Times New Roman': 'Times New Roman',
            monospace: 'monospace',
            新細明體: '新細明體',
            細明體: '細明體',
            標楷體: '標楷體',
            微軟正黑體: '微軟正黑體',
            微軟雅黑體: '微軟雅黑體'
          }
        }),
        new FontSize()
      ],
      fontList: ['新細明體', '細明體', '標楷體', '微軟正黑體', '微軟雅黑體', 'serif', 'sans-serif', 'monospace', 'Times New Roman', 'Arial', 'Helvetica', 'Calibri']
    }
  },
  watch: {
    data(val) {
      if (val != null) {
        this.form = this.data
        this.mode = 'update'
      }
    }
  },
  mounted() {
    window.tailwind.svgLoader()

    if (this.data != null) {
      this.form = this.data
      this.mode = 'update'
    }
  },
  methods: {
    onSave: async function() {
      this.loading = true
      this.errors = {}

      let response

      if (this.mode === 'create') {
        response = await this.createPerformanceReportSectionTable(this.$route.params.sectionId, this.form)
      } else {
        response = await this.updatePerformanceReportSectionTable(this.$route.params.sectionId, this.data.table_id, this.form)
      }

      if ('errors' in response.data) {
        this.errors = response.data.errors
        this.loading = false
      } else {
        let option = {
          title: (this.mode === 'create') ? '已成功新增表格' : '已成功更新表格',
          message: '請稍後，將會轉至執行報告頁面',
          type: 'success'
        }

        if (response.code === 0) {
          this.finish = true

          setTimeout(() => {
            this.$router.push(`/management/performance-reports/${this.$route.params.sectionId}`)
          }, 1000)
        } else {
          this.loading = false
          option = {
            title: (this.mode === 'create') ? '未能新增表格' : '未能更新表格',
            message: response.data.message ?? '系統錯誤，請稍後再試',
            type: 'error'
          }
        }

        this.$notify(option)
      }
    }
  }
}
</script>

<style scoped lang="scss">
#performance-report-section-form {
  ::v-deep {
    .ql-editor {
      height: 100%;
      flex: 1;
      overflow-y: auto;
      width: 100%;
    }
  }
}
</style>
