<template>
  <div id="update-data-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      更改用戶
    </h2>

    <user-form v-if="checkPermission(permissions, 'system.user.edit')" :data="data"></user-form>
    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions'],
  data() {
    return {
      mounted: false,
      data: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    permissions() {
      this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      await this.loadData()

      setTimeout(() => {
        this.mounted = true
      }, 500)
    },
    async loadData() {
      this.data = await this.getUser(this.$route.params.userId)
    }
  }
}
</script>

<style lang="scss" scoped></style>
