<template>
  <div id="mobile-top-bar">
    <div class="menu-btn-container">
      <a href="" class="logo -intro-x md:flex flex justify-center" @click.prevent="onGoHome">
        <img alt="" class="logo__image" src="/images/logo_small.png">
      </a>

      <div class="right">

        <!-- BEGIN: Search -->
        <div class="search-container -intro-x flex align-items-center mr-4">
          <div class="search">
            <input type="text" class="search__input form-control border-transparent" placeholder="搜尋..." v-model="search" @keyup.enter="onSearch">
            <button class="search__icon dark:text-slate-500" type="button" @click="onSearch"><font-awesome-icon icon="fa-solid fa-magnifying-glass" /></button>
          </div>
        </div>
        <!-- END: Search -->

        <button class="btn btn-light" @click="toggleMobileMenu"><font-awesome-icon icon="fa-solid fa-bars" class="fa-xl" /></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['currentSite'],
  data() {
    return {
      search: null,
      lastSearch: null
    }
  },
  mounted() {
    this.sideMenu()
  },
  methods: {
    async onGoHome() {
      if (this.currentSite.site_id === 1) {
        if (this.$route.path !== '/home') {
          this.$router.push('/home')
        }
      } else {
        await this.$root.$children[0].updateCurrentSiteData(1)
        this.$router.go('/home')
      }
    },
    toggleMobileMenu() {
      this.$emit('toggle-mobile-menu')
    },
    onSearch() {
      if (this.search == null || this.search === '') {
        return
      }

      if (this.lastSearch !== this.search) {
        this.$router.push({
          path: '/result',
          query: { search: this.search }
        })
      }

      this.lastSearch = this.search
    }
  }
}
</script>

<style scoped lang="scss">
#mobile-top-bar {
  .menu-btn-container {
    display: none;
    width: 44.38px;
    z-index: 50;
    margin-top: -1rem;
    margin-left: 4px;
    margin-right: 4px;
    padding-top: 7rem;
    position: relative;

    .logo {
      display: none;
      height: 36px;
      padding: 4px;
    }

    .right {
      .search-container {
        display: none;
      }

      .btn {
        position: fixed;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  #mobile-top-bar {
    display: block;
    position: fixed;
    z-index: 55;
    top: 0;
    width: 100%;
    margin-left: -2rem;
    background-color: white;
    padding: 10px;

    .menu-btn-container {
      width: unset;
      z-index: unset;
      margin-top: unset;
      margin-left: unset;
      margin-right: unset;
      padding-top: unset;
      position: unset;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        display: flex;
      }

      .right {
        display: flex;

        .btn {
          position: unset;
        }

        .search-container {
          display: flex;
        }
      }
    }
  }
}

@media screen and (max-width: 639px) {
  #mobile-top-bar {
    margin-left: -0.75rem;
  }
}

@media screen and (max-width: 390px) {
  #mobile-top-bar {
    .menu-btn-container {
      .right {
        .search-container {
          input {
            width: 40vw;
          }
        }
      }
    }
  }
}
</style>
