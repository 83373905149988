<template>
  <div id="update-data-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      更改組別
    </h2>

    <user-group-form v-if="checkPermission(permissions, 'site.user-group.edit')" :data="data"
                     :current-site="currentSite"></user-group-form>
    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions', 'currentSite'],
  data() {
    return {
      mounted: false,
      data: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    },
    permissions() {
      this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadData()

        setTimeout(() => {
          this.mounted = true
        }, 500)
      }
    },
    async loadData() {
      const response = await this.getUserGroup(this.currentSite.site_id, this.$route.params.groupId)
      this.data = response.data
    }
  }
}
</script>

<style lang="scss" scoped></style>
