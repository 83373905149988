<template>
  <div id="banners-page">
    <h2 v-if="currentSite != null" class="intro-y text-lg font-medium mt-10">
      {{ currentSite.name }}的橫幅 (共{{ banners.length }}個)
    </h2>

    <div v-if="checkPermission(permissions, 'site.banner.view')">

      <div class="grid grid-cols-12 gap-6 mt-5">
        <div class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2">
          <router-link to="/management/create-banner" class="btn btn-primary shadow-md mr-2" v-if="checkPermission(permissions, 'site.banner.create')">新增橫幅</router-link>
        </div>
        <!-- BEGIN: Data List -->
        <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
          <table class="table table-report -mt-2">
            <thead>
            <tr>
              <th class="whitespace-nowrap">標題</th>
              <th class="whitespace-nowrap">網址</th>
              <th class="whitespace-nowrap">圖片</th>
              <th class="whitespace-nowrap">顯示次序</th>
              <th v-if="checkPermission(permissions, 'site.banner.edit') || checkPermission(permissions, 'site.banner.delete')" class="text-center whitespace-nowrap">動作</th>
            </tr>
            </thead>
            <tbody>
            <tr class="" v-for="(item, key) in banners" :key="key">
              <td class="">{{ item.title }}</td>
              <td class=""><a v-if="item.url != null && item.url !== ''" :href="item.url" target="_blank">{{ item.url }}</a><span v-else>-</span></td>
              <td class="">
                <a v-if="item.image != null && item.image !== ''" :href="item.image" target="_blank" class="inline-block">
                  <img :src="item.image" width="300"/>
                </a>
              </td>
              <td class="">{{ item.display_order }}</td>
              <td v-if="checkPermission(permissions, 'site.banner.edit') || checkPermission(permissions, 'site.banner.delete')" class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <router-link v-if="checkPermission(permissions, 'site.banner.edit')" :to="`/management/update-banner/${item.banner_id}`" class="flex items-center mr-3">
                    <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                    &nbsp;更改 </router-link>
                  <button v-if="checkPermission(permissions, 'site.banner.delete')" class="flex items-center text-danger" @click="openDeleteModal(item.banner_id)">
                    <font-awesome-icon icon="fa-regular fa-trash-can" />
                    &nbsp;刪除 </button>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
      </div>

      <!-- BEGIN: Delete Confirmation Modal -->
      <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body p-0">
              <div class="p-5 text-center">
                <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
                <div class="text-3xl mt-5">你確定嗎？</div>
                <div class="text-slate-500 mt-2">
                  你是否要刪除此橫幅？
                  <br>
                  這動作不能還原。
                </div>
              </div>
              <div class="px-5 pb-8 text-center">
                <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Delete Confirmation Modal -->

    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['auth', 'permissions', 'currentSite'],
  data() {
    return {
      mounted: false,
      sites: [],
      banners: [],
      deleteId: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite(val) {
      if (val) {
        this.init()
      }
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadBanners()
      }
    },
    async loadBanners() {
      this.banners = await this.getSiteBanners(this.currentSite.site_id)
    },
    openDeleteModal(id) {
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteSiteBanner(this.currentSite.site_id, this.deleteId)

      let option = {
        title: '成功',
        message: '橫幅已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        await this.loadBanners()
      } else {
        option = {
          title: '未能刪除橫幅',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped>
#banners-page {
    .tab-content {
        background-color: #FFF;
    }
}
</style>
