<template>
  <div id="faq-page">
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8 mb-5">
      <h2 class="text-lg font-medium mr-auto">
        FAQ
      </h2>

      <div class="flex justify-end mt-4 sm:mt-0">
        <div class="relative text-slate-500 mr-2">
          <input v-model="search" type="text" class="form-control sm:w-56 pr-10" placeholder="搜尋">
          <font-awesome-icon icon="fa-solid fa-magnifying-glass" class="absolute my-auto w-4 h-4 mr-3 right-0 inset-y-0" />
        </div>

        <select v-if="checkPermission(permissions, 'site.knowledge_base.edit')" id="status" v-model="currentStatus" class="form-select mr-2 w-auto" aria-label="請選擇狀態">
          <option v-for="(item, key) in allStatus" :key="key" :value="item.value">{{ item.name }}</option>
        </select>

        <div class="text-slate-500">
          <router-link to="/faq/create-knowledge" v-if="checkPermission(permissions, 'site.knowledge_base.create')" class="btn btn-primary shadow-md whitespace-nowrap">新增FAQ
          </router-link>
        </div>
      </div>
    </div>

    <div v-if="checkPermission(permissions, 'site.knowledge_base.view')">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: FAQ Menu -->
        <div class="intro-y col-span-12 lg:col-span-4 xl:col-span-3">
          <div class="box">
            <div v-for="(item, key) in faqTypes" :key="key" class="px-4 pb-3 pt-5" :class="{'pb-5': key === faqTypes.length - 1,'border-t border-slate-200/60 dark:border-darkmode-400': key > 0}">
              <button class="flex rounded-lg items-center px-4 py-2 w-full text-left" :class="{'bg-primary text-white font-medium': currentType === item }" @click="currentType = item">
                <div class="flex-1 truncate">{{ item.name }}</div>
              </button>
              <button v-for="(child, index) in item.children" :key="index" class="sub-type flex rounded-lg items-center px-4 py-2 mt-1 ml-4 text-left" :class="{'bg-primary text-white font-medium': currentType === child }" @click="currentType = child">
                <div class="flex-1 truncate">{{ child.name }}</div>
              </button>
            </div>
          </div>
        </div>
        <!-- END: FAQ Menu -->
        <!-- BEGIN: FAQ Content -->
        <div class="intro-y col-span-12 lg:col-span-8 xl:col-span-9">
          <div id="faq-content" class="intro-y box">
            <div class="flex items-center p-5 border-b border-slate-200/60 dark:border-darkmode-400">
              <h2 class="font-medium text-base mr-auto">
                {{ currentType ? currentType.name : '' }}
              </h2>
              <button v-if="!printing" type="button" class="btn btn-secondary shadow-md no-print" @click="onPrint(currentType)">列印</button>
            </div>
            <div class="accordion p-5">
              <div v-for="(item, key) in knowledges" :key="key" class="accordion-item">
                <div class="accordion-header flex justify-between align-items-start">
                  <button type="button" class="accordion-button flex-1 faq-title" @click="onClickFaq(item)"> {{ item.title }} </button>

                  <div class="flex justify-center items-center">
                    <button v-if="!printing" type="button" class="flex items-center mr-3 no-print text-pending" @click="onCopy(item)">
                      <font-awesome-icon icon="fa-solid fa-link" class="mr-1" />
                       分享連結
                    </button>

                    <router-link :to="`/faq/knowledge/${item.knowledge_id}`" class="flex items-center mr-3 text-success">
                      <font-awesome-icon icon="fa-solid fa-eye" />
                      &nbsp;檢視 </router-link>

                    <router-link v-if="checkPermission(permissions, 'site.knowledge_base.edit')" :to="`/faq/update-knowledge/${item.knowledge_id}`" class="flex items-center mr-3">
                      <font-awesome-icon icon="fa-solid fa-pen-to-square" />
                      &nbsp;更改 </router-link>
                    <button v-if="checkPermission(permissions, 'site.knowledge_base.delete')" class="flex items-center text-danger" @click="openDeleteModal(item.knowledge_id)">
                      <font-awesome-icon icon="fa-regular fa-trash-can" />
                      &nbsp;刪除 </button>
                  </div>
                </div>
                <div class="accordion-collapse show" v-if="currentFaq === item.knowledge_id">
                  <div class="accordion-body text-slate-600 dark:text-slate-500 html-content" v-html="item.content"></div>
                  <a v-if="item.extra_links != null && item.extra_links !== ''" class="mt-2 block" :href="item.extra_links" target="_blank">{{ item.extra_links }}</a>
                </div>
                <div class="mt-2 font-italic">最後更新：{{ item.updated_at }}</div>
              </div>
            </div>
          </div>

          <div class="hidden md:block mx-auto text-slate-500 text-center mt-5">正在顯示第 {{ knowledges.length !== 0 ? from : 0 }} 至 {{ to ?? 0 }} 個FAQ</div>

          <!-- BEGIN: Pagination -->
          <pagination-row :pages="pages" :current-page.sync="currentPage"
                          :item-per-page.sync="itemPerPage"></pagination-row>
          <!-- END: Pagination -->
        </div>
        <!-- END: FAQ Content -->

        <!-- BEGIN: Delete Confirmation Modal -->
        <div id="delete-confirmation-modal" class="modal" tabindex="-1" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body p-0">
                <div class="p-5 text-center">
                  <font-awesome-icon icon="fa-regular fa-circle-xmark" class="fa-3x text-danger" />
                  <div class="text-3xl mt-5">你確定嗎？</div>
                  <div class="text-slate-500 mt-2">
                    你是否要刪除此FAQ？
                    <br>
                    這動作不能還原。
                  </div>
                </div>
                <div class="px-5 pb-8 text-center">
                  <button type="button" data-tw-dismiss="modal" class="btn btn-outline-secondary w-24 mr-1">取消</button>
                  <button type="button" class="btn btn-danger w-24" @click="onDelete">確定</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Delete Confirmation Modal -->
      </div>
    </div>

    <div v-else-if="mounted">
      <div class="alert alert-danger show mt-2" role="alert">沒有權限查看</div>
    </div>
  </div>
</template>

<script>

export default {
  props: ['auth', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,
      users: [],
      faqTypes: [],
      currentType: null,
      currentStatus: 'PUBLISHED',

      knowledges: [],

      itemPerPage: 10,
      currentPage: 1,
      pages: 0,
      from: 0,
      to: 0,
      search: '',

      allStatus: [
        { name: '全部', value: 'ALL' },
        { name: '公開', value: 'PUBLISHED' },
        { name: '草稿', value: 'DRAFT' }
      ],

      currentFaq: null,

      printing: false
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    },
    currentType(val) {
      this.loadBases()

      // eslint-disable-next-line eqeqeq
      if (this.$route.query.type != this.currentType.type_id) {
        this.$router.replace({ path: '/faq', query: { type: this.currentType.type_id } })
      }
    },
    currentPage(val) {
      this.loadBases(false)
    },
    currentStatus(val) {
      this.loadBases()
    },
    itemPerPage(val) {
      this.loadBases()
    },
    search(val) {
      this.loadBases()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadFaqTypes()
        this.mounted = true
      }
    },
    async loadFaqTypes() {
      const typeId = this.$route.query.type ?? null

      this.faqTypes = await this.getSiteFaqTypes(this.currentSite.site_id)
      if (this.faqTypes.length > 0) {
        if (typeId != null) {
          // Search Top Types
          this.currentType = this.getObjectValue(this.faqTypes, 'type_id', parseInt(typeId)) ?? null

          // Search Children Types
          if (this.currentType == null) {
            for (let i = 0; i < this.faqTypes.length; i++) {
              this.currentType = this.getObjectValue(this.faqTypes[i].children, 'type_id', parseInt(typeId)) ?? null
              if (this.currentType != null) {
                break
              }
            }
          }

          // No result found
          if (this.currentType == null) {
            this.currentType = this.faqTypes[0]
          }
        } else {
          this.currentType = this.faqTypes[0]
        }

        await this.loadBases()
      }
    },
    async loadBases(resetCurrentPage = true) {
      const response = await this.getSiteFaqBases(this.currentSite.site_id, this.search, this.currentType.type_id, this.itemPerPage, this.currentPage, 'DESC', this.currentStatus)
      this.knowledges = response.data

      this.pages = response.meta.page.last_page
      this.from = response.meta.page.from
      this.to = response.meta.page.to

      if (resetCurrentPage) {
        this.currentPage = 1
      }

      this.currentFaq = parseInt(this.$route.query.knowledge_id) ?? null
    },
    onClickFaq(item) {
      if (this.currentFaq != null && this.currentFaq === item.knowledge_id) {
        this.currentFaq = null
      } else {
        this.currentFaq = item.knowledge_id
      }
    },
    onCopy(item) {
      const tempInput = document.createElement('input')
      tempInput.value = window.location.host + `/faq/knowledge/${item.knowledge_id}`
      document.body.appendChild(tempInput)
      tempInput.select()
      document.execCommand('copy')
      document.body.removeChild(tempInput)

      const option = {
        title: '成功',
        message: '已複製分享連結',
        type: 'success'
      }

      this.$notify(option)
    },
    async onPrint(type) {
      this.printing = true
      const response = await this.downloadSiteFaqBase(this.currentSite.site_id, type.type_id)
      const blob = new Blob([response.data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.download = `knowledge-base_${type.name}.pdf`
      link.click()
      URL.revokeObjectURL(link.href)
      this.printing = false
    },
    openDeleteModal(id) {
      this.deleteId = id

      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.show()
    },
    async onDelete() {
      const el = document.querySelector('#delete-confirmation-modal')
      const modal = window.tailwind.Modal.getOrCreateInstance(el)
      modal.hide()

      const response = await this.deleteSiteFaqBase(this.currentSite.site_id, this.deleteId)

      let option = {
        title: '成功',
        message: 'FAQ已被刪除',
        type: 'success'
      }

      if (response.code === 0) {
        this.loadBases()
      } else {
        option = {
          title: '未能刪除FAQ',
          message: response.data.message ?? '系統錯誤，請稍後再試',
          type: 'error'
        }
      }

      this.$notify(option)
    }
  }
}
</script>

<style lang="scss" scoped>
#faq-page {
  .sub-type {
    width: 90%;
    margin-left: 10%;
  }

  .show {
    display: block !important;
  }

  .faq-title {
    font-size: 20px;
    font-weight: bold
  }
}
</style>
