<template>
  <div id="mobile-menu" class="p-relative">
    <div class="backdrop" v-if="show" @click="onClose"></div>
    <!-- BEGIN: Mobile Menu -->
    <div class="mobile-menu" :class="{'mobile-menu--active': show }">
      <div class="scrollable">
        <button class="mobile-menu-toggler" @click="onClose"><font-awesome-icon icon="fa-regular fa-circle-xmark" class="w-8 h-8 text-white transform"/></button>

        <ul class="scrollable__content py-2 mt-10">
          <li v-for="(item, key) in pages" :key="key">
            <template v-if="item.sitePermission && (item.permission == null || checkPermission(permissions, item.permission))">
              <router-link v-if="item.children == null" :to="item.url" class="menu" :class="{'menu--active': isActive(item)}" @click.native="show = false">
                <div class="menu__icon"><font-awesome-icon :icon="item.icon" class="fa-2x" /></div>
                <div class="menu__title"> {{ item.name }}</div>
              </router-link>

              <button v-else class="w-full menu" @click="item.show = !item.show">
                <div class="menu__icon"> <font-awesome-icon :icon="item.icon" class="fa-2x" /> </div>
                <div class="menu__title"> {{ item.name }} <font-awesome-icon icon="fa-solid fa-chevron-up" class="menu__sub-icon" /> </div>
              </button>

              <ul v-if="item.children != null && item.children.length > 0 && item.show">
                <li v-for="(child, index) in item.children" :key="`${key}-${index}`">
                  <router-link :to="child.url" class="menu" @click.native="show = false">
                    <div class="menu__icon"> <font-awesome-icon :icon="child.icon ?? 'fa-regular fa-circle'" class="fa-2x" /> </div>
                    <div class="menu__title"> {{ child.name }} </div>
                  </router-link>
                </li>
              </ul>
            </template>
          </li>
        </ul>
      </div>
    </div>
    <!-- END: Mobile Menu -->
  </div>
</template>

<script>
export default {
  props: ['currentSite', 'permissions'],
  data() {
    return {
      show: false,

      pages: [
        { name: '報告板', url: '/announcements', icon: 'fa-solid fa-rss', sitePermission: false, permission: 'site.announcement.view' },
        { name: '行事曆', url: '/calendar', icon: 'fa-regular fa-calendar', sitePermission: false, permission: 'site.calendar.view' },
        { name: '通訊錄', url: '/address-books', icon: 'fa-regular fa-address-book', sitePermission: false, permission: 'site.phone_directory.view' },
        { name: '檔案櫃', url: '/library', icon: 'fa-regular fa-hard-drive', sitePermission: false, permission: null },
        { name: '申請表', url: '/workflows', icon: 'fa-solid fa-table-columns', sitePermission: false, permission: ['system.workflow.email-workflow', 'system.workflow.account-workflow'] },
        { name: '系統管理', url: '/management', icon: 'fa-solid fa-gear', sitePermission: false, permission: ['site.manage'] },
        { name: '問巻/電子表格', url: '/forms', icon: 'fa-regular fa-file-lines', sitePermission: false, permission: 'site.form.view' },
        { name: '會議室及資源預借', url: '/resource-bookings', icon: 'fa-solid fa-clipboard-check', sitePermission: false, permission: 'site.resource.view' },
        { name: 'FAQ', url: '/faq', icon: 'fa-regular fa-circle-question', sitePermission: false, permission: 'site.knowledge_base.view' },
        { name: '討論區', url: '/forum', icon: 'fa-regular fa-comment', sitePermission: false, permission: 'site.forum.view' },
        { name: '執行報告', url: '/performance-report', icon: 'fa-regular fa-file', sitePermission: false, permission: 'centre.performance-report.fill' }
      ]
    }
  },
  watch: {
    currentSite() {
      this.init()
    }
  },
  async mounted() {
    await this.init()
  },
  methods: {
    init() {
      this.mobileMenu()

      if (this.currentSite) {
        this.$set(this.pages[0], 'sitePermission', this.currentSite.is_announcement_enabled)
        this.$set(this.pages[1], 'sitePermission', this.currentSite.is_calendar_enabled)
        this.$set(this.pages[2], 'sitePermission', this.currentSite.is_phone_directory_enabled)
        this.$set(this.pages[3], 'sitePermission', true)
        this.$set(this.pages[4], 'sitePermission', this.currentSite.is_workflow_enabled)
        this.$set(this.pages[5], 'sitePermission', true)
        this.$set(this.pages[6], 'sitePermission', this.currentSite.is_form_enabled)
        this.$set(this.pages[7], 'sitePermission', this.currentSite.is_resource_booking_enabled)
        this.$set(this.pages[8], 'sitePermission', this.currentSite.is_knowledge_base_enabled)
        this.$set(this.pages[9], 'sitePermission', this.currentSite.is_forum_enabled)
        this.$set(this.pages[10], 'sitePermission', true)
      }
    },
    isActive(item) {
      return this.$route.path.startsWith(item.url)
    },
    onClose() {
      this.show = false
    }
  }
}
</script>

<style scoped lang="scss">
#mobile-menu{
  .mobile-menu::before {
    display: none;
  }

  .mobile-menu .scrollable {
    z-index: 65;
  }

  .banner {
    margin-left: auto;
    margin-right: auto;
    width: 220px;

    a {
      width: 100%;
      margin: 16px 0;
    }
  }

  .mobile-menu .scrollable .mobile-menu-toggler {
    right: unset;
    left: 200px;
    margin-right: unset;
    margin-left: 1rem;
  }

  ul {
    display: block !important;
    transition: none !important;
    height: initial !important;
  }
}
</style>
