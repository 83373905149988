<template>
  <div id="update-data-page">
    <h2 class="intro-y text-lg font-medium mt-10">
      更改會議室及資源項目
    </h2>
    <resource-form v-if="checkPermission(permissions, 'site.resource.edit')" :all-sites="allSites" :site="currentSite" :data="data"></resource-form>
  </div>
</template>

<script>
export default {
  props: ['auth', 'allSites', 'currentSite', 'permissions'],
  data() {
    return {
      mounted: false,
      data: null
    }
  },
  watch: {
    auth(val) {
      if (val) {
        this.init()
      }
    },
    currentSite() {
      this.init()
    },
    permissions() {
      this.init()
    }
  },
  async mounted() {
    if (!this.auth) {
      return
    }

    await this.init()
  },
  methods: {
    async init() {
      if (this.currentSite) {
        await this.loadData()
      }

      setTimeout(() => {
        this.mounted = true
      }, 500)
    },
    async loadData() {
      this.data = await this.getSiteResource(this.currentSite.siteId, this.$route.params.resourceId)
    }
  }
}
</script>

<style lang="scss" scoped></style>
